import { Alert } from 'antd';
import { useNetworkStatus } from 'src/utils/network-status';

export const NetworkStatusWrapper = ({ children }) => {
  const isOnline = useNetworkStatus();
  const className = isOnline ? 'online' : 'offline';
  return (
    <>
      <Alert
        className={`network-status-alert ${className}`}
        type="error"
        message="We are not able to connect to the server, please try again shortly"
        showIcon
        banner
      />
      {children}
    </>
  );
};
