import vaxRecordNSLogo from 'src/assets/myvaxnslogo.png';
import unavailableImg from 'src/assets/unavailable.png';

export const ServiceUnavailablePage = () => {
  return (
    <main style={{ color: '#00538f', minHeight: '100vh', backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignContent: 'center',
          rowGap: 40,
          minHeight: '100vh',
          maxWidth: 800,
          margin: '0 auto'
        }}
      >
        <section style={{ textAlign: 'center', padding: '0 25px', maxWidth: 450 }}>
          <img style={{ width: '80%' }} src={vaxRecordNSLogo} alt="VaxRecordNS" />
        </section>
        <section style={{ textAlign: 'center', width: '80%', padding: '0 25px', maxWidth: 450 }}>
          <h2>VaxRecordNS is currently unavailable.</h2>
          <h2>Please try again later.</h2>
        </section>
        <section style={{ textAlign: 'center', padding: '0 25px' }}>
          <img style={{ width: '100%' }} src={unavailableImg} alt="VaxRecordNS" />
        </section>
      </div>
    </main>
  );
};
