export const theme = {
  colors: {
    primary: {
      50: '#deefff',
      100: '#b2d2fe',
      200: '#85b9f8',
      300: '#58a2f4',
      400: '#2280C1',
      500: '#177cd6',
      600: '#0C6DB4',
      700: '#024079',
      800: '#00214b',
      900: '#00091e',
      default: '#0C6DB4'
    },
    secondary: {
      50: '#fff8db',
      100: '#ffeaad',
      200: '#ffdc7d',
      300: '#fece4b',
      400: '#FEC52E',
      500: '#e4a601',
      600: '#b28100',
      700: '#7f5c00',
      800: '#4d3700',
      900: '#1c1100',
      default: '#FEC52E'
    },
    coolGray: {
      100: '#F1F3F8'
    }
  },
  radii: {
    none: 0,
    xs: 5,
    sm: 10,
    md: 20,
    lg: 25,
    xl: 35,
    '2xl': 45,
    '3xl': 60,
    full: 9999
  }
};
