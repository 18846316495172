import { AbstractValidationRule } from './abstract-validation-rule';

export const RequiredValidationRuleId = 'required';

export interface RequiredValidationRule extends AbstractValidationRule {
  validationRuleType: typeof RequiredValidationRuleId;
}

/**
 * Validation to check if a value is null or undefined.
 *
 * If the value has a type of string then make sure it is not an empty string (a string
 * with just spaces counts as an empty string).
 * If the value is an array then make sure it is not empty.
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateRequiredRule(value: any): boolean {
  if (value === null || value === undefined || value === false) {
    return false;
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return false;
  }

  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  return true;
}
