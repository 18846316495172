import { IUploaderFile, UploaderFileState } from 'src/form-ux/fields/files-field';
import { AbstractValidationRule } from './abstract-validation-rule';

export const RequiredFilesValidationRuleId = 'requiredFiles';

export interface RequiredFilesValidationRule extends AbstractValidationRule {
  validationRuleType: typeof RequiredFilesValidationRuleId;
}

/**
 * Validation to check if at least a file is provided and its state is SELECTED.
 *
 * @export
 * @param {IUploaderFile[]} files
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateRequiredFilesRule(files: IUploaderFile[]): boolean {
  return files.some(
    (file) =>
      file.object &&
      (file.state === UploaderFileState.SELECTED ||
        file.state === UploaderFileState.UPLOADING ||
        file.state === UploaderFileState.UPLOADED)
  );
}
