import { notification } from 'antd';
import axios, { AxiosInstance } from 'axios';
import FHIR from 'fhirclient';
import Client from 'fhirclient/dist/lib/Client';
import { fhirclient } from 'fhirclient/dist/lib/types';
import { default as jwtDecode, default as JwtDecode } from 'jwt-decode';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { PrivacyPolicyModal } from 'src/modals/privacy-policy';
import { SessionTimeoutModal } from 'src/modals/session-timeout';
import { NetworkStatusService } from 'src/services/network-status';
import { Broadcast } from '../services/broadcast';

const logout_token_key = 'LOGOUT_TOKEN';

interface AccessClaims {
  acr: string;
  'allowed-origins': Array<string>;
  aud: Array<string>;
  auth_time: number;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  preferred_username: string;
  realm_access: { roles: Array<string> };
  resource_access: { account: any };
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
}

interface RefreshToken {
  aud: string;
  sub: string;
  iss: string;
  iat: number;
  exp: number;
  [key: string]: any;
}

interface ISmartClientContext {
  user: AccessClaims;
  isLoggedIn: boolean;
  ready: boolean;
  client: Client;
  logout: () => Promise<void>;
  verify: string;
  loading: boolean;
  authorize: () => Promise<string | void>;
  lambdasClient?: AxiosInstance;
  authClient?: AxiosInstance;
  claims?: AccessClaims;
  clearSession: () => boolean;
}

/**
 * Using undefined! will throw an error in case the Context is not provided with a value
 * but this is preferable than a never ending usage of ? or just not have it typed as it
 * was before.
 */
export const SmartClientContext = React.createContext<ISmartClientContext>(undefined!);

interface SmartAppProviderProps {
  children: React.ReactElement;
  config: fhirclient.AuthorizeParams;
}

// This file is getting too big, we should split it into smaller files
export const SmartAppProvider = (props: SmartAppProviderProps) => {
  const [client, setClient] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [ready, setReady] = React.useState(false);
  const [state, setState] = React.useState<fhirclient.ClientState>();
  const [searchParams] = useSearchParams();
  const query = Object.fromEntries(searchParams);
  const isPageVisible = usePageVisibility();

  const isLoggedIn = !!state?.tokenResponse?.access_token;

  const claims = state?.tokenResponse?.access_token
    ? jwtDecode<AccessClaims>(state?.tokenResponse?.access_token)
    : undefined;

  const user = state?.tokenResponse?.id_token
    ? decodeIdentityToken(state.tokenResponse.id_token)
    : undefined;

  const refresh_token = client?.state.tokenResponse?.refresh_token;
  const refreshToken: RefreshToken = refresh_token && JwtDecode<RefreshToken>(refresh_token);

  /**
   * Refreshes the session and returns the new client state if successful, otherwise
   * it clears the session and navigates to the login page
   * @returns
   */
  const refreshSession = async (fhirClient: Client = client) => {
    if (!NetworkStatusService.isOnline) return;
    // If we have a refresh token, we can refresh the session
    try {
      const clientState = await (fhirClient as Client).refresh();
      Broadcast.refresh(clientState);
      const newClient = new Client(fhirClient.environment, clientState);
      onAuth(newClient, clientState, setClient, setState);
      return clientState;
    } catch {
      clearSession();
    }
  };

  const authClient = React.useMemo(() => {
    const accessToken = state?.tokenResponse?.access_token;
    const authUrl = state?.authorizeUri?.replace(/\/auth(?![\s\S]*\/auth)/, '');
    if (!accessToken || !authUrl) return;
    return createRefreshClient(authUrl, accessToken, refreshSession, clearSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, client]);

  const lambdasClient = React.useMemo(() => {
    const accessToken = state?.tokenResponse?.access_token;
    const lambdasUrl = process.env.REACT_APP_LAMBDAS_URL;
    if (!accessToken || !lambdasUrl) return;
    return createRefreshClient(lambdasUrl, accessToken, refreshSession, clearSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, client]);

  const logoutClient = React.useMemo(() => {
    const accessToken = state?.tokenResponse?.access_token;
    if (!accessToken || !state?.authorizeUri) return;
    const baseAuthUrl = new URL(state.authorizeUri).origin;
    return createRefreshClient(baseAuthUrl, accessToken, refreshSession, clearSession, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, client]);

  React.useEffect(() => {
    if (document.hidden) return;
    if (!client) return;
    // Attempt to refresh the session
    refreshSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageVisible]);

  React.useEffect(() => {
    if (!isLoggedIn) return;
    if (!sessionStorage.getItem(logout_token_key)) {
      // Ask for a My NS Account id token from other tabs, wait 30ms for a response or get it yourself
      Broadcast.hasLogoutToken();
      setTimeout(() => {
        if (!logoutClient) return;
        if (sessionStorage.getItem(logout_token_key)) return;
        // Get My NS Account access token from keycloak
        logoutClient.get('auth/realms/NSSmartApi/broker/MyNSID/token').then((res) => {
          const MyNSAccounttoken = res.data['id_token'];
          // Store token in session storage
          sessionStorage.setItem(logout_token_key, MyNSAccounttoken);
        });
      }, 30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const authorize = () => {
    return FHIR.oauth2.authorize(props.config);
  };

  const logout = async () => {
    try {
      if (state?.tokenResponse?.access_token && state.authorizeUri) {
        // Log out of keycloak
        await authClient
          ?.get('logout', {
            params: {
              id_token_hint: state?.tokenResponse?.id_token,
              post_logout_redirect_uri: window.location.origin
            }
          })
          // Remove the catch block and move what is inside "finally" into "then" once keycloak fixes the cors issue in the logout endpoint
          .catch(() => {})
          .finally(() => {
            // Get logout token from session storage
            const MyNSAccounttoken = sessionStorage.getItem(logout_token_key);
            // Clear the session and navigate to the My NS Account logout page
            clearSession(
              `${process.env.REACT_APP_NSID_BASE_URL}/auth/oidc/endsession?id_token_hint=${MyNSAccounttoken}&post_logout_redirect_uri=${window.location.origin}`
            );
          });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Logout failed',
        description: 'An error has occured'
      });
    }
  };

  /**
   * Clears the session storage, broadcasts a logout event, and navigates to the landing page
   * @param {string} location - the location to navigate to after clearing the session, defaults to window.location.origin
   * @returns {boolean} false if a session was not found
   */
  const clearSession = React.useCallback((location?: string) => {
    if (!sessionStorage.length) return false;
    // Clear the session storage
    sessionStorage.clear();
    Broadcast.logout();
    window.location.replace(location ?? window.location.origin);
    return true;
  }, []);

  const renewSession = React.useCallback(async () => {
    try {
      const client = await FHIR.oauth2.ready();
      await refreshSession(client);
      onAuth(client, client.state, setClient, setState);
      setReady(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
    // Adding navigate to the list of deps below will cause this to run every time the tab changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create broadcastChannel event listeners on first load
  React.useEffect(() => {
    Broadcast.registerListeners({
      login: (state) => {
        // If the SMART_KEY is not set then do so and attempt to renew session
        const smartKey = window.sessionStorage.getItem('SMART_KEY');
        if (!smartKey) {
          const SMART_KEY = state.key;
          if (SMART_KEY) {
            window.sessionStorage.setItem('SMART_KEY', `"${SMART_KEY}"`);
            window.sessionStorage.setItem(SMART_KEY, JSON.stringify(state));
            renewSession();
          }
        }
      },
      refresh: (receivedState) => {
        // Make sure this only happens in other tabs
        const SMART_KEY = state?.key;
        if (SMART_KEY && state) {
          const newState: fhirclient.ClientState = {
            ...state,
            tokenResponse: receivedState.tokenResponse,
            expiresAt: receivedState.expiresAt
          };
          window.sessionStorage.setItem(SMART_KEY, JSON.stringify(newState));
          const newClient = new Client(client.environment, newState);
          onAuth(newClient, newState, setClient, setState);
        }
      },
      logout: clearSession,
      has_logout_token: () => {
        const token = sessionStorage.getItem(logout_token_key);
        if (token) Broadcast.logoutToken(token);
      },
      logout_token: (token) => sessionStorage.setItem(logout_token_key, token),
      is_logged_in: () => {
        const smartKey = window.sessionStorage.getItem('SMART_KEY');
        if (smartKey) {
          const state = window.sessionStorage.getItem(smartKey.replaceAll('"', ''));
          if (state) Broadcast.login(JSON.parse(state));
        }
      }
    });
  }, [clearSession, client, renewSession, state]);

  React.useEffect(() => {
    // Check if a session already exists and return it
    const smartKey = window.sessionStorage.getItem('SMART_KEY');
    if (smartKey) {
      renewSession();
    } else {
      Broadcast.isLoggedIn();

      // Allow for a message to return before setting load to false
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [renewSession]);

  return (
    <SmartClientContext.Provider
      value={{
        user,
        isLoggedIn,
        ready,
        client,
        authorize,
        authClient,
        lambdasClient,
        logout,
        verify: query.verify,
        claims,
        loading,
        clearSession
      }}
    >
      {props.children}
      <PrivacyPolicyModal />
      {refreshToken && (
        <SessionTimeoutModal
          exp={refreshToken.exp}
          onExpiry={() => clearSession(window.location.origin + '/?timed_out=true')}
          onRefresh={refreshSession}
        />
      )}
    </SmartClientContext.Provider>
  );
};

const onAuth = (
  client: Client,
  state: fhirclient.ClientState,
  setClient: (client: Client) => any,
  setState: (state: fhirclient.ClientState) => any
) => {
  // Propagate login event to other tabs
  Broadcast.login(state);
  setClient(client);
  setState({ ...state });
};

export const useSmartApp = () => {
  return React.useContext(SmartClientContext) ?? {};
};

export const useSmartClient = () => {
  return React.useContext(SmartClientContext)?.client;
};

const decodeIdentityToken = (idToken) => {
  const data = jwtDecode(idToken) as any;
  return { ...data, fullName: `${data.given_name ?? ''} ${data.family_name ?? ''}`.trim() };
};

const createRefreshClient = (
  baseUrl: string,
  access_token: string,
  refreshSession: () => Promise<fhirclient.ClientState | undefined>,
  clearSession: (location?: string) => boolean,
  refreshOnAnyError?: boolean
): AxiosInstance => {
  const newClient = axios.create({
    // Replace the last occurance of /auth
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });

  // set up an interceptor to refresh the token if it has expired
  newClient.interceptors.response.use(undefined, async (error) => {
    // unless otherwise specified, we only want to refresh the token on a 401
    if (error.response?.status !== 401 && !refreshOnAnyError) return Promise.reject(error);
    if (refreshOnAnyError && error.config?.retry) return Promise.reject(error);
    if (error.response?.status === 401 && error.config?.retry) return clearSession();
    // refresh the token
    const state = await refreshSession();
    if (!state) return Promise.reject(error);
    // retry the request with the new token
    const newConfig = {
      ...error.config,
      retry: true,
      headers: {
        Authorization: `Bearer ${state.tokenResponse?.access_token}`
      }
    };
    return newClient.request(newConfig);
  });

  return newClient;
};

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(document.hidden);
  const onVisibilityChange = () => setIsVisible(document.hidden);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilityChange', onVisibilityChange);
    };
  });

  return isVisible;
}
