export function getNonceFromUrl(): string | null {
  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Use URLSearchParams to extract the query parameters
  const params = new URLSearchParams(url.search);

  // Get the 'nonce' parameter
  const nonce = params.get('nonce');

  return nonce;
}
