import { faChevronRight, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, Space, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { Patient } from 'src/data/patient';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';

interface IPatientCardProps {
  patient: Patient;
  isDependent: boolean;
  onPress: () => any;
  noBorderLeft?: boolean;
  disconnectOnPress?: () => any;
}

export const PatientCard = (props: IPatientCardProps) => {
  const { patient, onPress, disconnectOnPress } = props;

  const spaceJustify = useValueFromBreakpoint([
    ['xs', 'center'],
    ['sm', 'end']
  ]);

  /* React's state management doesn't update this variable in time (if the disconnect button is clicked,
     the disconnect code is run first and then the onClick for the card) -> the Card onClick uses the old value (false)
     when using setDisconnect(true) in the disconnect onClick */
  let disconnected = false;
  const thirtyDaysFromNow = moment().add(30, 'days');

  //Check if patient turns 16 in next 30 days
  const sixteenInNextThirtyDays =
    patient.birthDate && thirtyDaysFromNow.diff(moment(new Date(patient.birthDate)), 'years') >= 16;

  const colProps = disconnectOnPress ? { xs: 24, sm: 18, md: 19, lg: 21 } : { span: 22 };

  const PatientCardData = () => {
    return (
      <Row justify="space-between" align="middle" gutter={[10, 15]}>
        <Col {...colProps}>
          <Space direction="vertical" size={4}>
            <h2
              style={{
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
                color: patient.isDeceased ? '#D3D3D3' : '#00538f'
              }}
            >
              {patient.displayName}
            </h2>
            <p style={{ margin: 0, color: patient.isDeceased ? '#d3d3d3' : 'black' }}>
              {/* Date of Birth: {moment(patient.birthDate).format('MMM D, YYYY')} */}
              Date of Birth: {moment(patient.birthDate).format('YYYY-MMM-DD').toUpperCase()}
            </p>
            {sixteenInNextThirtyDays && props.isDependent && (
              <p style={{ margin: 0, color: 'red' }}>
                When your dependent turns 16 this record will no longer be visible as they will be
                required to create their own account.
              </p>
            )}
          </Space>
        </Col>
        {disconnectOnPress ? (
          <Col xs={24} sm={6} md={5} lg={3}>
            <div style={{ display: 'flex', width: '100%', justifyContent: spaceJustify }}>
              <Space
                style={{ color: 'red' }}
                align="center"
                onClick={() => {
                  disconnected = true;
                  if (disconnectOnPress) disconnectOnPress();
                }}
              >
                <span>Disconnect</span>
                <FontAwesomeIcon icon={faUnlink} size="lg" />
              </Space>
            </div>
          </Col>
        ) : (
          <Col span={2}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <FontAwesomeIcon
                icon={faChevronRight}
                color={patient.isDeceased ? '#d3d3d3' : 'gray'}
                size="lg"
              />
            </div>
          </Col>
        )}
      </Row>
    );
  };

  // if patient is deceased, don't allow user to enter vax record & style it disabled
  if (patient.isDeceased) {
    return (
      <Tooltip title="This record cannot be displayed.">
        <Card
          style={{
            backgroundColor: '#F1F3F8',
            borderLeft: !props.noBorderLeft ? '15px solid #D3D3D3' : undefined,
            cursor: 'pointer'
          }}
          bodyStyle={{ padding: '20px 15px' }}
          onClick={() => {}}
        >
          <PatientCardData />
        </Card>
      </Tooltip>
    );
  }

  // if user is not deceased, allow onClick
  return (
    <Card
      style={{
        backgroundColor: '#F1F3F8',
        borderLeft: !props.noBorderLeft ? '15px solid #00538f' : undefined,
        cursor: 'pointer'
      }}
      bodyStyle={{ padding: '20px 15px' }}
      onClick={() => {
        if (!disconnected && !patient.isDeceased) onPress();
      }}
    >
      <PatientCardData />
    </Card>
  );
};
