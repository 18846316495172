import { Ids, StrLang } from '@canimmunize/tools';
import { ValidationRules } from 'src/form-ux/validation/validation-rules/validation-rules';
import { validateValue } from 'src/form-ux/validation/validate-value';
import { RequiredValidationRuleId } from 'src/form-ux/validation/validation-rules/required-validation';
import { RequiredFilesValidationRuleId } from 'src/form-ux/validation/validation-rules/required-files-validation';
import { RequiredDefinedValidationRuleId } from 'src/form-ux/validation/validation-rules/required-defined-validation';
import { MaxLengthValidationRuleId } from 'src/form-ux/validation/validation-rules/max-length-validation';
import { JsonValidationRuleId } from 'src/form-ux/validation/validation-rules/json-validation';
import { EmailValidationRuleId } from 'src/form-ux/validation/validation-rules/email-validation';
import { PhoneValidationRuleId } from 'src/form-ux/validation/validation-rules/phone-validation';
import { RegexValidationId } from 'src/form-ux/validation/validation-rules/regex-validation';
import { MinValidationRuleId } from 'src/form-ux/validation/validation-rules/min-validation';
import { UserPasswordValidationRuleId } from 'src/form-ux/validation/validation-rules/user-password-validation';
import { HcnValidationRuleId } from 'src/form-ux/validation/validation-rules/hcn-validation';

/**
 * Validates the value parameter based on the validationRules parameter and
 * returns a string to display if there's an error
 *
 * @param {ValidationRules[]} validationRules
 * @param {*} value
 * @returns A string representing the validation error or an empty string if
 * there's no error
 */
export function formItemValidate(validationRules: ValidationRules[], value: any): string {
  const validationFailure = validateValue(validationRules, value);
  const path = window.location.pathname;

  //Find language from window pathname to use in translating error message
  let lang = 'en';
  if (path.includes('/fr/')) lang = 'fr';

  if (validationFailure === true) {
    return '';
  }

  if (typeof validationFailure === 'string') {
    return validationFailure;
  }

  switch (validationFailure.validationRuleType) {
    case RequiredValidationRuleId:
    case RequiredFilesValidationRuleId:
    case RequiredDefinedValidationRuleId: {
      return StrLang(lang, Ids.this_field_is_required);
    }
    case MaxLengthValidationRuleId: {
      return `This field cannot have more than ${validationFailure.maxLength} characters`;
    }
    case JsonValidationRuleId: {
      return `This field is not valid JSON`;
    }
    case EmailValidationRuleId: {
      return StrLang(lang, Ids.shield_invalid_email_field);
    }
    case PhoneValidationRuleId: {
      return lang === 'en'
        ? `This field is not a valid phone number`
        : `Champ obligatoire : numéro de téléphone valide`;
    }
    case MinValidationRuleId: {
      const OrText: string = validationFailure.inclusive ? 'or equal to' : '';

      return `This field should be greater than ${OrText} ${validationFailure.min}`;
    }
    case RegexValidationId: {
      return validationFailure.errorMessage
        ? validationFailure.errorMessage
        : `This field should meet the regex ${validationFailure.regex}`;
    }
    case UserPasswordValidationRuleId: {
      return StrLang(lang, Ids.password_rules);
    }
    case HcnValidationRuleId: {
      return 'Invalid Health Card Number';
    }
    default: {
      return 'This field is invalid';
    }
  }
}
