//HCN Validation based on Yukon Document
import { AbstractValidationRule } from './abstract-validation-rule';

export const HcnValidationRuleId = 'hcn';

export interface HcnValidationRule extends AbstractValidationRule {
  validationRuleType: typeof HcnValidationRuleId;
}

export const validateHcnRule = (hcn: string): boolean => {
  return !!hcn && hcn.length === 10;
};

/**
 * Nova Scotia
 * 10 digit numeric with MOD 10
 */
export const validateHcnNS = (hcn: string): boolean => {
  if (validateHcnNumeric(hcn, 10) !== null) return false;
  return validateMod(hcn, 10);
};

/**
 * Ensures the entire hcn is numeric with the appropriate length
 */
const validateHcnNumeric = (hcn: string, length: number): Error | null => {
  // Make sure it's not empty
  if (hcn.length === 0) {
    return new Error('Invalid Health Card Number');
  }

  // Make sure that it's only numeric
  if (!hcn.match(/[0-9]+/)) {
    return new Error('Invalid Health Card Number');
  }

  // Make sure that it's the correct length
  if (hcn.length !== length) {
    return new Error('Invalid Health Card Number');
  }

  return null;
};

/* Lunh's Algorithm - True if MOD 10. False otherwise*/
const validateMod = (hcn: string, length, doubleOddIndices = false): boolean => {
  // Get an int array representation of the String
  const digits = hcn.split('').map((char) => parseInt(char));

  // Loop through each digit
  let sum = 0;
  let lastDigit = -1;

  digits.forEach((digit, i) => {
    let currentDigit = digit;

    // For the even indices, double the value
    // Optional tag 'doubleOddIndices' if we want to instead double the odd indices
    if ((!doubleOddIndices && i % 2 === 0) || (doubleOddIndices && i % 2 === 1)) {
      currentDigit *= 2;

      // If the new value is a two digit number, sum its digits
      if (currentDigit > 9) {
        let newDigit = 0;
        while (currentDigit !== 0) {
          newDigit = newDigit + (currentDigit % 10);
          currentDigit = Math.floor(currentDigit / 10);
        }

        currentDigit = newDigit;
      }
    }

    // If this is the last number, store it
    if (i === length - 1) {
      lastDigit = currentDigit;
    } else {
      // If not, add the current digit to the sum
      sum += currentDigit;
    }
  });

  // Multiply the sum by 9
  sum *= 9;

  // Take the mod 10 of the sum
  const mod10 = sum % 10;

  // If the mod 10 is not the same as the last digit, then the OHIP is not valid
  if (mod10 !== lastDigit) {
    return false;
  }
  return true;
};
