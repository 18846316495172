import React from 'react';
import { CheckOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Avatar, AvatarProps, Col, Menu, Row, Space, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { Patient } from 'src/data/patient';
import { useSmartApp } from 'src/contexts/smart-app-provider';

interface IMenuItemProps {
  /** @private Internal filled by antd. Do not set it directly or it can freeze the app*/
  eventKey?: string;
}

export const UserSettingsMenu = ({ patients, onLogout, onClick }) => {
  const menuItems = [<UserMenuItem key={0} />];
  if (patients?.length) menuItems.push(<RecordsMenuItem patients={patients} key={1} />);
  if (patients?.length < 26 && !patients[0].isDeceased)
    menuItems.push(<ConnectDependentMenuItem key={2} />);
  menuItems.push(<LogoutMenuItem onLogout={onLogout} key={3} />);

  return (
    <Menu
      style={{
        maxWidth: 700,
        padding: '10px 0',
        paddingTop: 10,
        marginTop: 0,
        boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
        borderRadius: 10
      }}
      selectedKeys={[]}
      className="ant-menu-dropdown"
      onClick={onClick}
    >
      {menuItems.reduce<Array<JSX.Element>>((items, Item, i) => {
        items.push(Item);

        if (i < items.length - 1) items.push(<Menu.Divider key={`divider-${i}`} />);

        return items;
      }, [])}
    </Menu>
  );
};

interface ILogoutMenuItemProps extends IMenuItemProps {
  onLogout: () => any;
}

export const LogoutMenuItem: React.VFC<ILogoutMenuItemProps> = (props) => {
  return (
    <Menu.Item className="ant-menu-item-group" eventKey={props.eventKey}>
      <p style={{ color: '#00538f', margin: 0 }} onClick={props.onLogout}>
        Log out
      </p>
    </Menu.Item>
  );
};

export const UserMenuItem: React.VFC<IMenuItemProps> = (props) => {
  const { mainPatient } = useA2iData();
  const { claims } = useSmartApp();

  return (
    <Menu.Item disabled className="ant-menu-item-group" eventKey={props.eventKey}>
      <div
        style={{
          display: 'inline-grid',
          gridTemplateColumns: 'auto auto',
          gridTemplateRows: 'auto',
          columnGap: 15,
          alignItems: 'center'
        }}
      >
        <UserAvatar
          firstName={mainPatient?.firstName ?? ''}
          lastName={mainPatient?.lastName ?? ''}
          size={45}
          style={{ fontSize: 20, backgroundColor: '#2280C1' }}
        />

        <div style={{ lineHeight: 'normal', margin: 'auto 0' }}>
          <p style={{ margin: 0, color: 'black' }}>Primary account holder:</p>
          <p
            style={{ margin: 0, fontSize: '1.3em', fontWeight: 'bold', color: '#00538F' }}
          >{`${mainPatient?.displayName}`}</p>
          <p style={{ margin: 0, color: 'grey' }}>{`${claims?.email}`}</p>
        </div>
      </div>
    </Menu.Item>
  );
};

interface IRecordsMenuItemProps extends IMenuItemProps {
  patients: Patient[];
}

export const RecordsMenuItem: React.VFC<IRecordsMenuItemProps> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { patients } = props;

  return (
    <Menu.Item
      className="ant-menu-item-group"
      style={{ maxHeight: 150, overflowY: 'scroll' }}
      eventKey={props.eventKey}
    >
      <p style={{ margin: 0, color: 'gray' }}>View Record</p>
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        {patients.map((patient) => (
          <Row
            style={{ cursor: 'pointer' }}
            key={patient.id}
            gutter={[10, 5]}
            align="middle"
            onClick={() => (patient.isDeceased ? undefined : navigate(`/${patient.id}`))}
            justify="space-between"
            className="ant-menu-item-option"
          >
            <Col>
              <Space>
                <UserAvatar
                  firstName={patient?.firstName}
                  lastName={patient?.lastName}
                  size={26}
                  style={{ fontSize: 20, backgroundColor: '#2280C1' }}
                />
                <span style={{ lineHeight: 1.2 }}>
                  {patient.isDeceased ? (
                    <Tooltip title="This record cannot be displayed.">
                      <p style={{ margin: 0, color: 'grey' }}>{patient.displayName}</p>
                    </Tooltip>
                  ) : (
                    <p style={{ margin: 0, color: 'black' }}>{patient.displayName}</p>
                  )}
                </span>
              </Space>
            </Col>
            {pathname === `/${patient.id}` && (
              <CheckOutlined style={{ fontSize: '1.2em', color: '#2280C1', paddingRight: 5 }} />
            )}
          </Row>
        ))}
      </Space>
    </Menu.Item>
  );
};

export const ConnectDependentMenuItem: React.VFC<IMenuItemProps> = (props) => {
  const { launchAddDependentModal } = useA2iData();

  return (
    <Menu.Item
      onClick={launchAddDependentModal}
      eventKey={props.eventKey}
      className={'ant-menu-item-group'}
    >
      <Space align="center">
        <PlusCircleFilled style={{ fontSize: 26, color: '#00538f' }} />
        <p style={{ margin: 0, color: 'black' }}>Connect a Dependent's Record</p>
      </Space>
    </Menu.Item>
  );
};

interface IUserAvatarProps extends AvatarProps {
  firstName: string;
  lastName: string;
}

export const UserAvatar: React.FC<IUserAvatarProps> = (props) => {
  const avatarProps = { ...props } as Partial<IUserAvatarProps>;
  delete avatarProps.firstName;
  delete avatarProps.lastName;
  return (
    <Avatar {...avatarProps}>{`${(props.firstName[0] ?? '') + (props.lastName[0] ?? '')}`}</Avatar>
  );
};
