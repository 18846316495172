import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './index.css';
import { App } from './app';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
