import React from 'react';
import { Button, Modal } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';
import { useNetworkStatus } from 'src/utils/network-status';

const gracePeriod = 60000;
// Allow for a 500ms buffer to account for network latency
const latencyBuffer = 500;

const timeouts: NodeJS.Timeout[] = [];

export const SessionTimeoutModal: React.FC<{
  exp: number;
  onExpiry: () => any;
  onRefresh: () => any;
}> = (props) => {
  // The time at which the token expires, as number of seconds since the Unix epoch
  const { onExpiry, onRefresh } = props;
  const expiresAt = props.exp * 1000;
  const expiresIn = expiresAt - Date.now();
  const [visible, setVisible] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isOnline = useNetworkStatus();

  React.useEffect(() => {
    const visibleIn = expiresIn - gracePeriod - latencyBuffer;

    // Clear all timeouts
    timeouts.forEach(clearTimeout);

    // Reset state if needed
    setLoading(false);
    if (Date.now() < expiresAt - latencyBuffer) setDisabled(false);
    if (Date.now() < expiresAt - gracePeriod - latencyBuffer) setVisible(false);

    // Visibility timeout
    const visibilityTimeout = setTimeout(() => {
      setVisible(true);
    }, visibleIn);

    // Disable timeout
    const latency = setTimeout(() => {
      setDisabled(true);
    }, visibleIn + gracePeriod);

    // Expiry timeout
    const expiryTimeout = setTimeout(() => {
      if (Date.now() >= expiresAt) onExpiry();
      // Add a 25ms buffer to account for unlikely early executions of the timeout
    }, visibleIn + gracePeriod + latencyBuffer + 25);

    timeouts.push(visibilityTimeout, latency, expiryTimeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAt]);

  const onOk = async () => {
    setLoading(true);
    await onRefresh();
    setLoading(false);
  };

  const modalTopSpace = useValueFromBreakpoint([
    ['xs', 50],
    ['md', 100]
  ]);

  return (
    <Modal
      visible={visible}
      onCancel={() => onExpiry()}
      closable={false}
      maskClosable={false}
      footer={null}
      style={{
        maxWidth: 416,
        top: modalTopSpace
      }}
    >
      <div style={{ padding: '8px 8px 0 8px' }}>
        <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>
          <InfoCircleOutlined
            style={{ float: 'left', fontSize: 22, color: '#faad14', marginRight: 16 }}
          />
          <span>Your session is about to expire</span>
        </div>
        <div style={{ marginLeft: 38 }}>
          You will be logged out in{' '}
          <Countdown
            /**
             * Countdown counts down to exactly 0ms, meaning that it takes a second to reach 0ms from 0.99999s
             * This is fine if we display the time in ms, but because we only show seconds it looks akward.
             * To avoid this, we need add 1000ms to the countdown so that it hits 0s in the display at the
             * same time as the timeout event fires (handled separately).
             */
            value={Math.max(0, expiresAt - latencyBuffer) + 1000}
            key="resend-code-countdown"
            format="ss"
            className="countdown"
          />{' '}
          seconds.
        </div>
        <div style={{ textAlign: 'right', marginTop: 24 }}>
          <Button
            onClick={() => onOk()}
            type="primary"
            disabled={disabled || loading || !isOnline}
            loading={loading}
          >
            Continue session
          </Button>
        </div>
      </div>
    </Modal>
  );
};
