import { Col, Modal, Row } from 'antd';
import React from 'react';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { Patient } from 'src/data/patient';
import { DisconnectDependentStep } from './steps/disconnect';
import { SuccessfulDeletionStep } from './steps/success';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';

export interface IDisconnectDependentModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => any;
  patient: Patient | undefined;
}

export interface IAuthFactor {
  id: string;
  channel: 'sms' | 'call';
  value: string;
}

export interface IDisconnectDependentModalState {
  sessionId?: string;
  authFactors?: Array<IAuthFactor>;
  selectedAuthFactor?: IAuthFactor;
}

export const DisconnectDependentModal: React.VFC<IDisconnectDependentModalProps> = (props) => {
  const { visible, setVisible, patient } = props;
  const { getPatients } = useA2iData();
  const [title, setTitle] = React.useState<string>();
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const [state, setState] = React.useState<IDisconnectDependentModalState>();

  /* In order of step appearance */
  const Steps: Array<DisconnectDependentModalStep> = [
    DisconnectDependentStep,
    SuccessfulDeletionStep
  ];

  React.useEffect(() => {
    if (!state || Object.keys(state).length !== 0) setState(undefined);
    if (stepIndex !== 0) setStepIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const next = React.useCallback(() => {
    if (stepIndex >= Steps.length - 1) {
      setVisible(false);
      getPatients();
    } else {
      setStepIndex(stepIndex + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, stepIndex]);

  const back = React.useCallback(() => {
    if (stepIndex === 0) {
      setVisible(false);
    } else {
      setStepIndex(stepIndex - 1);
    }
  }, [setVisible, stepIndex]);

  const close = React.useCallback(() => {
    if (stepIndex >= Steps.length - 1) getPatients();
    setVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, stepIndex]);

  const Step = Steps[stepIndex];

  const modalTopSpace = useValueFromBreakpoint([
    ['xs', 50],
    ['md', 100]
  ]);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      style={{
        top: modalTopSpace
      }}
      title={
        <p style={{ color: '#00538f', margin: 0, fontWeight: 'bold', lineHeight: 1.8 }}>{title}</p>
      }
    >
      {Step && (
        <Step
          patient={patient}
          setTitle={setTitle}
          next={next}
          back={back}
          close={close}
          state={state}
          setState={setState}
        />
      )}
    </Modal>
  );
};

export const DisconnectStepContentLayout: React.FC<
  React.PropsWithChildren<{ footer?: React.ReactNode }>
> = (props) => {
  return (
    <Row justify="space-between" gutter={[0, 15]}>
      <Col span={24} style={{ overflowY: 'auto' }}>
        {props.children}
      </Col>
      {props.footer && <Col span={24}>{props.footer}</Col>}
    </Row>
  );
};

interface IDisconnectDependentModalStepProps {
  patient: Patient | undefined;
  setTitle: (title: string | undefined) => any;
  next: () => any;
  back: () => any;
  close: () => any;
  state?: IDisconnectDependentModalState;
  setState: (state: IDisconnectDependentModalState) => any;
}

export type DisconnectDependentModalStep = React.VFC<IDisconnectDependentModalStepProps>;
