import React from 'react';
import { AddDependentModalStep, ERROR_CODES, error_codes_message_map, StepContentLayout } from '.';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Alert, Button, ButtonProps, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { AxiosError } from 'axios';
import Countdown from 'antd/lib/statistic/Countdown';
import { ResponsiveFooter } from 'src/utils/responsive-footer';
import { useWindowDimensions } from 'src/utils/window-dimensions';

const RATE_LIMIT_DELAY = 600000;

export const MFACodeStep: AddDependentModalStep = (props) => {
  const AuthInputRef = React.useRef<AuthCodeRef>(null);
  const { lambdasClient } = useSmartApp();
  const [error, setError] = React.useState<ERROR_CODES>();
  const [code, setCode] = React.useState<string>();
  const [submitting, setSubmitting] = React.useState<boolean>();
  const [resendingCode, setResendingCode] = React.useState<boolean>();
  const [resendAtTime, setResendAtTime] = React.useState<number>();
  const [retryAtTime, setRetryAtTime] = React.useState<number>();

  const { width } = useWindowDimensions();
  const isNarrowScreen = width <= 760;

  React.useEffect(() => {
    props.setTitle('Enter your Verification Code');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setCode(undefined);
  }, [error]);

  const authFactor = props.state?.selectedAuthFactor;

  const handleOnChange = (e: string) => {
    setCode(e);
  };

  const verifyRecord = async (code: string) => {
    setSubmitting(true);
    setError(undefined);
    return await lambdasClient
      ?.post('/Dependent/mfa/code', {
        sessionId: props.state?.sessionId,
        authFactorId: authFactor?.id,
        code
      })
      .then(props.next)
      .catch((error: AxiosError<{ error?: ERROR_CODES }>) => {
        const errorCode = error.response?.data.error;
        if (ERROR_CODES.TOO_MANY_ATTEMPTS === errorCode || error.response?.status === 429) {
          const retryTime = Date.now() + RATE_LIMIT_DELAY;
          setRetryAtTime(retryTime);
        }
        setError(errorCode ?? ERROR_CODES.DEFAULT);
        setSubmitting(false);
      });
  };

  const onResendCode = async () => {
    setResendingCode(true);
    try {
      await lambdasClient?.post('/Dependent/mfa', {
        sessionId: props.state?.sessionId,
        authFactorId: authFactor?.id
      });
    } catch (error) {
      setError(ERROR_CODES.DEFAULT);
    }
    setResendAtTime(Date.now() + 30000);
    setResendingCode(false);
  };

  const onTryAnotherMethod = async () => {
    props.back();
  };

  if (submitting)
    return (
      <StepContentLayout>
        <div className="add-dependent-centered-container">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
            style={{ paddingBottom: 20 }}
          />
          <h3>Verifying Record</h3>
        </div>
      </StepContentLayout>
    );

  const TryAnotherAnotherMethodButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={onTryAnotherMethod}
      type="primary"
      ghost
      style={{ minWidth: 150 }}
      size="large"
      {...p}
    >
      Try another method
    </Button>
  );

  const VerifyButton: React.FC<ButtonProps> = (p) => (
    <Button
      type="primary"
      onClick={() => {
        if (code?.length === 6 && !retryAtTime) {
          verifyRecord(code);
        }
      }}
      style={{ minWidth: 150 }}
      disabled={submitting || !!retryAtTime || code?.length !== 6}
      loading={submitting}
      size="large"
      {...p}
    >
      Verify Record
    </Button>
  );

  return (
    <StepContentLayout
      footer={
        <ResponsiveFooter OkButton={VerifyButton} CancelButton={TryAnotherAnotherMethodButton} />
      }
    >
      {error && (
        <Alert
          type="error"
          message={
            retryAtTime ? (
              <TryAgainError
                time={retryAtTime}
                onFinish={() => {
                  setRetryAtTime(undefined);
                  setError(undefined);
                }}
              />
            ) : (
              error_codes_message_map[error]
            )
          }
          style={{ marginBottom: 30 }}
          showIcon
        />
      )}
      <p style={{ fontWeight: 'bold' }}>
        A 6-digit code has been sent to {authFactor?.value}. Please enter the code you received.
      </p>
      <div style={{ pointerEvents: retryAtTime ? 'none' : 'auto', opacity: retryAtTime ? 0.6 : 1 }}>
        <AuthCode
          length={6}
          ariaLabel="Verification Code Input"
          onChange={handleOnChange}
          ref={AuthInputRef}
          allowedCharacters="numeric"
          inputClassName={isNarrowScreen ? 'mobile-auth-code-input' : 'auth-code-input'}
          containerClassName={isNarrowScreen ? 'mobile-auth-code-container' : 'auth-code-container'}
        />
      </div>
      <p>
        Didn't get the code?{' '}
        {resendAtTime ? (
          <span>
            Resend code in{' '}
            <Countdown
              value={resendAtTime}
              key="resend-code-countdown"
              format="mm:ss"
              onFinish={() => setResendAtTime(undefined)}
              className="countdown"
            />
          </span>
        ) : (
          <a
            href="/"
            style={{
              pointerEvents: resendingCode ? 'none' : 'auto',
              opacity: resendingCode ? 0.6 : 1
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!resendingCode) onResendCode();
            }}
          >
            Resend Code
          </a>
        )}{' '}
        {resendingCode && <Spin size="small" />}
      </p>
    </StepContentLayout>
  );
};

const TryAgainError: React.VFC<{
  onFinish: () => any;
  time: number;
}> = (props) => {
  return (
    <p style={{ margin: 0 }}>
      Too many attempts. Please try again in{' '}
      <Countdown
        value={props.time}
        key="resend-code-countdown"
        format="mm:ss"
        onFinish={props.onFinish}
        className="countdown"
      />
      .
    </p>
  );
};
