import { fhirclient } from 'fhirclient/dist/lib/types';

export enum BROADCAST_MESSAGE_TYPE {
  LOGOUT = 'logout',
  LOGIN = 'login',
  IS_LOGGED_IN = 'is_logged_in',
  REFRESH = 'refresh',
  HAS_LOGOUT_TOKEN = 'has_logout_token',
  LOGOUT_TOKEN = 'logout_token'
}

interface IBroadcastMessageListeners {
  [BROADCAST_MESSAGE_TYPE.LOGIN]: (state: fhirclient.ClientState) => any;
  [BROADCAST_MESSAGE_TYPE.REFRESH]: (state: fhirclient.ClientState) => any;
  [BROADCAST_MESSAGE_TYPE.LOGOUT]: () => any;
  [BROADCAST_MESSAGE_TYPE.IS_LOGGED_IN]: () => any;
  [BROADCAST_MESSAGE_TYPE.HAS_LOGOUT_TOKEN]: () => any;
  [BROADCAST_MESSAGE_TYPE.LOGOUT_TOKEN]: (token: string) => any;
}

/**
 * Broadcast service for sending and receiving auth related messages across tabs
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
 */
export abstract class Broadcast {
  static channel: BroadcastChannel & {
    postMessage(type: BROADCAST_MESSAGE_TYPE, payload?: any): void;
  } = new BroadcastChannel('vaxrecordns');

  static registerListeners(listeners: IBroadcastMessageListeners) {
    this.channel.onmessage = (event) => {
      const { data } = event;
      const listener = listeners[data.type as BROADCAST_MESSAGE_TYPE];
      if (listener) listener(data.payload);
    };
  }

  static logout() {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.LOGOUT
    });
  }

  static hasLogoutToken() {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.HAS_LOGOUT_TOKEN
    });
  }

  static logoutToken(token: string) {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.LOGOUT_TOKEN,
      payload: token
    });
  }

  static isLoggedIn() {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.IS_LOGGED_IN
    });
  }

  static login(state: fhirclient.ClientState) {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.LOGIN,
      payload: state
    });
  }

  static refresh(state: fhirclient.ClientState) {
    Broadcast.channel.postMessage({
      type: BROADCAST_MESSAGE_TYPE.REFRESH,
      payload: state
    });
  }
}
