import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mime from 'mime/lite';
import {
  CloseCircleFilled,
  DeleteFilled,
  LoadingOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { colors } from '@canimmunize/tools';
import { Progress, Space } from 'antd';
import React from 'react';
import { DropEvent, FileError, FileRejection, useDropzone } from 'react-dropzone';
import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';

export interface IFileFieldProps {
  files: IUploaderFile[];
  setFiles: (files: IUploaderFile[]) => any;
  accept: Array<string>;
  dropText?: string;
  maxFiles: number;
  maxSize?: number;
}

export enum UploaderFileState {
  SELECTED = 'SELECTED',
  UPLOADING = 'UPLOADING',
  REJECTED = 'REJECTED',
  UPLOADED = 'UPLOADED',
  UPLOAD_ERROR = 'UPLOAD_ERROR'
}

export interface IUploaderFile {
  object: File;
  state: UploaderFileState;
  errors: FileError[];
  uploadProgress?: number;
}

export const FileField = (props: IFileFieldProps) => {
  const [displayFiles, setDisplayFiles] = React.useState<IUploaderFile[]>();

  React.useEffect(() => {
    if (!props.files) return;
    if (props.files.length !== displayFiles?.length) {
      const newFiles = props.files.map((file) => {
        return { ...file };
      });
      setDisplayFiles(newFiles);
    } else {
      let fileOverride = false;
      const newFiles = props.files.map((file, i) => {
        const fileCopy = { ...file };
        const overrideFile =
          displayFiles[i]?.state !== UploaderFileState.SELECTED &&
          file.state === UploaderFileState.SELECTED;
        if (displayFiles[i]?.object !== file.object) {
          return fileCopy;
        } else if (overrideFile) {
          fileOverride = true;
          return { ...displayFiles[i] };
        } else {
          return fileCopy;
        }
      });
      if (fileOverride) props.setFiles(newFiles);
      else setDisplayFiles(newFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files]);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const newFiles: IUploaderFile[] = [];
    const previousFiles = props.files ?? [];

    if (acceptedFiles?.length) {
      const convertedFiles = acceptedFiles.reduce<Array<IUploaderFile>>((files, file) => {
        // Only accept the max number of files
        const previousAcceptedFiles = previousFiles.filter(
          (file) =>
            ![UploaderFileState.REJECTED, UploaderFileState.UPLOAD_ERROR].includes(file.state)
        );

        if (previousAcceptedFiles.length + files.length < props.maxFiles) {
          files.push({
            object: file,
            state: UploaderFileState.SELECTED,
            errors: []
          });
        } else {
          files.push({
            object: file,
            state: UploaderFileState.REJECTED,
            errors: [
              {
                message: 'Too many files',
                code: 'too-many-files'
              }
            ]
          });
        }
        return files;
      }, []);
      newFiles.push(...convertedFiles);
    }
    if (fileRejections?.length) {
      const convertedFiles = fileRejections.map((rejection) => {
        const errors = rejection.errors.map((error) => {
          if (error.code === 'file-too-large' && props.maxSize) {
            return {
              ...error,
              message: `File is larger than ${sizeOf(props.maxSize)}`
            };
          }
          return error;
        });
        return {
          object: rejection.file,
          state: UploaderFileState.REJECTED,
          errors: errors
        };
      });
      newFiles.push(...convertedFiles);
    }
    props.setFiles([...previousFiles, ...newFiles]);
  };

  const onRemoveFile = (file: IUploaderFile) => {
    if (displayFiles) {
      const udpatedFiles = displayFiles?.filter((f) => f !== file);
      props.setFiles(udpatedFiles);
    }
  };

  return (
    <div>
      <Dropzone
        onDrop={onDrop}
        accept={props.accept}
        dropText={props.dropText}
        maxFiles={props.maxFiles}
        maxSize={props.maxSize ?? 26214400}
      />
      <Space direction="vertical" style={{ width: '100%' }}>
        {displayFiles?.map((file, i) => {
          return <UploadFileInfo file={file} key={i} onRemove={() => onRemoveFile(file)} />;
        })}
      </Space>
    </div>
  );
};

interface IUploadFileInfoProps {
  file: IUploaderFile;
  onRemove: () => any;
}

const UploadFileInfo = (props: IUploadFileInfoProps) => {
  const { object: file, state, uploadProgress } = props.file;

  const [hoveringContainer, setHoveringContainer] = React.useState(false);
  const [hoveringDelete, setHoveringDelete] = React.useState(false);

  const isError = state === UploaderFileState.UPLOAD_ERROR || state === UploaderFileState.REJECTED;
  const hideDelete = state === UploaderFileState.UPLOADING || state === UploaderFileState.UPLOADED;
  const showProgress =
    state === UploaderFileState.UPLOADING || state === UploaderFileState.UPLOADED;

  const containerHoveringStyle = hoveringContainer ? { backgroundColor: '#f3f3f3' } : undefined;
  const deleteHoveringStyle = hoveringDelete ? { transform: 'scale(1.2)' } : undefined;
  let icon,
    progressStatus: 'active' | 'normal' | 'success' | 'exception' = 'active';

  if (state === UploaderFileState.UPLOAD_ERROR) {
    icon = <CloseCircleFilled style={{ color: 'red', marginRight: 5, marginLeft: 5 }} />;
    progressStatus = 'exception';
  } else if (state === UploaderFileState.REJECTED) {
    icon = <CloseCircleFilled style={{ color: 'red', marginRight: 5, marginLeft: 5 }} />;
  } else if (state === UploaderFileState.SELECTED) {
    icon = <PaperClipOutlined style={{ color: 'grey', marginRight: 5, marginLeft: 5 }} />;
  } else if (state === UploaderFileState.UPLOADING) {
    icon = <LoadingOutlined spin style={{ marginRight: 5, marginLeft: 5 }} />;
    progressStatus = 'active';
  } else if (state === UploaderFileState.UPLOADED) {
    icon = <PaperClipOutlined style={{ color: 'grey', marginRight: 5, marginLeft: 5 }} />;
    progressStatus = 'success';
  }

  const firstError = props.file.errors.length ? props.file.errors[0] : undefined;

  return (
    <div
      style={{
        ...containerHoveringStyle,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 8,
        transition: 'background-color 200ms, height 200ms'
      }}
      onMouseEnter={() => setHoveringContainer(true)}
      onMouseLeave={() => setHoveringContainer(false)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>
          <span className="anticon-block">{icon}</span>
          <span style={{ color: isError ? 'red' : 'initial' }}>
            {file.name}
            <span style={{ color: colors.noteGray }}>
              {' '}
              {firstError && ` - ${firstError.message}`}
            </span>
          </span>
        </div>
        <div
          onMouseEnter={() => setHoveringDelete(true)}
          onMouseLeave={() => setHoveringDelete(false)}
        >
          {!hideDelete && (
            <span className="anticon-block">
              <DeleteFilled
                style={{
                  ...deleteHoveringStyle,
                  transition: 'transform 200ms',
                  marginRight: 8,
                  marginLeft: 8,
                  color: 'red'
                }}
                onClick={props.onRemove}
              />
            </span>
          )}
        </div>
      </div>
      <div style={{ marginLeft: 5, marginRight: 5 }}>
        {showProgress && <Progress status={progressStatus} percent={uploadProgress} />}
      </div>
    </div>
  );
};

interface IDropzoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => any;
  maxFiles: number;
  maxSize: number;
  accept?: string[];
  dropText?: string;
}

const Dropzone = ({ onDrop, maxFiles, maxSize, accept, dropText }: IDropzoneProps) => {
  const multiple = maxFiles > 1;

  const mimeTypes = accept?.reduce<Array<string>>((mimeTypes, type) => {
    const mimeType = mime.getType(type);
    if (mimeType) {
      mimeTypes.push(mimeType);
    } else if (mime.getExtension(type)) {
      mimeTypes.push(type);
    } else {
      console.error(`Invalid mime type or extention: ${type}`);
    }
    return mimeTypes;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: mimeTypes,
    maxFiles,
    maxSize
  });

  const extentions = mimeTypes?.map((type) => `.${mime.getExtension(type)}`);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className="file-input-dropzone"
        style={{
          textAlign: 'center',
          backgroundColor: '#fcfcfc',
          marginBottom: 10,
          border: '2px lightgrey dashed',
          padding: 20,
          borderRadius: 20,
          height: '70%',
          minHeight: 100,
          maxHeight: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: colors.darkBlue
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={faFileArrowUp}
            style={{
              fontSize: 24,
              color: colors.darkBlue
            }}
          />
          <div>
            <span style={{ fontSize: 22, marginTop: 5, marginBottom: 0, color: colors.darkBlue }}>
              {`Add File${multiple ? 's' : ''}`}
            </span>
          </div>
          <div>
            <span>
              {dropText ?? `(${extentions?.join(', ')} only. ${sizeOf(maxSize)} maximum)`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const sizeOf = (bytes: number) => {
  if (bytes === 0) {
    return '0B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  const roundedSize = Math.round((bytes / Math.pow(1024, e)) * 100) / 100;
  const extention = ' KMGTP'.charAt(e) + 'B';
  return roundedSize + extention;
};

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const string = reader.result as string;
      resolve(string.split(',')[1]);
    };
    reader.onerror = (error) => reject(error);
  });
