import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface IDuotoneFontAwesomeIconProps extends FontAwesomeIconProps {
  primaryColor?: string;
  secondaryColor?: string;
}

type TFontAwesomeProperties = {
  '--fa-primary-color'?: string;
  '--fa-secondary-color'?: string;
};

export function DuotoneFontAwesomeIcon(props: IDuotoneFontAwesomeIconProps) {
  const primaryColor: string | undefined = props.primaryColor || '#2280C1';
  const secondaryColor: string | undefined = props.secondaryColor || '#00538f';

  const style: React.CSSProperties & TFontAwesomeProperties = props.style ? { ...props.style } : {};
  style.color = secondaryColor;
  style['--fa-primary-color'] = primaryColor;
  style['--fa-secondary-color'] = secondaryColor;
  style['--fa-primary-opacity'] = '0.8';
  style['--fa-secondary-opacity'] = '1.0';

  return <FontAwesomeIcon {...props} style={style} />;
}
