import { R4 } from '@ahryman40k/ts-fhir-types';

export interface Immunization extends R4.IImmunization {
  genericName?: string;
  genericCode?: string;
  tradename?: string;
  tradenameCode?: string;
}

export const parseImmsBundle = (bundle: R4.IBundle): Array<Immunization> => {
  const imms = bundle.entry
    ?.filter((e) => e.resource!.resourceType === 'Immunization')
    .map((e) => parseImmunization(e.resource as R4.IImmunization));

  return imms ?? [];
};

export const parseImmunization = (immunization: R4.IImmunization): Immunization => {
  const tradenameCoding = immunization.vaccineCode.coding?.filter((coding) => {
    return coding.system === 'https://cvc.canimmunize.ca/v3/ValueSet/Tradename';
  });

  const genericCoding = immunization.vaccineCode.coding?.filter((coding) => {
    return coding.system === 'https://cvc.canimmunize.ca/v3/ValueSet/Generic';
  });

  return {
    ...immunization,
    genericName: genericCoding?.[0]?.display,
    tradename: tradenameCoding?.[0]?.display?.replace('?', ''),
    genericCode: genericCoding?.[0]?.code,
    tradenameCode: tradenameCoding?.[0]?.code
  };
};
