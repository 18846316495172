import { AbstractValidationRule } from './abstract-validation-rule';

export const JsonValidationRuleId = 'json';

export interface JsonValidationRule extends AbstractValidationRule {
  validationRuleType: typeof JsonValidationRuleId;
}

/**
 * Validation to check whether a value is a valid JSON string. This will
 * return true if the value is null or undefined, use a required rule
 * to check for that.
 *
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export function validateJsonRule(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  try {
    const parsedJson = JSON.parse(value);
    if (typeof parsedJson === 'object') {
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
}
