import { ExclamationCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Button, ButtonProps, message, Spin } from 'antd';
import React from 'react';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { DisconnectDependentModalStep, DisconnectStepContentLayout } from '..';
import { ResponsiveFooter } from 'src/utils/responsive-footer';

export const DisconnectDependentStep: DisconnectDependentModalStep = (props) => {
  const { lambdasClient } = useSmartApp();
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    props.setTitle('Warning');
  }, [props]);

  React.useEffect(() => {
    if (submitting) {
      disconnectDependent(String(props?.patient?.hcn), String(props?.patient?.relation));
    }
  }, [submitting]);

  const disconnectDependent = async (dependentHCN: string, relation: string) => {
    return await lambdasClient
      ?.post('Dependent/remove', {
        dependentHCN: dependentHCN,
        relation: relation
      })
      .then(props.next)
      .catch(() => {
        message.error('An error occurred. Please try again later.');
        setSubmitting(false);
      });
  };
  if (submitting) {
    return (
      <DisconnectStepContentLayout>
        <div className="add-dependent-centered-container">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
            style={{ paddingBottom: 20 }}
          />
          <p>Removing Dependent</p>
        </div>
      </DisconnectStepContentLayout>
    );
  }

  const CancelButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={props.close}
      type="primary"
      ghost
      style={{ minWidth: 150 }}
      size="large"
      {...p}
    >
      Cancel
    </Button>
  );

  const AcceptButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={() => {
        setSubmitting(true);
      }}
      type="primary"
      style={{ minWidth: 150 }}
      size="large"
      {...p}
    >
      Accept
    </Button>
  );

  return (
    <DisconnectStepContentLayout
      footer={<ResponsiveFooter OkButton={AcceptButton} CancelButton={CancelButton} />}
    >
      <p>
        <span>You are about to disconnect this dependent from your account.</span>
        <br />
        <span>Would you like to continue?</span>
      </p>

      <p style={{ display: 'inline-flex', marginBottom: 18, marginTop: 18, fontSize: 12 }}>
        <ExclamationCircleTwoTone
          twoToneColor="orange"
          style={{ paddingTop: 4, paddingRight: 4 }}
        />
        Disconnecting the dependent's account will remove the connection from your account. You may
        reconnect your dependent if they are less than 16 years old. Disconnecting your dependent
        will not affect their public health record.
      </p>
    </DisconnectStepContentLayout>
  );
};
