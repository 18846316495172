import { LoadingOutlined } from '@ant-design/icons';
import { colors } from '@canimmunize/tools';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonProps,
  Card,
  Checkbox,
  Col,
  Modal,
  Row,
  Skeleton,
  Space,
  Table,
  Tabs,
  message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { Immunization } from 'src/data/immunization';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { useWindowDimensions } from 'src/utils/window-dimensions';
import { downloadPVCPdf } from 'src/utils/download-pvc';
import { ResponsiveFooter } from 'src/utils/responsive-footer';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';
import barcodeImg from 'src/assets/5-phone.jpg';

enum TAB_KEYS {
  VACCINATIONS = 'vaccinations',
  COVID_19 = 'covid19'
}

const defaultActiveTab = TAB_KEYS.VACCINATIONS;

export const PatientView: React.VFC = () => {
  /* 
    Instead of just adding ?s everywhere as I did here, consider refactoring to properly handle bad patientIds (i.e. users typing the patientId manually in the url).
   */
  const [selectableImmsModalVisible, setSelectableImmsModalVisible] = React.useState(false);
  const { allPatients, loading } = useA2iData();
  const [pvcDownloading, setPvcDownloading] = React.useState(false);
  const [covidPvcDownloading, setCovidPvcDownloading] = React.useState(false);
  const [mobilePvcDownloading, setMobilePvcDownloading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(defaultActiveTab);
  const { patientId } = useParams();
  const { lambdasClient } = useSmartApp();

  const [aadFile, setAadFile] = React.useState<any>();
  const [nameMappingFile, setNameMappingFile] = React.useState<any>();

  const patient = allPatients.find((patient) => patient?.id === patientId);
  const { width } = useWindowDimensions();
  const isNarrowScreen = width <= 760;

  // load CVC files immediately upon loading file
  React.useEffect(() => {
    if (!aadFile && !nameMappingFile) {
      pullCvcFiles();
    }
  }, []);

  // pull cvc-aad.json & disease-name-mapping.json files from s3 bucket
  const pullCvcFiles = () => {
    if (!aadFile || !nameMappingFile) {
      try {
        lambdasClient?.get('/cvcFiles').then((response) => {
          const aadFileResponse = JSON.parse(response.data.aadFile);
          const nameMappingFileResponse = JSON.parse(response.data.nameMappingFile);
          setAadFile(aadFileResponse);
          setNameMappingFile(nameMappingFileResponse);
        });
      } catch (err) {
        message.error('An error occurred. Please try again later');
      }
    }
  };

  const downloadCOVIDProofOfVaccination = () => {
    setCovidPvcDownloading(true);
    downloadCovidProofOfVaccinations('pdf')
      .catch((err) => {
        console.log('Error downloading standard PVC');
        console.log(err);
      })
      .finally(() => {
        setCovidPvcDownloading(false);
      });
  };

  const downloadCOVIDMobileProofOfVaccination = () => {
    setMobilePvcDownloading(true);
    downloadCovidProofOfVaccinations('mobile')
      .catch((err) => {
        console.log('Error downloading mobile PVC');
        console.log(err);
      })
      .finally(() => {
        setMobilePvcDownloading(false);
      });
  };

  const downloadFullProofOfVaccinations = (selectedDiseases: string[]) => {
    setPvcDownloading(true);
    downloadFullVaxRecord(selectedDiseases)
      .catch((err) => {
        console.log('Error downloading full record PVC');
        console.log(err);
      })
      .finally(() => {
        setPvcDownloading(false);
      });
  };

  const downloadCovidProofOfVaccinations = async (type: string) => {
    if (patient?.immunizations.length === 0) {
      alert('This functionality is not available without any vaccination records.');
      return;
    }

    try {
      const response = await lambdasClient?.get(`/pvc/${patientId}`, {
        params: {
          type: type
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      });

      const pdf = response?.data?.pdf;
      downloadPVCPdf(pdf, patient, type);
      return response;
    } catch (err) {
      message.error('An error occurred. Please try again later');
    }
  };

  const downloadFullVaxRecord = async (selectedDiseases: string[]) => {
    if (patient?.immunizations.length === 0) {
      alert('This functionality is not available without any vaccination records.');
      return;
    }

    try {
      const response = await lambdasClient?.get(`/fullVaxRecord/${patientId}`, {
        params: {
          selectedDiseases: qs.stringify(selectedDiseases, {
            arrayFormat: 'separator',
            arrayFormatSeparator: '|'
          })
        }
      });

      const pdf = response?.data;
      downloadPVCPdf(pdf, patient, 'full');
      return response;
    } catch (err) {
      message.error('An error occurred. Please try again later.');
    }
  };

  const covidDoses = patient?.immunizations.filter((i) => {
    return i.vaccineCode.coding?.some(
      (coding) =>
        coding.system === 'https://cvc.canimmunize.ca/v3/ValueSet/Generic' &&
        coding.display?.includes('COVID-19')
    );
  });

  const validCovidDoses = covidDoses?.filter((i) => {
    return i.vaccineCode.coding?.some((coding) => {
      return (
        coding.system === 'https://cvc.canimmunize.ca/v3/ValueSet/Tradename' &&
        coding.display?.includes('COVID-19')
      );
    });
  });

  const close = React.useCallback(
    () => setSelectableImmsModalVisible(false),
    [setSelectableImmsModalVisible]
  );

  return (
    <div style={{}} className="patient-view">
      <div style={{ backgroundColor: 'white' }}>
        <div className="container">
          <Row
            justify="space-between"
            gutter={[25, 10]}
            align="middle"
            style={{ padding: '15px 0' }}
          >
            <Col>
              <h1>
                {loading ? (
                  <Skeleton
                    style={{ width: 200, paddingTop: 20 }}
                    active
                    round
                    paragraph={false}
                    title={{ width: 350 }}
                  />
                ) : (
                  patient?.displayName
                )}
              </h1>
              <h2>
                {loading ? (
                  <Skeleton
                    style={{ width: 125, paddingTop: 10 }}
                    active
                    round
                    paragraph={false}
                    title={{ width: 250 }}
                  />
                ) : (
                  `Date of Birth: ${moment(patient?.birthDate)
                    .format('YYYY-MMM-DD')
                    .toLocaleUpperCase()}`
                )}
              </h2>
            </Col>
          </Row>
          <Tabs
            tabBarStyle={{ marginBottom: 0, fontSize: 16 }}
            defaultActiveKey={TAB_KEYS.VACCINATIONS}
            onChange={(key) => setActiveTab(key as TAB_KEYS)}
          >
            {/* Putting the tab content inside of the TabPane breaks the styling */}
            <Tabs.TabPane tab="Vaccinations" key={TAB_KEYS.VACCINATIONS} />
            <Tabs.TabPane tab="COVID-19 Proof of Vaccination" key={TAB_KEYS.COVID_19} />
          </Tabs>
        </div>
      </div>
      <div className="container" style={{ marginTop: 20 }}>
        {activeTab === TAB_KEYS.VACCINATIONS && (
          <Card style={{ width: '100%' }}>
            <Row
              style={{ marginBottom: 20 }}
              justify="space-between"
              align="middle"
              gutter={[15, 15]}
            >
              <Col>
                <h3 style={{ fontSize: 24 }}>My Vaccination Records</h3>
                <h4 style={{ fontSize: 16, fontWeight: 'normal' }}>
                  View and download your vaccination records.
                </h4>
              </Col>
              {!!patient?.immunizations.length && (
                <Col>
                  <Button
                    loading={pvcDownloading}
                    disabled={
                      pvcDownloading ||
                      mobilePvcDownloading ||
                      covidPvcDownloading ||
                      !aadFile ||
                      loading
                    }
                    size="large"
                    type="primary"
                    onClick={() => {
                      setSelectableImmsModalVisible(true);
                    }}
                  >
                    Download Vaccination Record
                  </Button>
                </Col>
              )}
              {isNarrowScreen && (
                <Col>
                  <p style={{ textAlign: 'left', margin: 0 }}>
                    Note: On smaller screen sizes you will need to scroll to view all of your
                    information.
                  </p>
                </Col>
              )}
            </Row>
            {loading ? (
              <SkeletonTable />
            ) : (
              <Table
                scroll={{ x: 300 }}
                columns={immsTableColumns}
                dataSource={patient?.immunizations}
                locale={{ emptyText: 'No Records Found' }}
                pagination={{ hideOnSinglePage: true }}
                rowKey={'id'}
              />
            )}
            {patient?.immunizations?.some((imm) => !!imm.isSubpotent) && (
              <div style={{ marginTop: 15 }}>
                <Space style={{ margin: 0 }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    size="lg"
                    inverse
                    style={{ background: '#00BFFF', borderRadius: '50%' }}
                  />
                  Some or part of the vaccine did not meet the Nova Scotia vaccine schedule. Please
                  contact your healthcare provider or Public Health for further information.
                </Space>
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <p style={{ textAlign: 'center', margin: 0 }}>
                Don't see your records above or are they incorrect?
              </p>
              <p
                style={{
                  textDecorationLine: 'underline',
                  textAlign: 'center'
                }}
              >
                <Link to={'/contact-support'}>
                  <li>Contact Support</li>
                </Link>
              </p>
            </div>
          </Card>
        )}

        {activeTab === TAB_KEYS.COVID_19 && (
          <Card style={{ marginTop: 20 }} bodyStyle={{ display: 'flex', minHeight: 176 }}>
            <div style={{ flexGrow: 2, flexShrink: 1, paddingRight: 20 }}>
              <h3 style={{ marginBottom: 20 }}>Nova Scotia COVID-19 Proof of Vaccination</h3>
              <p>
                {!patient?.immunizations.length // are there no immunizations
                  ? 'No records found.'
                  : !covidDoses?.length // are there no covid imms
                  ? `${patient?.displayName} doesn't have any COVID-19 vaccinations.`
                  : validCovidDoses?.length // are there valid covid imms
                  ? `To download a PDF of “Your Nova Scotia COVID-19 Proof of Vaccination” click the button below.`
                  : // there are only invalid covid imms
                    `We are unable to provide a COVID-19 proof of vaccination because it does not meet 
                Federal requirements to display in VaxRecordNS. Contact support for further
                information.`}
              </p>
              {!!validCovidDoses?.length && (
                <Space direction="vertical" style={{ width: '100%' }}>
                  {/* Using a custom spinner instead of the loading prop because of vertical alignment issues */}
                  <Button
                    type="primary"
                    onClick={downloadCOVIDProofOfVaccination}
                    disabled={
                      pvcDownloading || mobilePvcDownloading || covidPvcDownloading || loading
                    }
                    size="large"
                    block={isNarrowScreen}
                  >
                    {covidPvcDownloading && (
                      <LoadingOutlined
                        style={{ color: 'rgba(0, 0, 0, 0.25)', verticalAlign: '0.07em' }}
                        spin
                      />
                    )}
                    Download Proof of Vaccination
                  </Button>
                  {isNarrowScreen && (
                    <Button
                      type="primary"
                      onClick={downloadCOVIDMobileProofOfVaccination}
                      disabled={pvcDownloading || mobilePvcDownloading || covidPvcDownloading}
                      size="large"
                      block={isNarrowScreen}
                    >
                      {mobilePvcDownloading && (
                        <LoadingOutlined
                          style={{ color: 'rgba(0, 0, 0, 0.25)', verticalAlign: '0.07em' }}
                          spin
                        />
                      )}
                      Download Mobile Proof of Vaccination
                    </Button>
                  )}
                </Space>
              )}
            </div>
            {!isNarrowScreen && (
              <div
                style={{
                  flexGrow: 1,
                  alignSelf: 'stretch',
                  minWidth: 250,
                  borderRadius: 10,
                  backgroundImage: `url(${barcodeImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              ></div>
            )}
          </Card>
        )}
      </div>
      <div>
        <SelectableImmsModal
          patient={patient}
          onCancel={close}
          visible={selectableImmsModalVisible}
          downloading={pvcDownloading}
          onDowload={(values) => {
            setSelectableImmsModalVisible(false);
            downloadFullProofOfVaccinations(values);
          }}
          aadFile={aadFile}
          nameMappingFile={nameMappingFile}
        />
      </div>
    </div>
  );
};

const SelectableImmsModal: React.FC<{
  patient: any;
  onCancel: () => any;
  visible: boolean;
  downloading: boolean;
  onDowload: (values: Array<string>) => any;
  aadFile: any;
  nameMappingFile: any;
}> = (props) => {
  // this array will hold mapping of cvc/display names for reverse mapping before passing value to lambda
  const [diseaseOptions, setDiseaseOptions] = React.useState<any[]>([]);
  const [displayNames, setDisplayNames] = React.useState<string[]>(['Select/Unselect All']);
  // selected diseases tracked here
  const [values, setValues] = React.useState<string[]>(displayNames);
  const { loading } = useA2iData();
  const { aadFile, nameMappingFile } = props;
  const imms = props.patient.immunizations;

  // once CVC files are loaded, fetch disease options
  React.useEffect(() => {
    if (!props.nameMappingFile) return;
    fetchDiseases();
  }, [props.nameMappingFile]);

  // pull the generic snomed code for each imm
  const immSnomedCodes = imms.map((imm) => {
    return imm.vaccineCode.coding?.filter((coding) => {
      return coding.system === 'https://cvc.canimmunize.ca/v3/ValueSet/Generic';
    })[0].code;
  });

  const fetchDiseases = () => {
    // filter through aad.json file by snomed code to find all potential selectable diseases
    if (!!aadFile && !!nameMappingFile) {
      for (let i = 0; i < aadFile?.length; i++) {
        // if user has imms w/ snomed code, pull preventable diseases
        if (immSnomedCodes.includes(aadFile[i].agentConceptId)) {
          const cvcDiseaseName = aadFile[i].diseaseDisplayTermEn;

          const diseaseOption = nameMappingFile.filter((obj) => {
            return obj.cvcDiseaseName === cvcDiseaseName;
          });
          // if no display name found in mapping file, set both names to CVC disease name
          if (diseaseOption.length === 0) {
            diseaseOption.push({
              displayName: cvcDiseaseName,
              cvcDiseaseName: cvcDiseaseName
            });
          }

          // Note: can't use .includes() on obj as it doesn't compare values of objects, it compares
          // memory references. Cast it to a string value to compare values.
          const diseaseOptionAlreadyExists = diseaseOptions.some((obj) => {
            return JSON.stringify(diseaseOption) === JSON.stringify(obj);
          });
          if (!diseaseOptionAlreadyExists) {
            diseaseOptions.push(diseaseOption);
          }
        }
      }

      // push display names to owning array
      diseaseOptions.map((obj) => {
        displayNames.push(obj[0].displayName);
      });
    }

    // sort options alphabetically, excluding select all option
    displayNames.sort(function (a, b) {
      if (a == 'Select/Unselect All') return -1;
      if (b == 'Select/Unselect All') return 1;
      return a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : 0;
    });

    setValues(displayNames);
  };

  const onChange = (newValue) => {
    let newValues;
    if (newValue === 'Select/Unselect All' && !values?.includes('Select/Unselect All')) {
      // select all diseases
      newValues = displayNames;
      setValues(newValues);
    } else if (newValue === 'Select/Unselect All' && values?.includes('Select/Unselect All')) {
      //deselect all diseases
      setValues([]);
    } else {
      // toggle new value
      if (values?.includes(newValue)) {
        // if newValue goes T -> F, uncheck it
        newValues = values?.filter((value) => value !== newValue);
      } else {
        // if newValue not in values, add it
        newValues = [...values, newValue];
      }

      // if all diseases selected, add 'Select All' to values
      if (
        newValues.length === displayNames.length - 1 &&
        !newValues.includes('Select/Unselect All')
      ) {
        newValues.push('Select/Unselect All');
      }
      // if not all diseases selected, remove 'Select All' from values
      else if (
        newValues.length <= displayNames.length - 1 &&
        newValues.includes('Select/Unselect All')
      ) {
        newValues = newValues.filter((value) => value !== 'Select/Unselect All');
      }

      setValues(newValues);
    }
  };

  const openReportClicked = () => {
    // Map display disease name to CVC disease name before assigning options to values.
    // The lambdas need tor receive the CVC disease name for them to succeed.
    const diseases = diseaseOptions.filter((obj) => {
      return values.includes(obj[0].displayName);
    });

    const cvcDiseases = diseases.map((obj) => {
      return obj[0].cvcDiseaseName;
    });

    props.onDowload(cvcDiseases);
  };

  const DownloadButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={openReportClicked}
      loading={props.downloading}
      disabled={props.downloading || !values?.length || loading}
      size="large"
      type="primary"
      {...p}
    >
      Download Vaccination Record
    </Button>
  );

  const CancelButton: React.FC<ButtonProps> = (p) => (
    <Button onClick={props.onCancel} type="primary" ghost size="large" {...p}>
      Cancel
    </Button>
  );

  const modalTopSpace = useValueFromBreakpoint([
    ['xs', 50],
    ['md', 100]
  ]);

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={null}
      title={
        <p style={{ color: '#00538f', margin: 0, fontWeight: 'bold', lineHeight: 1.8 }}>
          Download Your Vaccination Record
        </p>
      }
      style={{
        alignItems: 'center',
        padding: 10,
        top: modalTopSpace
      }}
      maskClosable={false}
    >
      <div>
        <p>Select the vaccination groups you would like to include in your vaccination record:</p>
        {displayNames.map((option, i) => {
          return (
            <div key={i} style={{ display: 'flex', padding: 5 }}>
              <Checkbox
                type="checkbox"
                style={{
                  marginRight: 40,
                  marginLeft: 40,
                  flex: '5%'
                }}
                className="darkenedCheckbox"
                onChange={() => onChange(option)}
                checked={values?.includes(option)}
              />
              <span
                style={{
                  fontWeight: option === 'Select/Unselect All' ? 'bold' : 'normal',
                  flex: '80%'
                }}
              >
                {option}
              </span>
            </div>
          );
        })}
        <div style={{ marginTop: 40 }}>
          <ResponsiveFooter OkButton={DownloadButton} CancelButton={CancelButton} />
        </div>
      </div>
    </Modal>
  );
};

const SkeletonTable: React.VFC = () => {
  return (
    <Table
      dataSource={[{} as Immunization, {} as Immunization]}
      columns={immsTableColumns.map((col) => ({
        ...col,
        render: () => (
          <Skeleton
            active
            round
            paragraph={false}
            style={{ marginTop: 3, marginBottom: 3 }}
            title={{ width: 100 }}
          />
        )
      }))}
    />
  );
};

const immsTableColumns: ColumnsType<Immunization> = [
  {
    title: 'Generic Name',
    dataIndex: 'genericName',
    key: 'genericName',
    sorter: (a, b) => {
      if (!a.genericName) return +1;
      if (!b.genericName) return -1;
      const nameA = a.genericName;
      const nameB = b.genericName;
      return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
    },
    render: (genericName, immunization) => (
      <Space align="center">
        {immunization.isSubpotent && (
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="lg"
            inverse
            style={{ background: '#00BFFF', borderRadius: '50%' }}
          />
        )}
        {!!genericName ? genericName : <span style={{ color: colors.lightGray }}>N/A</span>}
      </Space>
    )
  },
  {
    title: 'Trade Name',
    dataIndex: 'tradename',
    key: 'tradename',
    sorter: (a, b) => {
      if (!a.tradename) return +1;
      if (!b.tradename) return -1;
      const nameA = a.tradename;
      const nameB = b.tradename;
      return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
    },
    render: (tradename, immunization) => (
      <Space align="center">
        {!!tradename ? tradename : <span style={{ color: colors.lightGray }}>N/A</span>}
      </Space>
    )
  },
  {
    title: 'Date Administered',
    dataIndex: 'occurrenceDateTime',
    key: 'occurrenceDateTime',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      return moment(a.occurrenceDateTime).diff(moment(b.occurrenceDateTime));
    },
    render: (occurrenceDateTime) => {
      return moment(occurrenceDateTime).format('YYYY-MMM-DD').toUpperCase();
    }
  }
];
