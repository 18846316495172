import moment from 'moment';
import { AbstractValidationRule } from './abstract-validation-rule';

export const BirthDateValidationRuleId = 'birthDate';
export const YYYYMMDDValidationRuleId = 'YYYYMMDD';

export interface BirthDateValidationRule extends AbstractValidationRule {
  validationRuleType: typeof BirthDateValidationRuleId;
}

export interface YYYYMMDDValidationRule extends AbstractValidationRule {
  validationRuleType: typeof YYYYMMDDValidationRuleId;
}

/**
 * Validation to check if a birthdate is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateBirthDateRule(birthdate: any): boolean {
  // If no birthdate provided, then valid.
  if (birthdate === '' || !birthdate) return true;

  if (!!birthdate && moment(birthdate).isValid()) {
    return true;
  }

  return false;
}

export function validateYYYYMMDDDate(date: any): boolean {
  if (date === '' || !date) return true;

  if (!!date && moment(date, 'YYYY-MM-DD').isValid()) {
    return true;
  }
  return false;
}
