import { Col, Row } from 'antd';
import React from 'react';
import { ContactSupportForm } from './form';
import { ContactSupportSuccess } from './success';

export const ContactSupportPage: React.VFC = () => {
  const [submited, setIsSubmited] = React.useState(false);

  return (
    <div className="patient-view">
      <div style={{ background: 'white' }}>
        <div className="container">
          <Row
            justify="space-between"
            gutter={[25, 10]}
            align="middle"
            style={{ padding: '15px 0' }}
          >
            <Col>
              <h1>Contact Support</h1>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container" style={{ paddingTop: 20 }}>
        {!submited ? (
          <ContactSupportForm onSuccess={() => setIsSubmited(true)} />
        ) : (
          <ContactSupportSuccess />
        )}
      </div>
    </div>
  );
};
