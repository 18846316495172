import { Button, Card, Row, Space, Typography } from 'antd';
import vaxRecordNSLogo from 'src/assets/myvaxnslogo.png';
import familyImg from 'src/assets/1-family.jpg';
import poweredByLogo from 'src/assets/powered_by_logo.png';
import backgroundImage from 'src/assets/backgroundImage.png';
import { theme } from 'src/theme';

export const AccessDeniedPage = () => {
  // Pass state parameter back to mobile app - it checks that the response and request have the same state parameter
  return (
    <div className="auth-client-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPositionX: '100%',
          backgroundPositionY: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: '100%',
            alignItems: 'center',
            margin: 30
          }}
        >
          <Card
            style={{ maxWidth: 550 }}
            cover={
              <div
                style={{
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 550,
                  height: 250,
                  backgroundImage: `url(${familyImg})`,
                  borderRadius: '10px 10px 0 0'
                }}
              />
            }
          >
            <Row justify="center" style={{ marginBottom: 30 }}>
              <img style={{ width: '60%' }} src={vaxRecordNSLogo} alt="Vax Record NS" />
            </Row>
            <div>
              <Row justify="center">
                <Typography.Title
                  level={3}
                  style={{
                    textAlign: 'center',
                    marginBottom: 36,
                    fontWeight: 'normal',
                    color: theme.colors.primary[400]
                  }}
                >
                  You must be 16 or older to use this service
                </Typography.Title>
              </Row>
              <Row justify="center">
                <Button
                  type="primary"
                  style={{ borderRadius: 3, paddingLeft: 45, paddingRight: 45, marginBottom: 30 }}
                >
                  <a
                    href={`${process.env.REACT_APP_NSID_BASE_URL}/auth/eng/logout`}
                    rel="noreferrer"
                  >
                    Logout of VaxRecordNS
                  </a>
                </Button>
              </Row>
            </div>
            <Row justify="center">
              <div style={{ width: '40%' }}>
                <img
                  style={{ width: '100%' }}
                  alt="Nova Scotia. Powered by CANImmunize"
                  src={poweredByLogo}
                />
              </div>
            </Row>
          </Card>
        </Space>
      </div>
    </div>
  );
};
