import { Alert, Button, ButtonProps, Card, Col, Row } from 'antd';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modify } from 'src/utils/modify';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import {
  EmailValidationRules,
  FormUX,
  FormUXFieldType,
  RequiredValidationRule,
  ShortTextValidationRules
} from 'src/form-ux';
import { IUploaderFile, toBase64 } from 'src/form-ux/fields/files-field';
import { FormUXModel } from 'src/form-ux/models/form-ux-model';
import { PhoneValidationRuleId } from 'src/form-ux/validation/validation-rules/phone-validation';
import { ERROR_CODES, error_codes_message_map } from 'src/modals/add-dependent/steps';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';
import { ResponsiveFooter } from 'src/utils/responsive-footer';
import { useNetworkStatus } from 'src/utils/network-status';

interface IContactSupportForm {
  onSuccess: () => any;
}

export const ContactSupportForm: React.VFC<IContactSupportForm> = (props) => {
  const { lambdasClient } = useSmartApp();
  const isOnline = useNetworkStatus();
  const navigate = useNavigate();
  const { mainPatient } = useA2iData();

  const buttonWidth = useValueFromBreakpoint([
    ['xs', '100%'],
    ['sm', 220],
    ['md', 300],
    ['lg', 420]
  ]);

  const [error, setError] = React.useState<ERROR_CODES>();

  // Used to scroll the top error message into view
  const fieldRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (error && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [error]);

  const initialValues = fields.reduce<Partial<SupportForm>>((values, field) => {
    if (field.name) values[field.name] = undefined;
    return values;
  }, {});

  const onSubmit = async (
    values: Partial<SupportForm>,
    actions: FormikHelpers<Partial<SupportForm>>
  ) => {
    actions.setSubmitting(true);

    if (error) setError(undefined);

    if (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.issue ||
      !values.deviceUsed
    ) {
      return;
    }

    const attachment = values.attachment?.length
      ? {
          content: await toBase64(values.attachment[0].object),
          name: values.attachment[0].object.name
        }
      : undefined;

    // Get main user panorama id
    const panoramaUserId = mainPatient?.panoramaUserId;

    const body: Partial<SupportRequestBody> = { ...values, attachment, panoramaUserId };
    return await lambdasClient
      ?.post('/Support', body)
      .then(() => {
        props.onSuccess();
      })
      .catch((error: AxiosError<{ error?: ERROR_CODES }>) => {
        const code = error.response?.data.error;
        const errorCode = code ? ERROR_CODES[code] ?? ERROR_CODES.DEFAULT : ERROR_CODES.DEFAULT;
        setError(errorCode);
        actions.setSubmitting(false);
      });
  };

  return (
    <Card style={{ width: '100%' }}>
      <Row style={{ marginBottom: 20 }} justify="space-between" align="middle">
        <Col span={24}>
          <h4 style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 18 }}>
            Please do not include your health card number at this time. Staff will ask you for your
            personal health information / health card number only when or if necessary.
          </h4>
        </Col>
        {error && (
          <Col span={24} ref={fieldRef}>
            <Alert type="error" message={error_codes_message_map[error]} showIcon />
          </Col>
        )}
      </Row>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validateOnBlur={true}>
        {(formikProps) => {
          const SubmitButton: React.FC<ButtonProps> = (p) => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: buttonWidth }}
              size="large"
              disabled={
                !formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty || !isOnline
              }
              loading={formikProps.isSubmitting}
              onClick={() => formikProps.handleSubmit()}
              {...p}
            >
              Submit
            </Button>
          );

          const CancelButton: React.FC<ButtonProps> = (p) => (
            <Button
              type="primary"
              ghost
              style={{ width: buttonWidth }}
              size="large"
              htmlType="reset"
              onClick={() => navigate(-1)}
              {...p}
            >
              Cancel
            </Button>
          );

          return (
            <div>
              <div>
                <FormUX formUXModel={fields} />
              </div>
              <div style={{ marginBottom: 50 }}>
                <p style={{ fontSize: 16 }}>
                  Our target for issue resolution is 3 business days. If you have an urgent need for
                  your vaccination records, call your local public health office at{' '}
                  <a href="tel:+18445150675" style={{ whiteSpace: 'nowrap' }}>
                    1-844-515-0675
                  </a>
                  .
                </p>
                <p style={{ fontSize: 16 }}>
                  We are collecting your personal information on this form pursuant to the 
                  <i>Personal Health Information Act (PHIA)</i> and the{' '}
                  <i>Health Protection Act (HPA)</i> for verification in our internal records to
                  assist with troubleshooting your account. For questions related to health privacy,
                  contact the health privacy office at{' '}
                  <a href="tel:+18556404765" style={{ whiteSpace: 'nowrap' }}>
                    1-855-640-4765
                  </a>
                  .
                </p>
              </div>
              <div style={{ marginBottom: 20 }}>
                <ResponsiveFooter OkButton={SubmitButton} CancelButton={CancelButton} />
              </div>
            </div>
          );
        }}
      </Formik>
    </Card>
  );
};

type SupportForm = {
  firstName: string;
  lastName: string;
  email: string;
  issue: ISSUE;
  deviceUsed: DEVICE;
  contactNumber?: string;
  additionalDetails?: string;
  attachment?: Array<IUploaderFile>;
};

type SupportRequestBody = Modify<
  SupportForm,
  {
    panoramaUserId: string;
    attachment?: {
      content: string;
      name: string;
    };
  }
>;

enum ISSUE {
  SLOW_APP = 'SLOW_APP',
  FAILED_LOGOUT = 'FAILED_LOGOUT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  UNEXPECTED_VACCINATION_RECORDS = 'UNEXPECTED_VACCINATION_RECORDS',
  DUPLICATE_VACCINATION_RECORDS = 'DUPLICATE_VACCINATION_RECORDS',
  ERROR_VACCINATION_RECORDS = 'ERROR_VACCINATION_RECORDS',
  CANNOT_ADD_DEPENDENT = 'CANNOT_ADD_DEPENDENT',
  FAILED_POV = 'FAILED_POV',
  FAILED_COVID_POV = 'FAILED_COVID_POV',
  OTHER = 'OTHER'
}

enum DEVICE {
  ANDROID_PHONE = 'ANDROID_PHONE',
  IOS_PHONE = 'IOS_PHONE',
  ANDROID_TABLET = 'ANDROID_TABLET',
  IOS_TABLET = 'IOS_TABLET',
  PC = 'PC',
  MAC = 'MAC',
  OTHER = 'OTHER'
}

enum LOCATION {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PRIMARY_CARE_PROVIDER = 'PRIMARY_CARE_PROVIDER',
  PHARMACY = 'PHARMACY',
  PUBLIC_HEALTH = 'PUBLIC_HEALTH',
  TRAVEL_CLINIC = 'TRAVEL_CLINIC',
  OCCUPATIONAL_HEALTH = 'OCCUPATIONAL_HEALTH',
  EMERGENCY_ROOM = 'EMERGENCY_ROOM',
  HOSPITAL = 'HOSPITAL',
  SCHOOL = 'SCHOOL',
  VICTORIAN_ORDER_OF_NURSES = 'VICTORIAN_ORDER_OF_NURSES',
  OUTSIDE_NS = 'OUTSIDE_NS',
  UNKNOWN = 'UNKNOWN'
}

enum VAX_CATEGORY {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CHILDHOOD = 'CHILDHOOD',
  TRAVEL = 'TRAVEL',
  SCHOOL_PROGRAM = 'SCHOOL_PROGRAM',
  COMPLETE_RECORD = 'COMPLETE_RECORD',
  ADULT_VACCINE = 'ADULT_VACCINE',
  ANNUAL = 'ANNUAL',
  OUTSIDE_NS = 'OUTSIDE_NS',
  UNKNOWN = 'UNKNOWN'
}

const issue_options: Array<{ key: ISSUE; label: string }> = [
  { key: ISSUE.SLOW_APP, label: 'VaxRecordNS is loading very slowly' },
  { key: ISSUE.FAILED_LOGOUT, label: "The log out isn't working" },
  {
    key: ISSUE.UNEXPECTED_VACCINATION_RECORDS,
    label: "My vaccination records aren't showing up as expected"
  },
  {
    key: ISSUE.DUPLICATE_VACCINATION_RECORDS,
    label: 'I see a duplicate of my vaccination records'
  },
  { key: ISSUE.ERROR_VACCINATION_RECORDS, label: 'There is an error on my vaccination record' },
  { key: ISSUE.CANNOT_ADD_DEPENDENT, label: "I can't add a dependent" },
  { key: ISSUE.FAILED_COVID_POV, label: "I can't open/download my COVID-19 Proof of Vaccination" },
  { key: ISSUE.FAILED_POV, label: "I can't open/download my Vaccination record" },
  { key: ISSUE.OTHER, label: 'Other – (specify in Additional Details)' }
];

const device_options: Array<{ key: DEVICE; label: string }> = [
  { key: DEVICE.ANDROID_PHONE, label: 'Android/Google Phone' },
  { key: DEVICE.IOS_PHONE, label: 'iOS/Apple Phone ' },
  { key: DEVICE.ANDROID_TABLET, label: 'Android/Google Tablet' },
  { key: DEVICE.IOS_TABLET, label: 'iOS/Apple Tablet ' },
  { key: DEVICE.PC, label: 'PC' },
  { key: DEVICE.MAC, label: 'MAC' },
  { key: DEVICE.OTHER, label: 'Other (specify in Additional details)' }
];

const received_at_options: Array<{ key: LOCATION; label: string }> = [
  { key: LOCATION.NOT_APPLICABLE, label: 'Not Applicable' },
  { key: LOCATION.PRIMARY_CARE_PROVIDER, label: 'Primary Care Provider' },
  { key: LOCATION.PHARMACY, label: 'Pharmacy' },
  { key: LOCATION.PUBLIC_HEALTH, label: 'Public Health' },
  { key: LOCATION.TRAVEL_CLINIC, label: 'Travel Clinic' },
  { key: LOCATION.OCCUPATIONAL_HEALTH, label: 'Occupational Health' },
  { key: LOCATION.EMERGENCY_ROOM, label: 'Emergency Room' },
  { key: LOCATION.HOSPITAL, label: 'Hospital' },
  { key: LOCATION.SCHOOL, label: 'School' },
  { key: LOCATION.VICTORIAN_ORDER_OF_NURSES, label: 'Victorian Order of Nurses (VoN)' },
  { key: LOCATION.OUTSIDE_NS, label: 'Outside of Nova Scotia' },
  { key: LOCATION.UNKNOWN, label: 'Unknown' }
];

const vax_category_options: Array<{ key: VAX_CATEGORY; label: string }> = [
  { key: VAX_CATEGORY.NOT_APPLICABLE, label: 'Not Applicable' },
  { key: VAX_CATEGORY.CHILDHOOD, label: 'Childhood' },
  { key: VAX_CATEGORY.TRAVEL, label: 'Travel' },
  { key: VAX_CATEGORY.SCHOOL_PROGRAM, label: 'School Program' },
  { key: VAX_CATEGORY.COMPLETE_RECORD, label: 'Complete Record' },
  { key: VAX_CATEGORY.ADULT_VACCINE, label: 'Adult Vaccine' },
  { key: VAX_CATEGORY.ANNUAL, label: 'Annual' },
  { key: VAX_CATEGORY.OUTSIDE_NS, label: 'Received outside of NS' },
  { key: VAX_CATEGORY.UNKNOWN, label: 'Unknown' }
];

const fields: FormUXModel = [
  {
    groupName: 'Name',
    type: FormUXFieldType.grouped,
    wrap: true,
    fields: [
      {
        name: 'firstName',
        label: 'First Name',
        type: FormUXFieldType.text,
        editable: true,
        placeholder: 'eg. Jane',
        className: 'ant-input-grey',
        size: 'large',
        validationRules: [...RequiredValidationRule, ...ShortTextValidationRules]
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: FormUXFieldType.text,
        editable: true,
        placeholder: 'eg. Smith',
        className: 'ant-input-grey',
        size: 'large',
        validationRules: [...RequiredValidationRule, ...ShortTextValidationRules]
      }
    ]
  },
  {
    groupName: 'Contact',
    type: FormUXFieldType.grouped,
    wrap: true,
    fields: [
      {
        name: 'email',
        label: 'Email',
        type: FormUXFieldType.text,
        editable: true,
        placeholder: 'eg. jane@email.com',
        className: 'ant-input-grey',
        size: 'large',
        validationRules: [...RequiredValidationRule, ...EmailValidationRules]
      },
      {
        name: 'contactNumber',
        label: 'Contact Number',
        type: FormUXFieldType.text,
        editable: true,
        placeholder: 'eg. 123-567-7890',
        className: 'ant-input-grey',
        size: 'large',
        validationRules: [
          {
            validationRuleType: PhoneValidationRuleId
          }
        ]
      }
    ]
  },
  {
    groupName: 'Issue',
    type: FormUXFieldType.grouped,
    wrap: 'lg',
    fields: [
      {
        name: 'issue',
        label: 'Issue',
        type: FormUXFieldType.select,
        editable: true,
        selectableValues: issue_options,
        placeholder: '-select-',
        size: 'large',
        validationRules: [...RequiredValidationRule]
      },
      {
        name: 'deviceUsed',
        label: 'Device Used',
        type: FormUXFieldType.select,
        editable: true,
        selectableValues: device_options,
        placeholder: '-select-',
        size: 'large',
        validationRules: [...RequiredValidationRule]
      }
    ]
  },
  {
    groupName: 'Vaccine Details',
    type: FormUXFieldType.grouped,
    wrap: 'lg',
    fields: [
      {
        name: 'vaccineReceivedAt',
        label: 'Vaccine Received At',
        type: FormUXFieldType.select,
        editable: true,
        selectableValues: received_at_options,
        placeholder: '-select-',
        size: 'large',
        validationRules: [...RequiredValidationRule]
      },
      {
        name: 'vaccineCategory',
        label: 'Vaccine Category',
        type: FormUXFieldType.select,
        editable: true,
        selectableValues: vax_category_options,
        placeholder: '-select-',
        size: 'large',
        validationRules: []
      }
    ]
  },
  {
    name: 'additionalDetails',
    label: 'Additional Details',
    type: FormUXFieldType.textarea,
    maxLength: 500,
    autoSize: { minRows: 5 },
    editable: true,
    validationRules: []
  }
  // {
  //   name: 'attachment',
  //   label: 'Attachment',
  //   type: FormUXFieldType.files,
  //   maxFiles: 1,
  //   maxSize: 3145728,
  //   editable: true,
  //   accept: ['.png', 'image/jpeg'],
  //   validationRules: [],
  // },
];
