import React from 'react';
import Markdown from 'react-markdown';
import { FormUXModel } from './models/form-ux-model';
import { Form, FormItemProps, Input, InputNumber, Select, Switch, Checkbox } from 'formik-antd';
import { Col, DatePicker, Row, TimePicker, Button, Modal, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { FormUXFieldType } from './models/form-ux-fields/form-ux-field-type';
import { UFormUXFields } from './models/form-ux-fields/form-ux-fields';
import { IFormUXGroupedFields } from './models/form-ux-fields/form-ux-grouped-fields';
import { IFormUXCustomField } from './models/form-ux-fields/form-ux-custom-field';
import { IFormUXFlexFields } from './models/form-ux-fields/form-ux-flex-fields';
import { Field, FieldProps, FormikProps, useFormikContext } from 'formik';
import moment from 'moment';
// import {ReactDatePicker} from '../pickers/react-datepicker';
import { IFormUXSectionField } from './models/form-ux-fields/form-ux-section';
import { JsonField } from './fields/json-field';
import { RadioGroupField } from './fields/radio-group-field';
import { FileField } from './fields/files-field';
import { SectionTitleField } from './fields/section-title-field';
import { ThreeFieldDatepicker } from './fields/three-field-date-picker-field';
import { formItemValidate } from './validation';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { RequiredValidationRuleId } from './validation/validation-rules/required-validation';

const { TextArea } = Input;

/**
 * Props for the FormUX component
 *
 * @export
 * @interface FormUXProps
 */
export interface FormUXProps {
  /**
   * The model object used to inform the rendering of the Form
   *
   * @type {FormUXModel}
   * @memberof FormUXProps
   */
  formUXModel: FormUXModel;
  /**
   * Whether this form is for creating a new item or editing an existing one
   *
   * @type {boolean}
   * @memberof FormUXProps
   */
  createMode?: boolean;
  /**
   * Whether this form is created in a modal or not
   *
   * @type {boolean}
   * @memberof FormUXProps
   */
  modal?: boolean;
  /**
   * Prefix to add to all the field names.
   * Use this when the fields in this object are part of an array or an object
   * for example and you need to add the parent object/array name to the field
   * names.
   *
   * @type {string}
   * @memberof FormUXProps
   */
  namePrefix?: string;
  /**
   * Function called when the user should render the form item for a
   * field whose type is custom.
   *
   * @memberof FormUXProps
   */
  renderCustomField?: (
    field: IFormUXCustomField,
    formikProps: FormikProps<any>
  ) => React.ReactElement;
  /**
   * Disable form fields when set to true
   *
   * @memberof FormUXProps
   */
  isDisabled?: boolean;
  className?: string;
}

/* Use this component when rendering the form used to create or edit
an entity UX */
export const FormUX = (props: FormUXProps) => {
  const [mdPreview, setMdPreview] = React.useState<any>(undefined);
  /* A map of md field keys to their textfield dom elements. */
  const [mdFields, setMdFields] = React.useState<any>({});
  const namePrefix = props.namePrefix || '';
  const formikProps = useFormikContext();

  const fields = props.formUXModel.reduce((previousFields, field) => {
    const fields = [...previousFields];
    if (field.type === FormUXFieldType.section) {
      if (field.title) {
        fields.push({
          title: field.title,
          type: FormUXFieldType.sectionTitle,
          inCreateModal: field.inCreateModal,
          inEditMode: field.inEditMode
        });
      }
      fields.push(...field.fields.map((field) => field));
    } else {
      fields.push(field);
    }
    return fields;
  }, [] as FormUXModel);

  /* Depending on whether we are creating a new entity or editing
      an existing one we show different form items. Show only those which
      have the inCreateModal flag set in create mode. */
  const fieldsToRender = fields.filter((field) => {
    if (field.type === FormUXFieldType.section) return false;
    if (props.createMode) {
      return field.inCreateModal;
    } else if (field.inEditMode === false) {
      return false;
    } else return true;
  });

  return (
    <Form layout="vertical" requiredMark={false}>
      {fieldsToRender.map((fieldToRender, fieldToRenderIndex) => {
        /* The fields that need to be rendered in this row */
        const { formUXFields, spans } = getFieldsAndProperties(fieldToRender, props.modal);

        return (
          <Row key={fieldToRenderIndex} gutter={[16, 0]}>
            {formUXFields.map((formUXField, formUXFieldIndex) => {
              if (formUXField.type === FormUXFieldType.sectionTitle) {
                return (
                  <Col {...spans} key={formUXFieldIndex}>
                    <FormItemInput
                      field={formUXField}
                      namePrefix={namePrefix}
                      renderCustomField={props.renderCustomField}
                      isDisabled={props.isDisabled}
                      formikProps={formikProps}
                    />
                  </Col>
                );
              }

              if (formUXField.hidden) {
                if (typeof formUXField.hidden === 'function') {
                  const hidden = formUXField.hidden(formikProps.values);
                  if (hidden) return null;
                } else {
                  if (formUXField.hidden) return null;
                }
              }

              const required =
                formUXField.validationRules.find(
                  (rule) => rule.validationRuleType === RequiredValidationRuleId
                ) !== undefined;

              /* Create a callback ref for elements to add them to mdFields. */
              const ref = (e) => {
                /* Check the event exists and it hasnt already been added to mdFields. */
                if (e && !mdFields[formUXField.name])
                  setMdFields((prev) =>
                    Object.assign(prev, {
                      [formUXField.name]: e.resizableTextArea.textArea
                    })
                  );
              };

              const looksRequired =
                formUXField.looksRequired === false ? false : required || formUXField.looksRequired;

              /* The label for the form element. */
              const label = formUXField.hideDefaultLabel ? undefined : (
                <span>
                  {`${formUXField.label} `}
                  {looksRequired && requiredStar}
                  {formUXField.type === FormUXFieldType.markdown && (
                    <Tooltip title="Preview as Markdown">
                      <Button
                        icon={
                          <EyeOutlined
                            style={{ opacity: '.3' }}
                            onClick={() => setMdPreview(mdFields[formUXField.name])}
                          />
                        }
                        type="text"
                      />
                    </Tooltip>
                  )}
                </span>
              );

              return (
                <Col {...spans} key={formUXFieldIndex}>
                  <Form.Item
                    name={`${namePrefix}${formUXField.name}`}
                    label={formUXField.type === FormUXFieldType.checkbox ? undefined : label}
                    style={{
                      display: formUXField.type === FormUXFieldType.hidden ? 'none' : 'block',
                      paddingBottom:
                        formUXField.type === FormUXFieldType.threeFieldDate ? 10 : 'unset'
                    }}
                    tooltip={formUXField.tooltip}
                    required={required}
                    key={formUXField.name}
                    validate={(value) => formItemValidate(formUXField.validationRules, value)}
                    extra={
                      typeof formUXField.extra === 'string'
                        ? formUXField.extra
                        : formUXField.extra?.(formikProps)
                    }
                  >
                    {/* If this is a custom field then render the form
                input immediately since the user may not want to
                render it inside a Form.Item */}
                    {formUXField.type === FormUXFieldType.custom ? (
                      <FormItemInput
                        field={formUXField}
                        namePrefix={namePrefix}
                        renderCustomField={props.renderCustomField}
                        isDisabled={props.isDisabled || formUXField.disabled}
                        formikProps={formikProps}
                      />
                    ) : (
                      <FormItemInput
                        field={formUXField}
                        namePrefix={namePrefix}
                        renderCustomField={props.renderCustomField}
                        isDisabled={props.isDisabled || formUXField.disabled}
                        formikProps={formikProps}
                        ref={ref}
                        looksRequired={looksRequired}
                        className={props.className}
                      />
                    )}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        );
      })}
      <Modal
        visible={mdPreview}
        title="View Markdown"
        onCancel={() => setMdPreview(undefined)}
        maskClosable={false}
        footer={null}
      >
        {mdPreview?.innerHTML.trim() ? (
          <Markdown children={mdPreview?.innerHTML} />
        ) : (
          <p style={{ opacity: 0.3, textAlign: 'center' }}>no markdown content found</p>
        )}
      </Modal>
    </Form>
  );
};

function getFieldsAndProperties(fieldToRender, modal?: boolean) {
  let formUXFields: Exclude<
    UFormUXFields,
    IFormUXGroupedFields | IFormUXFlexFields | IFormUXSectionField
  >[] = [];
  let spans: { [key in Breakpoint]?: number } = {};
  const fieldType = fieldToRender.type;

  switch (fieldType) {
    case FormUXFieldType.grouped:
      formUXFields = fieldToRender.fields;
      if (fieldToRender.wrap) {
        const wrapAt = typeof fieldToRender.wrap === 'string' ? fieldToRender.wrap : 'md';
        spans = { xs: 24, [wrapAt]: 12 };
      } else {
        spans = { xs: 12 };
      }
      break;

    case FormUXFieldType.flex:
      formUXFields = fieldToRender.fields;
      spans.xs = modal
        ? 24
        : fieldToRender.customColSpan
        ? fieldToRender.customColSpan
        : 24 / fieldToRender.fields.length;
      break;

    default:
      formUXFields = [fieldToRender];
      spans.xs = 24;
      break;
  }

  return { formUXFields, spans };
}

interface IFormItemInputProps {
  field: Exclude<UFormUXFields, IFormUXGroupedFields | IFormUXFlexFields | IFormUXSectionField>;
  namePrefix: string;
  renderCustomField: FormUXProps['renderCustomField'];
  isDisabled: boolean | undefined;
  formikProps: FormikProps<any>;
  ref?: any;
  looksRequired?: boolean;
  className?: string;
}

/**
 * Based on the field's type property, decides which input element to render
 *
 * @param {FormUXField} field
 * @param {FormUXProps["renderCustomField"]} renderCustomField
 * @returns {React.ReactElement}
 */
const FormItemInput: React.FC<IFormItemInputProps> = ({
  field,
  namePrefix,
  renderCustomField,
  isDisabled,
  formikProps,
  ref,
  looksRequired,
  className
}) => {
  /* The field name that the current field should use since it has the
    name prefix in it. */
  const fieldName = `${namePrefix}${field['name']}`;

  switch (field.type) {
    // case FormUXFieldType.boolean:
    //   return (
    //     <Select name={fieldName} showSearch={field.searchable}>
    //       <Select.Option key={'true'} value={true}>
    //         {'True'}
    //       </Select.Option>
    //       <Select.Option key={'false'} value={false}>
    //         {'False'}
    //       </Select.Option>
    //       {/* {field.selectableValues.map((selectableValue) => {
    //     if (typeof selectableValue === 'string') {
    //       return (

    //       );
    //     } else {
    //       return (
    //         <Select.Option key={selectableValue.key} value={selectableValue.key}>
    //           {selectableValue.label}
    //         </Select.Option>
    //       );
    //     }
    //   })} */}
    //     </Select>
    //   );
    case FormUXFieldType.boolean:
      return <Switch name={fieldName} disabled={isDisabled || !field.editable} />;
    case FormUXFieldType.markdown: {
      return (
        <TextArea
          fast
          name={fieldName}
          showCount
          disabled={isDisabled || !field.editable}
          ref={ref}
          autoSize={field.autoSize ?? true}
          maxLength={field.maxLength}
        />
      );
    }
    case FormUXFieldType.textarea:
      return (
        <TextArea
          fast
          name={fieldName}
          showCount
          autoSize={field.autoSize ?? true}
          disabled={isDisabled || !field.editable}
          maxLength={field.maxLength}
          className={'ant-field-grey'}
        />
      );
    case FormUXFieldType.text:
      return (
        <Input
          fast={!field.notFastField}
          name={fieldName}
          placeholder={field.placeholder}
          disabled={isDisabled || !field.editable}
          className={field.className}
          size={field.size}
          maxLength={field.maxLength}
          suffix
        />
      );
    case FormUXFieldType.json:
      return (
        <JsonField
          field={Object.assign({}, field, {
            name: fieldName
          })}
        />
      );
    case FormUXFieldType.custom: {
      if (renderCustomField === undefined) {
        throw new Error(
          `Field ${field.name} has type set to custom but no renderCustomField prop provided`
        );
      }

      return renderCustomField(field, formikProps);
    }
    case FormUXFieldType.select: {
      return (
        <Select
          name={fieldName}
          showSearch={field.searchable}
          disabled={isDisabled || !field.editable}
          placeholder={field.placeholder}
          defaultValue={field.default}
          size={field.size}
          className={'ant-input-grey ant-select-selector'}
          filterOption={
            field.searchable
              ? (input, option: any) =>
                  option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              : undefined
          }
        >
          {field.selectableValues.map((selectableValue, i) => {
            if (typeof selectableValue === 'string') {
              return (
                <Select.Option key={i} value={selectableValue}>
                  {selectableValue}
                </Select.Option>
              );
            } else {
              return (
                <Select.Option key={i} value={selectableValue.key}>
                  {selectableValue.label}
                </Select.Option>
              );
            }
          })}
        </Select>
      );
    }
    case FormUXFieldType.number: {
      return <InputNumber name={fieldName} disabled={isDisabled || !field.editable} />;
    }
    // case FormUXFieldType.date: {
    //   const value = formikProps.values[fieldName];
    //   return (
    //     <ReactDatePicker
    //       placeholder="YYYY/MM/DD"
    //       {...field}
    //       name={fieldName}
    //       popperPlacement="bottom-start"
    //       dateFormat={field.format || 'yyyy/MM/dd'}
    //       value={value && moment(value, 'YYYY-MM-DD').toDate()}
    //       onChange={(e) =>
    //         formikProps.setFieldValue(fieldName, e && moment(e).format('YYYY-MM-DD'))
    //       }
    //     />
    //   );
    // }
    case FormUXFieldType.MultipleSelect: {
      return (
        <Select
          name={fieldName}
          mode="multiple"
          filterOption={(input, option) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {field.selectableValues.map((value, i) => {
            if (typeof value === 'string') return null;

            return (
              <Select.Option key={i} value={value.key}>
                {value.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    }
    case FormUXFieldType.password: {
      return (
        <Input.Password
          fast={!field.notFastField}
          name={fieldName}
          disabled={isDisabled || !field.editable}
          suffix
        />
      );
    }
    case FormUXFieldType.checkbox: {
      return (
        <div className={className ?? ''}>
          <Checkbox
            name={fieldName}
            disabled={isDisabled || !field.editable}
            onChange={field.onChange}
            style={{ width: '100%', ...field.style }}
          >
            {`${field.label} `}
            {looksRequired && requiredStar} {field.tooltip}
          </Checkbox>
        </div>
      );
    }
    case FormUXFieldType.files: {
      return (
        <FileField
          accept={field.accept}
          maxFiles={field.maxFiles}
          maxSize={field.maxSize}
          dropText={field.dropText}
          files={formikProps.values[field.name]}
          setFiles={(files) => {
            formikProps.setFieldValue(field.name, files);
          }}
        />
      );
    }
    case FormUXFieldType.radioGroup: {
      return (
        <RadioGroupField
          field={field}
          options={field.options}
          onChange={field.onChange}
          button={field.button}
          vertical={field.vertical}
          className={className ?? undefined}
        />
      );
    }
    case FormUXFieldType.hidden:
      return null;
    case FormUXFieldType.sectionTitle:
      return <SectionTitleField title={field.title} />;
    case FormUXFieldType.threeFieldDate:
      return (
        <ThreeFieldDatepicker
          name={field.name}
          className={field.className}
          validationRules={field.validationRules}
        />
      );
  }
  return null;
};

/** Component to use for time fields. Will eventually be used in the main
 * FormUX component.
 * Replaces the one from formik-antd since that uses string values rather
 * than moment object which was causing timezone issues.
 */
export const FormUXTimeField = (props: {
  name: string;
  label: string;
  validate: FormItemProps['validate'];
  timeFormat?: string;
}) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
          <TimePicker
            value={value}
            allowClear={false}
            format={props.timeFormat}
            onChange={(time) => {
              /* The time argument passed by antd in the onChange could be
              in the local timezone rather than UTC, for example when the field
              is cleared and a new value is set. The following code ensures
              that the timezone of the moment object is always in UTC. */
              let utcTime = time;
              if (time) {
                utcTime = moment.utc({
                  hour: time.hours(),
                  minutes: time.minutes(),
                  seconds: time.seconds()
                });
              }

              setFieldValue(props.name, utcTime);
              setFieldTouched(props.name, true, false);
            }}
          />
        </Form.Item>
      )}
    </Field>
  );
};

/** Component to use for date fields. Will eventually be used in the main
 * FormUX component.
 * Replaces the one from formik-antd since that uses string values rather
 * than moment object which was causing timezone issues.
 */
export const FormUXDateField = (props: {
  name: string;
  label: string;
  validate: FormItemProps['validate'];
}) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
          <DatePicker
            value={value}
            /** if you want to set this to true, make sure that the picker still works after date is cleared */
            allowClear={false}
            onChange={(time) => {
              /**Set UTC offset to zero to prevent inconsistencies between datepicker selection and stored value in database */
              const formattedDate = moment(time).utcOffset(0, true);
              setFieldValue(props.name, formattedDate);
              setFieldTouched(props.name, true, false);
            }}
          />
        </Form.Item>
      )}
    </Field>
  );
};

const requiredStar = (
  <span
    style={{
      color: 'red',
      fontSize: 14,
      fontWeight: 'bolder'
    }}
  >
    *
  </span>
);
