import React from 'react';
import { Button, ButtonProps, Modal, message } from 'antd';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import ReactMarkdown from 'react-markdown';
import { parseConsentsBundle } from 'src/data/consent';
import { ResponsiveFooter } from 'src/utils/responsive-footer';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';

const TOU_URL = 'https://support.novascotia.ca/services/vaxrecordns-privacy-terms';

export const PrivacyPolicyModal: React.FC = () => {
  const { isLoggedIn, lambdasClient, client, logout } = useSmartApp();
  // The time at which the token expires, as number of seconds since the Unix epoch
  const [isOpen, setIsOpen] = React.useState(false);
  const [accepting, setAccepting] = React.useState(false);
  const [declining, setDeclining] = React.useState(false);

  React.useEffect(() => {
    if (!isLoggedIn) return;
    // Find if there is a privacy Consent
    if (lambdasClient && client.user.fhirUser) {
      client
        .request(
          `Consent?patient=${encodeURIComponent(
            client.user.fhirUser
          )}&organization=${encodeURIComponent('Organization/VaxRecordNS')}&status=active`
        )
        .then((res) => {
          const consents = parseConsentsBundle(res);
          // Get the latest consent
          if (!consents.length) return setIsOpen(true);
          const consent = consents.sort((a, b) => {
            if (!a.dateTime) return +1;
            if (!b.dateTime) return -1;
            return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
          })[consents.length - 1];
          // If the versions don't match server policy in iFrame
          if (consent.sourceReference?.reference !== TOU_URL) setIsOpen(true);
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const onOk = async () => {
    setAccepting(true);
    await lambdasClient
      ?.post('/AcceptTou', { source: TOU_URL })
      .catch(() => message.error('An error has occurred'));
    setAccepting(false);
    setIsOpen(false);
  };

  const onCancel = async () => {
    setDeclining(true);
    await logout();
  };

  const AcceptButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={onOk}
      loading={accepting}
      disabled={accepting || declining}
      size="large"
      type="primary"
      {...p}
    >
      Accept
    </Button>
  );

  const DeclineButton: React.FC<ButtonProps> = (p) => (
    <Button
      onClick={onCancel}
      loading={declining}
      disabled={accepting || declining}
      type="primary"
      ghost
      size="large"
      {...p}
    >
      Cancel
    </Button>
  );

  const modalTopSpace = useValueFromBreakpoint([
    ['xs', 50],
    ['md', 100]
  ]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      keyboard={false}
      closable={false}
      maskClosable={false}
      width={750}
      style={{
        top: modalTopSpace
      }}
    >
      <div style={{ padding: 8 }}>
        <MarkdownRenderer>{privacyText}</MarkdownRenderer>
        <div style={{ marginTop: 40 }}>
          <ResponsiveFooter OkButton={AcceptButton} CancelButton={DeclineButton} />
        </div>
      </div>
    </Modal>
  );
};

// function to force links in Markdown to open in a new tab
function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const MarkdownRenderer = ({ children }) => {
  return <ReactMarkdown components={{ a: LinkRenderer }}>{children}</ReactMarkdown>;
};

const privacyText = `# VaxRecordNS Privacy Policy and Terms of Use

  ## Privacy Policy

  The Nova Scotia VaxRecordNS service (“VaxRecordNS”) is a Public Health tool that gives people the option to access their Nova Scotia Department of Health and Wellness vaccination records. The tool can be accessed through a website or mobile app.

  The Nova Scotia Department of Health and Wellness is committed to the protection of personal health information. Personal health information means information that could be used to identify an individual, such as a name and date of birth and information relating to the medical care or health of an individual, including a health card number.

  The collection, use, disclosure, and retention of personal health information in VaxRecordNS is governed and protected by provincial privacy legislation: specifically, the Personal Health Information Act (PHIA) and the Personal Information International Disclosure Protection Act (PIIDPA).

  ### Using VaxRecordNS to gain access to vaccination records

  To access your Nova Scotia vaccination records using VaxRecordNS, you will require a My NS Account.

  When you open or download VaxRecordNS, you will be asked to create or log into your account. Once your account is set up and you are logged in, you will return to VaxRecordNS.

  VaxRecordNS will use the following personal health information to verify your account and provide you with access to your vaccination records:

  - Health Card (MSI) Number;
  - First, middle (if applicable) and last name;
  - Date of Birth; and
  - Email address and/or phone number.

  You will be asked to provide consent to your personal health information being collected, used and/or disclosed for these purposes.

  After reading this Privacy Statement, if you do not wish to provide the personal information required to create your account or use VaxRecordNS or if you do not agree with the Terms of Use, please do not continue to use VaxRecordNS. You can still access your vaccination records by using other (non-electronic) channels, such as requesting them in-person or by mail.

  ### How VaxRecordNS protects your personal health information

  When you use VaxRecordNS, the personal health information is sent through a secure encrypted (coded to prevent access by others) connection to secure servers where it is also encrypted and stored.

  VaxRecordNS applies strong security measures to protect your information by stopping others from accessing, altering, disclosing or destroying it. These security measures include administrative, technical, organizational, and physical safeguards to protect your data against loss, theft, or misuse.

  ### Downloading your record to your mobile device

  In order to download a Proof of Vaccination\*, VaxRecordNS requires access to your mobile device's default storage/download folder. The purpose of this access is to check if a previous Proof of Vaccination record of the same name already exists. If it does exist, VaxRecordNS will replace it with the latest version. You will be asked to consent to VaxRecordNS accessing this folder prior to downloading.

  VaxRecordNS will not access, collect or store any other data on your mobile device.

  \*This is an optional feature and if a user does not wish to download their record, no access rights will be necessary.

  ### Collection of data about your use of the website or mobile app

  VaxRecordNS automatically collects data about the devices and connections you use to connect to the website and mobile app. Here are some examples of the types of data we collect:

  - Your device’s operating system, hardware and software versions, settings, and identifiers
  - Your browser type, language, time zone, and the IP address you use to connect

  As with most websites, VaxRecordNS automatically collects data about how you use the website and mobile app.

  VaxRecordNS collects this data to:

  - provide you with user and technical support,
  - manage the platform and improve the services we provide,
  - understand how users are connecting to VaxRecordNS and
  - detect, investigate or prevent violation of our Terms of Use.

  VaxRecordNS protects this data in the same secure manner as it protects your personal health information.

  ### Use of Cookies

  Like many other websites, VaxRecordNS uses “cookies” to collect and store data on your computer or device to help improve your experience using VaxRecordNS. Most web browsers (including Firefox, Google Chrome, Internet Explorer, Edge, Safari, and Opera) are set up to accept cookies, unless you choose to disable this feature.

  **If you disable cookies on your web browser, you will not be able to access VaxRecordNS.**

  ### How you can protect your information

  If you use VaxRecordNS you will be able to download your vaccination information. However, your profile data will not be stored locally on your mobile device.

  VaxRecordNS takes protection of your information seriously, but there are also steps you can take to keep your information safe. You should make sure you keep your device in a safe place, so it won’t be lost or stolen. You should use a secure password or other method to lock your device. You should never share your password with anyone.

  ### How you can delete your account

  You may choose to delete the VaxRecordNS app from your device at any time. By deleting the app, this will remove all associated data downloaded to your device. Any records that you have downloaded and saved locally to your device(s) using VaxRecordNS will not be deleted by deleting the app.

  To access VaxRecordNS, you used your My NS Account credentials. To request that your My NS Account credentials be deleted, please follow this link:
  [https://mynsid.novascotia.ca/auth/eng/help](https://mynsid.novascotia.ca/auth/eng/help)

  ### Who to contact if you have questions:

  #### About your Vaccination Records

  For additional information please see the Department of Health and Wellness Privacy Statement [https://novascotia.ca/dhw/phia/privacy/DHW-Privacy-Statement.pdf](https://novascotia.ca/dhw/phia/privacy/DHW-Privacy-Statement.pdf) or visit [https://novascotia.ca/dhw/phia/your-privacy.asp](https://novascotia.ca/dhw/phia/your-privacy.asp)

  If you have questions about your personal health records contact the Department of Health and Wellness, please contact: 
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[phia@gov.ns.ca](mailto:phia@gov.ns.ca)

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy and Access Office

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nova Scotia Department of Health and Wellness

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1894 Barrington Street

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P.O. Box 488

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Halifax, NS B3J 2R8

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toll Free: [1-855-640-4765](tel:1-855-640-4765)

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[902 424-5419](tel:902-424-5419)

  #### About your My NS Account:

  If you have questions about your account or credentials, please follow this link: [https://mynsid.novascotia.ca/auth/eng/contact](https://mynsid.novascotia.ca/auth/eng/contact)

  ## VaxRecordNS Terms of Use (TOU)

  VaxRecordNS grants individuals with an account access to safely view their vaccination records. We refer to both the website and mobile app together as “VaxRecordNS”.

  Use of the VaxRecordNS service is governed by these Terms of Use. We may amend or modify these Terms of Use at any time and, notice will be provided the next time you access the VaxRecordNS service. The current version of these Terms of Use will also be available each time you access the service.

  By using VaxRecordNS, you agree to be bound by these Terms of Use and are responsible for reviewing these Terms of Use and the VaxRecordNS Privacy Policy. You also agree that you will comply with all applicable laws and regulations when using VaxRecordNS and are prohibited from attempting to violate the security of the service. You should not use VaxRecordNS if you do not agree to these Terms of Use and the Privacy Policy, understand what they mean, or do not consent for your data to be used.

  ### No Substitute for Health Care Advice

  Information included in VaxRecordNS is for your general knowledge only. It is not meant to replace what healthcare professionals advise. You should not rely on it for diagnosing or treating an illness. You should talk to your own healthcare provider. There are no representations or warranties that the information available through VaxRecordNS is or will be accurate, error free or complete.

  ### Availability of VaxRecordNS

  We make no representations or warranties that VaxRecordNS will operate uninterrupted and error-free, that communications to or from VaxRecordNS will be secure and/or not intercepted, or will be completely free of errors, viruses, or other harmful or invasive files. We are not responsible for your use of or inability to use VaxRecordNS, any damage sustained by your computer or device or any loss of data that may result when downloading material, or any error, delay, interruption, or omission in information on the service.

  From time to time, maintenance may be performed on VaxRecordNS. During this time, access to VaxRecordNS, the information it contains, and its features may be limited.

  ### Security

  Any information sent or received over the Internet is generally not secure and may be intercepted, lost or altered. We cannot guarantee the security of any communication to or from VaxRecordNS. VaxRecordNS does not assume any responsibility or risk for your use of the Internet.

  While we take reasonable precautions in line with industry standards to ensure the security of the information you access through VaxRecordNS, the service is ultimately provided for your convenience and to use at your own risk. We cannot guarantee its security or the protection of your data from loss, theft or disclosure when sent or received over the internet.

  ### Product Use

  You may use VaxRecordNS for your own non-commercial, personal use and you are granted the right to download, store and print single copies of the items or material provided that you maintain all copyright and other notices contained in such content. The content of VaxRecordNS software and associated website including text, graphics, and any other content or material is protected by copyright and trademark laws. You may only use the downloaded software, or the associated website, to access VaxRecordNS. You may not use any technologies that enable you to modify VaxRecordNS.

  You may not:

   (a) modify, adapt, or create derivative works of VaxRecordNS or any of its parts, features, functions or user interfaces

   (b) reverse engineer, decompile, translate, copy, mirror, frame, scrape, reproduce, distribute, republish, download, or attempt to reconstruct, identify or discover any source code, underlying ideas, underlying user interface techniques or algorithms of VaxRecordNS or any of its parts, features, functions or user interfaces

  (c\) attempt to gain unauthorized access to VaxRecordNS or its related services, systems or networks

  (d) permit direct or indirect access to or use of VaxRecordNS in any way that circumvents any usage limit

  (e) access or use VaxRecordNS in a commercial transaction, or to build or develop similar services that compete directly or indirectly with VaxRecordNS

  (f) interfere with or disrupt the integrity or performance of VaxRecordNS or any third-party data it contains

  (g) use VaxRecordNS to store or transmit viruses, worms, time bombs, trojan horses or other malicious codes, files, scripts, agents, or programs

  Your rights related to VaxRecordNS have limits and cannot be transferred or assigned to others. Your access to VaxRecordNS can be suspended or terminated if you don’t follow the Terms of Use.

  ### Acceptable Use of VaxRecordNS and Termination of Use

  If you do not use VaxRecordNS as intended, we can end your use of this service immediately and without notice to you. Inappropriate uses include, but are not limited to:  purposely inputting false information, or accessing, using, or disclosing someone else’s information without consent, or illegal activity, including non-compliance. We will not be liable to any party for such termination.

  ### Laws that Cover VaxRecordNS

  The laws of Nova Scotia and Canada (where applicable) apply to the Terms of Use and any disputes that come from your use of VaxRecordNS, without regard to conflict of law principles. By accessing VaxRecordNS you submit to the non-exclusive jurisdiction of the Courts of Halifax, Nova Scotia to hear any legal cases arising out of your use of VaxRecordNS.

  Personal health information provided to or accessed through VaxRecordNS will be protected in accordance with applicable laws, including the Nova Scotia Personal Health Information Act, and may be collected, used or disclosed in accordance with such laws.

  By clicking the button below, you acknowledge that you have read and accept the VaxRecordNS Privacy Policy and Terms of Use.
  
  [Read Full Privacy Policy and Terms of Use](https://support.novascotia.ca/services/vaxrecordns-privacy-terms)`;
