import React from 'react';
import { Input } from 'formik-antd';
import { IFormUXJsonField } from '../models/form-ux-fields/form-ux-json-field';

interface JsonFieldProps {
  field: IFormUXJsonField;
}

export const JsonField = (props: JsonFieldProps) => {
  const { field } = props;

  return <Input.TextArea fast name={field.name} autoSize disabled={!props.field.editable} />;
};
