/**
 *
 * @export
 * @enum {string}
 */
export enum FormUXFieldType {
  text = 'text',
  textarea = 'textarea',
  markdown = 'markdown',
  json = 'json',
  custom = 'custom',
  grouped = 'grouped',
  MultipleItemPicker = 'multiple-item-picker',
  select = 'select',
  SingleItemPicker = 'single-item-picker',
  number = 'number',
  flex = 'flex',
  boolean = 'boolean',
  date = 'date',
  MultipleSelect = 'multi-select',
  hidden = 'hidden',
  password = 'password',
  checkbox = 'checkbox',
  files = 'files',
  radioGroup = 'radioGroup',
  section = 'section',
  sectionTitle = 'sectionTitle',
  threeFieldDate = 'threeFieldDate'
}
