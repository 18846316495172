import React from 'react';
import { Spin, Alert, Button, Card, Row, Space, Typography, Modal } from 'antd';
import { colors } from '@canimmunize/tools';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'src/theme';

import vaxRecordNSLogo from 'src/assets/myvaxnslogo.png';
import familyImg from 'src/assets/1-family.jpg';
import poweredByLogo from 'src/assets/powered_by_logo.png';
import backgroundImage from 'src/assets/backgroundImage.png';
import { useNetworkStatus } from 'src/utils/network-status';

export const LandingPage = () => {
  const { authorize, verify, loading } = useSmartApp();
  const isOnline = useNetworkStatus();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (searchParams.has('timed_out')) {
      Modal.info({
        maskClosable: true,
        title: 'Logged out',
        content: 'To protect your data, you were logged out.'
      });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('timed_out');
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const login = () => {
    authorize();
  };

  const verificationError = ['expired', 'cancelled'].includes(verify);

  const showLogin = !loading && isOnline;

  return (
    <div className="auth-client-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPositionX: '100%',
          backgroundPositionY: '100%',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Space
          direction="vertical"
          style={{
            width: '100%',
            alignItems: 'center',
            margin: 30
          }}
        >
          <Card
            style={{ maxWidth: 550 }}
            cover={
              <div
                style={{
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 550,
                  height: 250,
                  backgroundImage: `url(${familyImg})`,
                  borderRadius: '10px 10px 0 0'
                }}
              />
            }
          >
            {verificationError && (
              <Alert
                type="error"
                message="Your verification code has expired. Please return to VaxRecordNS to request a new code."
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            <Row justify="center" style={{ marginBottom: 30 }}>
              <img style={{ width: '60%' }} src={vaxRecordNSLogo} alt="Vax Record NS" />
            </Row>
            {!showLogin && (
              <div style={{ marginBottom: 30 }}>
                <Row justify="center">
                  <Spin size="large" />
                </Row>
                <Row justify="center">
                  <p style={{ color: colors.darkBlue, fontSize: 30, margin: 0 }}>Loading...</p>
                </Row>
              </div>
            )}
            {showLogin && (
              <div>
                <Row justify="center" style={{ marginBottom: 30 }}>
                  <Typography.Title
                    level={3}
                    style={{
                      textAlign: 'center',
                      marginBottom: 15,
                      fontWeight: 'normal',
                      color: theme.colors.primary[400]
                    }}
                  >
                    Access your vaccination records from anywhere
                  </Typography.Title>
                  <Typography.Text style={{ textAlign: 'center', marginBottom: 15 }}>
                    In order to use VaxRecordNS, you will need a Nova Scotia Health card to create
                    an account. If you do not want to set up an account, contact your local public
                    health office to request your vaccination record.
                  </Typography.Text>
                  <Typography.Text style={{ textAlign: 'center' }} strong>
                    You must be 16 or older to use this service.
                  </Typography.Text>
                </Row>
                <Row justify="center">
                  <Button
                    type="primary"
                    style={{ borderRadius: 3, paddingLeft: 45, paddingRight: 45, marginBottom: 30 }}
                    onClick={login}
                  >
                    Launch VaxRecordNS
                  </Button>
                </Row>
                <Row justify="center">
                  <Typography.Text style={{ textAlign: 'center', marginBottom: 15 }}>
                    <text>Want to learn more about VaxRecordNS? </text>
                    <a
                      href={process.env.REACT_APP_FAQ_URL}
                      target="_blank"
                      style={{ color: '#12235A', fontWeight: 'bold', textDecoration: 'underline' }}
                      rel="noreferrer"
                    >
                      Find Out More.
                    </a>
                  </Typography.Text>

                </Row>
              </div>
            )}
            <Row justify="center">
              <div style={{ width: '40%' }}>
                <img
                  style={{ width: '100%' }}
                  alt="Nova Scotia. Powered by CAN Immunize"
                  src={poweredByLogo}
                />
              </div>
            </Row>
          </Card>
        </Space>
      </div>
    </div>
  );
};
