import { colors } from '@canimmunize/tools';
import 'antd/dist/antd.less';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fhirclient } from 'fhirclient/dist/lib/types';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useWindowDimensions } from 'src/utils/window-dimensions';
import { FeatureFlagsProvider } from './contexts/flags-provider';
import { SmartAppProvider } from './contexts/smart-app-provider';
import { AppRoutes } from './routes';
import { useValueFromBreakpoint } from './utils/breakpoints';
import { getNonceFromUrl } from './utils/nonce';
import { NetworkStatusWrapper } from './wrappers/network-status-wrapper';

export const App: React.FC = () => (
  // @ts-ignore
  <BrowserRouter basename={window.hummingbirdAppBaseUrl}>
    <ErrorBoundary>
      <NetworkStatusWrapper>
        <FeatureFlagsProvider>
          <SmartAppProvider config={config}>
            <AppRoutes />
          </SmartAppProvider>
        </FeatureFlagsProvider>
        <Overlay />
      </NetworkStatusWrapper>
    </ErrorBoundary>
  </BrowserRouter>
);

const Overlay: React.FC = () => {
  const { width } = useWindowDimensions();
  const versionNumber = process.env.REACT_APP_VERSION_NUMBER;
  const buildNumber = process.env.REACT_APP_BUILD_NUM;
  const stage = process.env.REACT_APP_STAGE?.toUpperCase();
  const approxVersionTextWidth = 80;

  const footerBottom = useValueFromBreakpoint([
    ['xs', width / 2 - approxVersionTextWidth],
    ['md', 10]
  ]);

  return (
    <div style={{ position: 'relative' }}>
      {/* align version number in center on smaller screens*/}
      <div
        style={{
          position: 'absolute',
          bottom: 5,
          right: footerBottom,
          fontSize: 11,
          color: colors.noteGray
        }}
      >
        {`${stage !== 'PROD' ? `${stage} ` : ''}Version: ${versionNumber} (${buildNumber})`}
      </div>
    </div>
  );
};

const config: fhirclient.AuthorizeParams = {
  iss: process.env.REACT_APP_ISSUER!,
  client_id: 'TestCanImm',
  clientSecret: 'fakeSecret',
  scope: 'openid fhirUser patient/*.read user/*.rs online_access',
  redirectUri: window.location.origin,
  loginHintToken: getNonceFromUrl()
};
