import { Radio } from 'formik-antd';
import { RadioChangeEvent, Space } from 'antd';
import { IFormUXRadioGroupField } from '../models/form-ux-fields/form-ux-radio-group-field';

export type RadioGroupFieldOptions = {
  value: any;
  label: string;
};

interface RadioGroupFieldProps {
  field: IFormUXRadioGroupField;
  options: RadioGroupFieldOptions[];
  vertical?: boolean;
  button?: boolean;
  onChange?: (e: RadioChangeEvent) => any;
  className?: string;
}

export const RadioGroupField = (props: RadioGroupFieldProps) => {
  const { field, options, onChange, vertical, button } = props;

  const RadioType = button ? Radio.Button : Radio;

  const radioOptions = options.map((option, i) => {
    return (
      <RadioType name={field.name} value={option.value} onChange={onChange} key={i}>
        {option.label}
      </RadioType>
    );
  });

  return (
    <div className={props?.className ?? ''}>
      <Radio.Group fast name={field.name} disabled={!props.field.editable}>
        {vertical ? <Space direction="vertical">{radioOptions}</Space> : radioOptions}
      </Radio.Group>
    </div>
  );
};
