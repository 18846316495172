import moment from 'moment';
import { AbstractValidationRule } from './abstract-validation-rule';

export const YearValidationRuleId = 'year';

export interface YearValidationRule extends AbstractValidationRule {
  validationRuleType: typeof YearValidationRuleId;
}

export function validateYearRule(value: any): boolean | string {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.length === 4 && value.match(/[0-9]+/)) {
    const validYear = Number(value) > 1899 && Number(value) <= moment().year();
    if (validYear) return true;
  }

  return 'Please enter a valid 4-digit year.';
}
