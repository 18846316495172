import { colors } from '@canimmunize/tools';
import { Alert, Button, ButtonProps, Col, Row } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import {
  FormUX,
  FormUXFieldType,
  RequiredValidationRule,
  ShortTextValidationRules
} from 'src/form-ux';
import { FormUXModel } from 'src/form-ux/models/form-ux-model';
import { HcnValidationRuleId } from 'src/form-ux/validation/validation-rules/hcn-validation';
import { ThreeFieldDateValidationRuleId } from 'src/form-ux/validation/validation-rules/three-field-date-validation';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { AddDependentModalStep, ERROR_CODES, error_codes_message_map, StepContentLayout } from '.';
import { ResponsiveFooter } from 'src/utils/responsive-footer';

const TryAgainError: React.VFC<{
  onFinish: () => any;
  time: number;
}> = (props) => {
  return (
    <div style={{ margin: 0 }}>
      Too Many Attempts - Please try again in{' '}
      <Countdown
        value={props.time}
        key="resend-code-countdown"
        format="mm:ss"
        onFinish={props.onFinish}
        className="countdown"
      />{' '}
      or Contact MSI Registration for help with your health card information at 1-800-563-8880.
    </div>
  );
};

export const PatientMatchStep: AddDependentModalStep = (props) => {
  const { lambdasClient } = useSmartApp();
  const { retryAtTime, updateConnectDependentRetryCooldown, error, setError } = props;

  React.useEffect(() => {
    props.setTitle('Dependent Information');
  }, [props]);

  // Used to scroll the top error message into view
  const fieldRef = React.useRef<HTMLDivElement>(null);

  const hasError = error || (retryAtTime && retryAtTime > Date.now());

  React.useEffect(() => {
    if (hasError && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [hasError]);

  const onSubmit = async (
    values: { [key: string]: any },
    actions: FormikHelpers<{
      [key: string]: any;
    }>
  ) => {
    actions.setSubmitting(true);

    if (error) setError(undefined);

    const day = values.dateOfBirth.day.padStart(2, '0');

    return await lambdasClient
      ?.post('/Dependent', {
        ...values,
        dateOfBirth: `${values.dateOfBirth.year}/${values.dateOfBirth.month}/${day}`,
        consent: props.state?.consent,
        relation: props.state?.relation
      })
      .then((response) => {
        props.setState({ ...props.state, ...response.data });
        props.next();
      })
      .catch((error: AxiosError<{ error?: ERROR_CODES }>) => {
        const code = error.response?.data.error;
        const errorCode = code ? ERROR_CODES[code] ?? ERROR_CODES.DEFAULT : ERROR_CODES.DEFAULT;

        // Use a custom error with a countdown instead of the default message
        if (ERROR_CODES.TOO_MANY_ATTEMPTS === errorCode || error.response?.status === 429) {
          updateConnectDependentRetryCooldown();
        } else {
          setError(errorCode);
        }

        actions.setSubmitting(false);
      });
  };

  const savedValues = props.savedValues ?? {};

  const [initialValues, initialTouched] = Object.keys(savedValues).reduce(
    (values, fieldName) => {
      if (fieldName === 'consent' || !savedValues[fieldName]) return values;

      const [fields, touched] = values;

      fields[fieldName] = savedValues[fieldName];
      touched[fieldName] = true;

      return [fields, touched];
    },
    [{}, {}]
  );

  const hasNoAuthFactors = error === ERROR_CODES.MFA_MISSING_AUTH_FACTORS;

  const errorMessage =
    retryAtTime && retryAtTime > Date.now() ? (
      <TryAgainError
        time={retryAtTime}
        onFinish={() => {
          updateConnectDependentRetryCooldown();
          props.setSavedValues(undefined);
        }}
      />
    ) : error ? (
      error_codes_message_map[error]
    ) : undefined;

  return (
    <div>
      {initialValues && (
        <Formik
          initialValues={initialValues}
          initialTouched={initialTouched}
          onSubmit={onSubmit}
          validateOnBlur={true}
        >
          {(formikProps) => {
            const BackButton: React.FC<ButtonProps> = (p) => (
              <Button
                onClick={() => {
                  props.setSavedValues(formikProps.values);
                  props.back();
                }}
                type="primary"
                ghost
                style={{ minWidth: 150 }}
                size="large"
                {...p}
              >
                Back
              </Button>
            );

            const ContinueButton: React.FC<ButtonProps> = (p) => (
              <Button
                type="primary"
                onClick={() => {
                  props.setSavedValues(formikProps.values);
                  formikProps.handleSubmit();
                }}
                style={{ minWidth: 150 }}
                loading={formikProps.isSubmitting}
                disabled={
                  !formikProps.dirty ||
                  !formikProps.isValid ||
                  formikProps.isSubmitting ||
                  !!retryAtTime
                }
                size="large"
                {...p}
              >
                Continue
              </Button>
            );

            if (hasNoAuthFactors)
              return (
                <StepContentLayout>
                  <Row>
                    <Col>
                      <h3
                        style={{
                          color: colors.darkBlue,
                          textAlign: 'center',
                          marginBottom: 15
                        }}
                      >
                        Dependent cannot be verified.
                      </h3>
                    </Col>
                    <Col>
                      No phone number has been found. Please contact MSI Registration at
                      1-800-563-8880
                    </Col>
                  </Row>
                </StepContentLayout>
              );

            return (
              <StepContentLayout
                footer={<ResponsiveFooter OkButton={ContinueButton} CancelButton={BackButton} />}
              >
                <div style={{ marginLeft: 12, marginRight: 12 }}>
                  <div ref={fieldRef}>
                    {errorMessage && (
                      <Alert
                        type="error"
                        message={errorMessage}
                        style={{ marginBottom: 30 }}
                        showIcon
                      />
                    )}
                  </div>
                  <div style={{ marginBottom: 15 }}>
                    <i style={{ margin: 0 }}>
                      You can only connect a dependent’s records if they are under the age of 16.
                    </i>
                  </div>
                  <Row gutter={24} style={{ marginRight: 0, marginLeft: 0 }}>
                    <FormUX formUXModel={fields} />
                  </Row>
                </div>
              </StepContentLayout>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

const fields: FormUXModel = [
  {
    name: 'hcn',
    label: 'Health Card Number',
    editable: true,
    tooltip: 'Must be 10 digits',
    type: FormUXFieldType.text,
    placeholder: 'eg. 1234 567 890',
    className: 'ant-input-grey',
    size: 'large',
    validationRules: [
      ...RequiredValidationRule,
      {
        validationRuleType: HcnValidationRuleId
      }
    ]
  },
  {
    name: 'firstName',
    label: 'First Name',
    type: FormUXFieldType.text,
    editable: true,
    className: 'ant-input-grey',
    size: 'large',
    validationRules: [...RequiredValidationRule, ...ShortTextValidationRules]
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: FormUXFieldType.text,
    editable: true,
    className: 'ant-input-grey',
    size: 'large',
    validationRules: [...RequiredValidationRule, ...ShortTextValidationRules]
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    editable: true,
    type: FormUXFieldType.threeFieldDate,
    className: 'ant-input-grey',
    validationRules: [
      ...RequiredValidationRule,
      {
        validationRuleType: ThreeFieldDateValidationRuleId,
        maxAge: 16
      }
    ],
    looksRequired: false
  },
  {
    name: 'consent',
    label:
      'I consent to the collection and use of the above information for the purpose of validating my dependent’s information and providing me with access to my dependent’s vaccination information (view and/or download)',
    type: FormUXFieldType.checkbox,
    editable: true,
    style: { fontWeight: 'bold' },
    validationRules: [...RequiredValidationRule],
    looksRequired: false
  }
];
