import { Button, ButtonProps } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { FormUX } from 'src/form-ux/form-ux';
import { RequiredValidationRuleId } from 'src/form-ux/validation/validation-rules/required-validation';
import { AddDependentModalStep, StepContentLayout } from '.';
import { FormUXFieldType } from 'src/form-ux';
import { FormUXModel } from 'src/form-ux/models/form-ux-model';
import { ResponsiveFooter } from 'src/utils/responsive-footer';

export const ConsentCaptureStep: AddDependentModalStep = (props) => {
  React.useEffect(() => {
    props.setTitle('Parent - Dependent Consent');
  }, [props]);

  const initialValues = [...consentFields, ...relationshipFields].reduce<{ [key: string]: any }>(
    (values, field) => {
      if (field.name) values[field.name] = undefined;
      return values;
    },
    {}
  );

  const onSubmit = async (values: { [key: string]: any }) => {
    // Pass the relation value to the next step
    props.setState({ ...props.state, relation: values.relation, consent: values.consent });
    props.updateConnectDependentRetryCooldown();
    props.next();
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validateOnChange={true}>
      {(formikProps) => {
        const AcceptButton: React.FC<ButtonProps> = (p) => (
          <Button
            onClick={() => formikProps.handleSubmit()}
            style={{ minWidth: 150 }}
            disabled={!formikProps.isValid || !formikProps.dirty}
            type="primary"
            size="large"
            {...p}
          >
            Accept
          </Button>
        );

        const CancelButton: React.FC<ButtonProps> = (p) => (
          <Button onClick={props.close} type="primary" ghost size="large" {...p}>
            Cancel
          </Button>
        );

        return (
          <StepContentLayout
            footer={<ResponsiveFooter OkButton={AcceptButton} CancelButton={CancelButton} />}
          >
            <div style={{ marginBottom: 15, padding: '0 15px' }}>
              <p>
                In order to access a dependent's record, the dependent must be under 16 years of age
                and you must be their parent or guardian. For more information, please go to{' '}
                <a href="https://www.nshealth.ca/vaxrecordns" target="_blank">
                  https://www.nshealth.ca/vaxrecordns
                </a>
                .
              </p>
              <FormUX formUXModel={relationshipFields} className="darkenedRadioButton" />
              <p>of the dependent being added through my VaxRecordNS access.</p>
              <p>
                I consent to the collection, use and disclosure of information associated with the
                dependent's health card for the purposes of (i) validating the dependent's
                information and (ii) providing me with access to the dependent's vaccination
                records.
              </p>
              <p>
                Public Health vaccination records are health records and are subject to the Personal
                Health Information Act (PHIA). PHIA is enforceable in the courts of Nova Scotia.
                Penalties may apply for offences under PHIA.
              </p>
              <FormUX formUXModel={consentFields} className="darkenedCheckbox" />
            </div>
          </StepContentLayout>
        );
      }}
    </Formik>
  );
};

export enum CONSENT_RELATION_TYPE {
  PARENT = 'parent',
  GUARDIAN = 'guardian'
}

const relationshipFields: FormUXModel = [
  {
    name: 'relation',
    type: FormUXFieldType.radioGroup,
    editable: true,
    options: [
      { value: CONSENT_RELATION_TYPE.PARENT, label: 'Parent' },
      { value: CONSENT_RELATION_TYPE.GUARDIAN, label: 'Guardian' }
    ],
    label: 'I confirm that I am the (choose one)',
    looksRequired: false,
    validationRules: [
      {
        validationRuleType: RequiredValidationRuleId
      }
    ]
  }
];
const consentFields: FormUXModel = [
  {
    name: 'consent',
    type: FormUXFieldType.checkbox,
    editable: true,
    label:
      'I understand that I will lose access to my dependent’s records when they turn 16 years old.',
    validationRules: [{ validationRuleType: RequiredValidationRuleId }],
    style: { fontWeight: 'bold' },
    looksRequired: false
  }
];
