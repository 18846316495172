import moment from 'moment';
import { AbstractValidationRule } from './abstract-validation-rule';

export const TimezoneValidationRuleId = 'timezone';

export interface TimezoneValidationRule extends AbstractValidationRule {
  validationRuleType: typeof TimezoneValidationRuleId;
}

/**
 * Validation to check if a value is a valid moment.tz timezone.
 * This will count an empty string/null as valid: use the
 * RequiredValidationRule if you have a mandatory timezone
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateTimezoneRule(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return true;
  } else if (typeof value !== 'string') {
    return false;
  }

  return !!moment.tz.zone(value);
}
