import { Button, ButtonProps } from 'antd';
import React from 'react';
import { DisconnectDependentModalStep, DisconnectStepContentLayout } from '..';
import { ResponsiveFooter } from 'src/utils/responsive-footer';

export const SuccessfulDeletionStep: DisconnectDependentModalStep = (props) => {
  React.useEffect(() => {
    props.setTitle('Success');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BackButton: React.FC<ButtonProps> = (p) => (
    <Button type="primary" onClick={props.next} size="large" {...p}>
      Back
    </Button>
  );

  return (
    <DisconnectStepContentLayout footer={<ResponsiveFooter OkButton={BackButton} />}>
      <p>You have disconnected your dependent's record</p>
    </DisconnectStepContentLayout>
  );
};
