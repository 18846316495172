import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button, Card, Col, Row, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { PatientCard } from '../../components/PatientCard';

export const AccountSettingsPage = () => {
  const { dependentPatients, loading, launchDisconnectDependentModal } = useA2iData();
  // const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);
  const navigate = useNavigate();
  let content: JSX.Element;
  if (loading) {
    content = <Skeleton active />;
  } else if (!dependentPatients?.length) {
    content = (
      <div style={{ marginTop: 30 }}>
        <p
          style={{
            margin: 10,
            textAlign: 'center',
            fontSize: '1.2em',
            fontWeight: 'bold'
          }}
        >
          No dependent records are connected to your account.
        </p>
      </div>
    );
  } else {
    content = (
      <Row gutter={[0, 15]}>
        {dependentPatients.map((patient) => (
          <Col span={24} key={patient.id}>
            <PatientCard
              patient={patient}
              disconnectOnPress={() => launchDisconnectDependentModal(patient)}
              onPress={() => (patient.isDeceased ? undefined : navigate(`/${patient.id}`))}
              noBorderLeft
              isDependent={true}
            />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <div className="patient-view">
      <div style={{ background: 'white' }}>
        <div className="container">
          <Row
            justify="space-between"
            gutter={[25, 10]}
            align="middle"
            style={{ padding: '15px 0' }}
          >
            <Col>
              <h1>Account Settings</h1>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container" style={{ paddingTop: 20 }}>
        <Card style={{ width: '100%' }}>
          <Row
            style={{ marginBottom: 20 }}
            justify="space-between"
            align="middle"
            gutter={[10, 15]}
          >
            <Col sm={24} md={14} lg={14}>
              <h3 style={{ fontSize: 24, marginBottom: 18, fontWeight: 'bold' }}>My Account</h3>
              <h4 style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 18 }}>
                To change your account information, please proceed to My NS Account.
              </h4>
            </Col>
            <Col>
              <Button block size="large" type="primary" disabled={loading}>
                <a
                  href={`${process.env.REACT_APP_NSID_BASE_URL}/auth/eng/l/authenticate`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to My NS Account
                </a>
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
      <div className="container" style={{ paddingTop: 20 }}>
        <Card style={{ width: '100%' }}>
          <Row style={{ marginBottom: 5 }} justify="space-between" align="middle" gutter={[15, 15]}>
            <Col span={24}>
              <h3 style={{ fontSize: 24, marginBottom: 18, fontWeight: 'bold' }}>My Dependents</h3>
              {content}
            </Col>
          </Row>
          {dependentPatients.length > 0 && (
            <div style={{ display: 'inline-flex', marginBottom: 18, marginTop: 18 }}>
              <ExclamationCircleTwoTone twoToneColor="orange" style={{ paddingTop: 4 }} />
              <h4 style={{ fontSize: 16, fontWeight: 'normal', paddingLeft: 10 }}>
                Disconnecting the dependent's account will remove the connection from your account.
                You may reconnect your dependent if they are less than 16 years old. Disconnecting
                your dependent will not affect their public health record.
              </h4>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
