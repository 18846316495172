import { AbstractValidationRule } from './abstract-validation-rule';

export const MinValidationRuleId = 'min';

/**
 *
 *
 * @export
 * @interface MinValidationRule
 * @extends {AbstractValidationRule}
 */
export interface MinValidationRule extends AbstractValidationRule {
  validationRuleType: typeof MinValidationRuleId;
  /**
   * The minimum value
   *
   * @type {number}
   * @memberof MinValidationRule
   */
  min: number;
  /**
   * Whether the validation should include the minimum value.
   * If true then it will check whether the value is greater than or equal to
   * the min value
   * If false it will only check if its greater
   *
   * @type {boolean}
   * @memberof MinValidationRule
   */
  inclusive: boolean;
}

/**
 * Checks whether a value is greater than a min value or greater than or
 * equal to depending on the inclusive field in the rule
 * If the value is null or undefined it will return true. Add a RequiredRule
 * if you want this to fail.
 * Will return true if the value is not a number. Add a Number rule if
 * you want this to fail
 *
 * @export
 * @param {*} value
 * @param {MinValidationRule} rule
 * @returns {boolean}
 */
export function validateMinRule(value: any, rule: MinValidationRule): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value !== 'number') {
    return true;
  }

  if (rule.inclusive) {
    return value >= rule.min;
  }

  return value > rule.min;
}
