import React from 'react';

interface SectionTitleFieldProps {
  title: string;
  extra?: string | React.ReactNode;
}

export const SectionTitleField = (props: SectionTitleFieldProps) => {
  return (
    <div>
      <h6 style={{ fontWeight: 'bold' }}>{props.title}</h6>
      {typeof props.extra === 'string' ? (
        <p style={{ color: 'grey' }}>{props.extra}</p>
      ) : (
        props.extra
      )}
    </div>
  );
};
