import { BirthDateValidationRuleId } from './validation/validation-rules/birthdate-validation';
import { EmailValidationRuleId } from './validation/validation-rules/email-validation';
import { HcnValidationRuleId } from './validation/validation-rules/hcn-validation';
import { MaxLengthValidationRuleId } from './validation/validation-rules/max-length-validation';
import { RequiredDefinedValidationRuleId } from './validation/validation-rules/required-defined-validation';
import { RequiredValidationRuleId } from './validation/validation-rules/required-validation';
import { TruthyValidationRuleId } from './validation/validation-rules/truthy-validation';
import { ValidationRules } from './validation/validation-rules/validation-rules';

export { FormUX } from './form-ux';
export { FormUXFieldType } from './models/form-ux-fields/form-ux-field-type';

/* This module exports common validation rules that can be used by
UI components in the app */
/* Use for just required fields */
export const RequiredValidationRule: ValidationRules[] = [
  {
    validationRuleType: RequiredValidationRuleId
  }
];

/* Use for fields requiring not undefined and not null */
export const RequiredDefinedValidationRule: ValidationRules[] = [
  {
    validationRuleType: RequiredDefinedValidationRuleId
  }
];

/* Use this to validate email addresses */
export const EmailValidationRules: ValidationRules[] = [
  {
    validationRuleType: EmailValidationRuleId
  }
];

/* Use this for longer texts such as those that use a text areas like for
descriptions */
export const LongTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 4080
  }
];

/* Use this for short texts such as those that use a text field like for
name but not required */
export const ShortTextValidationRules: ValidationRules[] = [
  {
    validationRuleType: MaxLengthValidationRuleId,
    maxLength: 255
  }
];

export const BirthDateValidationRules: ValidationRules[] = [
  {
    validationRuleType: BirthDateValidationRuleId
  }
];

// /* Use this for validating numbers */
// export const NumberValidationRules: ValidationRules[] = [
//   {
//     validationRuleType: NumberValidationRuleId,
//   },
// ];

/* Truthy validation */
export const TruthyValidationRule: ValidationRules[] = [
  {
    validationRuleType: TruthyValidationRuleId
  }
];

/* NS HCN validation */
export const NSHcnValidationRule: ValidationRules[] = [
  {
    validationRuleType: HcnValidationRuleId
  }
];
