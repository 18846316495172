import { AbstractValidationRule } from './abstract-validation-rule';

export const DayValidationRuleId = 'day';

export interface DayValidationRule extends AbstractValidationRule {
  validationRuleType: typeof DayValidationRuleId;
}

export function validateDayRule(value: any): boolean | string {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string' && value.match(/[0-9]+/)) {
    const numberValue = Number(value);
    if (numberValue > 0 && numberValue < 32) return true;
  }

  return 'Please enter a valid 1 or 2-digit date.';
}
