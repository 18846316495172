import { Alert, Button, ButtonProps, Checkbox } from 'antd';
import { AxiosError } from 'axios';
import React from 'react';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { AddDependentModalStep, ERROR_CODES, error_codes_message_map, StepContentLayout } from '.';
import { IAuthFactor } from '..';
import { ResponsiveFooter } from 'src/utils/responsive-footer';

export const MFASelectStep: AddDependentModalStep = (props) => {
  const { lambdasClient } = useSmartApp();
  const [error, setError] = React.useState<ERROR_CODES>();
  const [selectedFactor, setSelectedFactor] = React.useState<IAuthFactor>();
  const [loading, setLoading] = React.useState<boolean>();

  // Used to scroll the top error message into view
  const fieldRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (error && fieldRef.current) {
      fieldRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [error]);

  const authFactors = props.state?.authFactors;
  React.useEffect(() => {
    props.setTitle('Further Verification Needed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerify = async () => {
    setLoading(true);

    if (error) setError(undefined);

    props.setState({ ...props.state, selectedAuthFactor: selectedFactor });

    return await lambdasClient
      ?.post('/Dependent/mfa', {
        sessionId: props.state?.sessionId,
        authFactorId: selectedFactor?.id
      })
      .then(props.next)
      .catch((error: AxiosError<{ error?: ERROR_CODES }>) => {
        const code = error.response?.data.error;
        const errorCode = code ? ERROR_CODES[code] ?? ERROR_CODES.DEFAULT : ERROR_CODES.DEFAULT;
        setError(errorCode);
        setLoading(false);
      });
  };

  const VerificationButton: React.FC<ButtonProps> = (p) => (
    <Button
      type="primary"
      onClick={onVerify}
      style={{ minWidth: 150 }}
      loading={loading}
      disabled={!selectedFactor || loading}
      size="large"
      {...p}
    >
      Send Verification
    </Button>
  );

  const BackButton: React.FC<ButtonProps> = (p) => (
    <Button onClick={props.back} type="primary" ghost style={{ minWidth: 150 }} size="large" {...p}>
      Back
    </Button>
  );

  return (
    <StepContentLayout
      footer={<ResponsiveFooter OkButton={VerificationButton} CancelButton={BackButton} />}
    >
      <div style={{ padding: '0 15px' }}>
        {error && (
          <Alert
            type="error"
            message={error_codes_message_map[error]}
            style={{ marginBottom: 30 }}
            showIcon
          />
        )}
        {authFactors?.length ? (
          <div>
            <p>Further verification is needed to continue. Select a verification method below:</p>
            {authFactors?.map((factor, i) => {
              let typeDisplay: string = '';
              if (factor.channel === 'sms') typeDisplay = 'Text';
              if (factor.channel === 'call') typeDisplay = 'Call';

              return (
                <div
                  key={i}
                  style={{
                    marginBottom: 10,
                    backgroundColor: '#F1F3F8',
                    padding: 10,
                    borderRadius: 10
                  }}
                >
                  <div
                    style={{ maxWidth: '100%' }}
                    onClick={() => setSelectedFactor(factor)}
                    className="auth-client-mfa-factor-list-button"
                  >
                    <Checkbox
                      checked={selectedFactor?.id === factor.id}
                      value={factor.id}
                      style={{
                        maxWidth: 500
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'block'
                        }}
                      >
                        <strong style={{ marginRight: 10 }}>{typeDisplay}</strong>
                        {factor.value}
                      </span>
                    </Checkbox>
                  </div>
                </div>
              );
            })}
            <p>
              In order to continue the verification process, your phone number will be sent to
              Twilio – a company in the United States. Neither your name, health card number, date
              of birth, or personal health information are sent to Twilio. The sole purpose is to
              permit you to access your vaccination records. Except for system logs or auditing
              purposes, Twilio will not otherwise use, disclose or retain your phone number. To find
              out more information about Twilio and their privacy policies and practices, click this
              link to{' '}
              <a href="https://www.twilio.com/legal/privacy" target="_blank" rel="noreferrer">
                https://www.twilio.com/legal/privacy
              </a>
              .
            </p>
            <p>
              By clicking, “Send Verification” below, you consent to your phone number being sent to
              Twilio for the purposes of verification.
            </p>
          </div>
        ) : (
          <p>No phone number has been found. Please contact MSI Registration at 1-800-563-8880.</p>
        )}
      </div>
    </StepContentLayout>
  );
};
