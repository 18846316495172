import moment from 'moment';
import { AbstractValidationRule } from './abstract-validation-rule';

export const ThreeFieldDateValidationRuleId = 'threeFieldDate';

export interface ThreeFieldDateValidationRule extends AbstractValidationRule {
  validationRuleType: typeof ThreeFieldDateValidationRuleId;
  maxAge?: number;
  isBirthday?: boolean;
}

/**
 * Validation to check if a three field date is valid
 * Assumes that the values were already indeviually validated
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateThreeFieldDateRule(
  date: {
    year: string;
    month: string;
    day: string;
  },
  validationRule: ThreeFieldDateValidationRule
): boolean | string {
  if (!date) return true;

  if (!date.year || !date.month || !date.day) return 'Please enter a valid date.';

  const { maxAge } = validationRule;

  const paddedDay = date.day.padStart(2, '0');

  // moment in iOS does not like single digit days
  if (
    maxAge &&
    moment().diff(moment(`${date.year}-${date.month}-${paddedDay}`), 'years') >= maxAge
  ) {
    return `Dependent must be under the age of ${maxAge}.`;
  }

  if (!moment().isSameOrAfter(moment(`${date.year}-${date.month}-${paddedDay}`))) {
    return 'Date of birth is invalid.';
  }

  return true;
}
