import { Route, Routes, Navigate } from 'react-router';
import { A2iDataProvider } from './contexts/a2i-data-provider';
import { A2iLayout } from './theme/layout';
import { AccountSettingsPage } from './pages/account-settings';
import { ContactSupportPage } from './pages/contact-support';
import { PatientView } from './pages/patient';
import { PatientsView } from './pages/patients';
import { LandingPage } from './pages/landing';
import { LogoutResponse } from './pages/logout-response';
import { useSmartApp } from './contexts/smart-app-provider';
import { useFeatureFlag } from './contexts/flags-provider';
import { Features } from './services/feature-flags';
import { ServiceUnavailablePage } from './pages/unavailable';
import { AccessDeniedPage } from './pages/access-denied';
import { useSearchParams } from 'react-router-dom';
// Bring back if we ever need to support front channel logout
// import {LogoutPage} from './logout';

export const AppRoutes = () => {
  const { isLoggedIn, loading } = useSmartApp();

  const [searchParams] = useSearchParams();
  const accessDenied = searchParams.get('error') === 'access_denied';

  const maintenanceMode = useFeatureFlag(Features.MAINTENANCE_MODE);

  if (maintenanceMode) {
    return (
      <Routes>
        <Route path="/" element={<ServiceUnavailablePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  if (accessDenied) {
    return (
      <Routes>
        <Route path="*" element={<AccessDeniedPage />} />
      </Routes>
    );
  }

  if (isLoggedIn && !loading) {
    return (
      <A2iDataProvider>
        <Routes>
          <Route
            path="/"
            element={
              <A2iLayout>
                <PatientsView />
              </A2iLayout>
            }
          />
          <Route
            path="/:patientId"
            element={
              <A2iLayout>
                <PatientView />
              </A2iLayout>
            }
          />
          <Route
            path="account-settings"
            element={
              <A2iLayout>
                <AccountSettingsPage />
              </A2iLayout>
            }
          />
          <Route
            path="contact-support"
            element={
              <A2iLayout>
                <ContactSupportPage />
              </A2iLayout>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="logout-response" element={<LogoutResponse />} />
        </Routes>
      </A2iDataProvider>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="logout-response" element={<LogoutResponse />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }
};
