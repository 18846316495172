import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useMemo } from 'react';

/**
 * Returns all currently enabled media breakpoints.
 *
 * @returns {Breakpoint[]} always includes 'xs'
 */
export const useCurrentBreakpoints: () => Breakpoint[] = () => {
  const screens = useBreakpoint();

  const breakpoints = useMemo(() => {
    /* Filter for all enabled breakpoints but always keep 'xs' */
    return Object.entries(screens)
      .filter((screen) => !!screen[1] || screen[0] === 'xs')
      .map((screen) => screen[0]) as Breakpoint[];
  }, [screens]);

  return breakpoints;
};

/**
 * Imitates CSS media query functionality, returns a value depending on the current screen width.
 *
 * @param {[Breakpoint, any][]} values
 * @return {any}
 */
export const useValueFromBreakpoint = (values: [Breakpoint, any][]) => {
  const breakpoints = useCurrentBreakpoints();

  const returnValue = useMemo(() => {
    /* Keep the smaller breakpoint value until a bigger breakpoint overrides it */
    return breakpoints.reduce((previousValue, currentBreakpoint) => {
      const a = values.find((value) => value[0] === currentBreakpoint);
      return a?.[1] ?? previousValue;
    }, undefined as any);
  }, [breakpoints, values]);

  return returnValue;
};
