import { faBars, faQuestionCircle, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Layout, Row, Space } from 'antd';
import React from 'react';
import { useSmartApp } from 'src/contexts/smart-app-provider';
import { useWindowDimensions } from 'src/utils/window-dimensions';
import { SettingsMenu } from 'src/components/SettingsMenu';
import { useNavigate } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';
import { UserAvatar, UserSettingsMenu } from 'src/components/UserSettingsMenu';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';
import { SurveyBanner } from 'src/components/SurveyBanner';
import { useFeatureFlag } from 'src/contexts/flags-provider';
import { Features } from 'src/services/feature-flags';
import logo from 'src/assets/myvaxnslogo.png';

const { Content } = Layout;

export const A2iLayout: React.FC<React.PropsWithChildren> = (props) => {
  const { logout } = useSmartApp();
  const { allPatients, mainPatient, loading } = useA2iData();
  const navigate = useNavigate();
  const footerJustify = useValueFromBreakpoint([
    ['xs', 'space-around'],
    ['sm', 'space-between']
  ]);

  // Add padding above version text overlay on xs screens so text isn't on top of Powered by CANImmunize logo
  const footerPaddingTop = useValueFromBreakpoint([
    ['xs', 30],
    ['sm', 0]
  ]);

  const { width } = useWindowDimensions();
  const isNarrowScreen = width < 850;
  const onLogout = React.useCallback(async () => await logout(), [logout]);

  const showSurvey = useFeatureFlag(Features.HOMEPAGE_SURVEY);

  const userPrimaryLabel = mainPatient?.displayName ?? mainPatient?.email;

  return (
    <Layout className="a2i-app">
      <Layout style={{ position: 'relative', backgroundColor: '#F1F3F8', minHeight: '100vh' }}>
        {/* This div must remain the top most item to prevent zIndex issues */}
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            zIndex: 0,
            width: '100vw',
            height: 300,
            bottom: 0,
            alignItems: 'flex-start',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              backgroundColor: '#D5DCEB',
              height: 400,
              width: '140vw',
              borderRadius: '50%',
              flexShrink: 0
            }}
          />
        </div>

        {showSurvey && <SurveyBanner />}

        {/* Header */}
        <div style={{ position: 'relative', backgroundColor: 'white', zIndex: 100 }}>
          <div className="container">
            <Row align="middle" gutter={[20, 0]} justify="space-between" wrap={false}>
              <Col>
                <Row align="middle" gutter={[40, 0]}>
                  <Col>
                    <div style={{ padding: '20px 0px' }} onClick={() => navigate('/')}>
                      <img
                        src={logo}
                        style={{ maxWidth: 300, cursor: 'pointer' }}
                        alt="MyVaxNS Logo"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Space align="start">
                  {!isNarrowScreen && (
                    <Button
                      disabled={loading}
                      className="menu-button"
                      onClick={() => {
                        navigate('/');
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHouse}
                        style={{
                          display: 'block',
                          fontSize: 24,
                          color: '#17406c'
                        }}
                        color="#17406c"
                      />
                    </Button>
                  )}
                  <Dropdown
                    disabled={loading}
                    overlay={
                      <SettingsMenu
                        showFullMenu={isNarrowScreen}
                        onLogout={onLogout}
                        // Not sure why but doing this is the only way I found fix the bug that doesn't close the dropdown menus on click
                        onClick={null}
                      />
                    }
                    overlayStyle={{ width: isNarrowScreen ? '100%' : undefined }}
                    trigger={['click']}
                  >
                    <Button className="menu-button">
                      <Space align="center">
                        {isNarrowScreen ? (
                          <FontAwesomeIcon icon={faBars} color="#17406c" />
                        ) : (
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            style={{
                              display: 'block',
                              fontSize: 24,
                              color: '#17406c'
                            }}
                            color="#17406c"
                          />
                        )}
                        {!isNarrowScreen && (
                          <CaretDownOutlined
                            style={{
                              display: 'block',
                              fontSize: '1.1em'
                            }}
                          />
                        )}
                      </Space>
                    </Button>
                  </Dropdown>
                  {!isNarrowScreen && (
                    <Dropdown
                      disabled={loading}
                      overlay={
                        // Read above onClick menu comment
                        <UserSettingsMenu
                          patients={allPatients}
                          onLogout={onLogout}
                          onClick={null}
                        />
                      }
                      trigger={['click']}
                    >
                      <Button className="menu-button">
                        <Space align="center">
                          <UserAvatar
                            firstName={mainPatient?.firstName ?? ''}
                            lastName={mainPatient?.lastName ?? ''}
                            size={24}
                            style={{ backgroundColor: '#17406c' }}
                          />
                          <div style={{ display: 'inline-grid' }}>
                            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                              {userPrimaryLabel}
                            </span>
                          </div>
                          <CaretDownOutlined
                            style={{
                              display: 'block',
                              fontSize: '1.1em'
                            }}
                          />
                        </Space>
                      </Button>
                    </Dropdown>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </div>

        <Content style={{ zIndex: 100 }}>{props.children}</Content>

        <div className="container">
          <Row
            style={{ minHeight: 100, paddingBottom: footerPaddingTop }}
            justify={footerJustify}
            align="middle"
            gutter={[50, 0]}
          >
            <Col>
              <a
                style={{ color: 'black', fontSize: 12 }}
                href="https://beta.novascotia.ca/copyright"
              >
                Crown copyright © Province of Nova Scotia
              </a>
            </Col>
            <Col>
              <img
                style={{ maxWidth: '200px' }}
                src="https://cdn.canimmunize.ca/powered-by-canimmunize-horizontal-en@2x.png"
                alt="Powered by CANImmunize"
              />
            </Col>
          </Row>
        </div>
      </Layout>
    </Layout>
  );
};
