import { AbstractValidationRule } from './abstract-validation-rule';

export const MaxLengthValidationRuleId = 'maxLength';

export interface MaxLengthValidationRule extends AbstractValidationRule {
  validationRuleType: typeof MaxLengthValidationRuleId;
  maxLength: number;
}

/**
 * Checks if the value parameter has a length greater than maxLength field
 * in the validation rule. Make sure that the value is of type string,
 * otherwise this function will throw an Error.
 *
 * @export
 * @param {*} value
 * @param {MaxLengthValidationRule} maxLengthRule
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateMaxLengthRule(value: any, maxLengthRule: MaxLengthValidationRule): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value !== 'string') {
    throw new Error(`Validating max length rule but value is not of type string`);
  }

  if (value.trim().length > maxLengthRule.maxLength) {
    return false;
  }

  return true;
}
