import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Skeleton, Space, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { PatientCard } from 'src/components/PatientCard';
import { PlusCircleFilled } from '@ant-design/icons';
import covidInfoImg from 'src/assets/4-covid-info.jpg';
import familyFunImg from 'src/assets/2-family-fun.jpg';
import vaccineCalendarImg from 'src/assets/6-vaccine-calendar.png';
import homeImg from 'src/assets/3-home.jpg';

export const PatientsView: React.FC = () => {
  const { dependentPatients, loading, launchAddDependentModal, mainPatient } = useA2iData();
  const navigate = useNavigate();

  let content: JSX.Element;

  if (loading) {
    content = <Skeleton active />;
  } else if (!mainPatient && !dependentPatients?.length) {
    content = (
      <div style={{ marginTop: 30 }}>
        <p
          style={{
            margin: 10,
            textAlign: 'center',
            fontSize: '1.2em',
            fontWeight: 'bold'
          }}
        >
          Your account doesn't have any records yet.
        </p>
      </div>
    );
  } else {
    content = (
      <div>
        {mainPatient && (
          <div>
            <h2 style={{ marginLeft: 15, fontWeight: 'bold' }}>Main Account</h2>
            <Row gutter={[0, 15]}>
              <Col span={24} key={mainPatient.id}>
                <PatientCard
                  patient={mainPatient}
                  onPress={() =>
                    mainPatient.isDeceased ? undefined : navigate(`/${mainPatient.id}`)
                  }
                  isDependent={false}
                />
              </Col>
            </Row>
          </div>
        )}
        {!!dependentPatients.length && (
          <div>
            <br></br>
            <h2 style={{ marginLeft: 15, fontWeight: 'bold' }}>Dependent(s)</h2>
            <Row gutter={[0, 15]}>
              {dependentPatients.map((patient) => (
                <Col span={24} key={patient.id}>
                  <PatientCard
                    patient={patient}
                    onPress={() => (patient.isDeceased ? undefined : navigate(`/${patient.id}`))}
                    isDependent={true}
                  />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }

  const button = (
    <Button
      size="large"
      type="primary"
      onClick={launchAddDependentModal}
      disabled={loading || dependentPatients.length >= 25 || mainPatient?.isDeceased}
    >
      <Space align="center">
        <PlusCircleFilled style={{ fontSize: 20, verticalAlign: 'baseline' }} /> Connect a
        Dependent's Record
      </Space>
    </Button>
  );

  return (
    <div>
      <div className="patient-view">
        <div style={{ background: 'white' }}>
          <div className="container">
            <Row
              justify="space-between"
              gutter={[25, 10]}
              align="middle"
              style={{ padding: '15px 0' }}
            >
              <Col>
                <h1>
                  {loading ? (
                    <Skeleton
                      style={{ width: 200, paddingTop: 20 }}
                      active
                      round
                      paragraph={false}
                      title={{ width: 350 }}
                    />
                  ) : (
                    `Welcome, ${mainPatient?.firstName}`
                  )}
                </h1>
              </Col>
            </Row>
          </div>
        </div>

        <div className="container" style={{ paddingTop: 20 }}>
          <Card style={{ width: '100%' }}>
            <Row
              style={{ marginBottom: 20 }}
              justify="space-between"
              align="middle"
              gutter={[15, 15]}
            >
              <Col>
                <h3 style={{ fontSize: 24, fontWeight: 'bold' }}>My Vaccination Records</h3>
                <h4 style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 18 }}>
                  View and download your vaccination records.
                </h4>
              </Col>
              <Col>
                {dependentPatients.length >= 25 ? (
                  <Tooltip title="You cannot connect any more dependents as you have reached the maximum allowable limit (25).">
                    {button}
                  </Tooltip>
                ) : mainPatient?.isDeceased ? (
                  <Tooltip title="You cannot connect dependents at the moment.">{button}</Tooltip>
                ) : (
                  button
                )}
              </Col>
            </Row>
            <div>
              {content}
              <div style={{ marginTop: 20 }}>
                <p style={{ textAlign: 'center', margin: 0 }}>
                  Click on the banner above to view vaccination records.
                </p>
              </div>
            </div>
          </Card>
          <HelpfulResourcesCard />
        </div>
      </div>
    </div>
  );
};

export const HelpfulResourcesCard: React.VFC = () => {
  return (
    <Card style={{ marginTop: 20 }}>
      <h3 style={{ marginBottom: 20 }}>Helpful Resources</h3>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <LearnCard
            image={covidInfoImg}
            title="NS COVID-19 Information"
            link="https://novascotia.ca/coronavirus/"
          />
        </Col>
        <Col xs={24} md={12}>
          <LearnCard
            image={familyFunImg}
            title="Learn more about VaxRecordNS"
            link="https://www.nshealth.ca/vaxrecordns"
          />
        </Col>
        <Col xs={24} md={12}>
          <LearnCard
            image={homeImg}
            title="Adding your Out of Province COVID-19 Vaccine"
            link="https://vaccineentryservice.novascotia.ca/"
          />
        </Col>
        <Col xs={24} md={12}>
          <LearnCard
            image={vaccineCalendarImg}
            title="NS Routine Vaccination Schedule"
            link="https://novascotia.ca/dhw/cdpc/documents/Routine-Immunization-Schedules-for-Children-Youth-Adults.pdf"
          />
        </Col>
      </Row>
    </Card>
  );
};

const LearnCard: React.VFC<{ image: any; title: string; link: string }> = ({
  image,
  title,
  link
}) => {
  const InnerLearnCard = ({ image, title, disabled }) => (
    <Card
      style={{ opacity: disabled ? 0.5 : 1.0, height: '100%' }}
      bodyStyle={{ height: '100%', padding: 15 }}
    >
      <Row align="middle" justify="space-between">
        <Col span={22}>
          <Row gutter={[20, 20]} align="middle">
            <Col xs={6} sm={5} md={6}>
              <div
                style={{
                  backgroundImage: `url("${image}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  borderRadius: 10,
                  height: 0,
                  width: '100%',
                  paddingBottom: '100%',
                  overflow: 'hidden'
                }}
              />
            </Col>
            <Col xs={18} sm={19} md={18}>
              <span>{title}</span>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <FontAwesomeIcon
              icon={faChevronRight}
              color={'gray'}
              size="lg"
              style={{ alignSelf: 'center' }}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );

  return link && link.length ? (
    <a href={link} className="learn-card" target="_blank" rel="noreferrer">
      <InnerLearnCard title={title} image={image} disabled={false} />
    </a>
  ) : (
    <InnerLearnCard disabled title={title} image={image} />
  );
};
