import { ButtonProps, Col, Row } from 'antd';
import { useValueFromBreakpoint } from './breakpoints';

interface IResponsiveFooterProps {
  OkButton?: React.FC<ButtonProps>;
  CancelButton?: React.FC<ButtonProps>;
}

export const ResponsiveFooter: React.FC<IResponsiveFooterProps> = (props) => {
  const { OkButton, CancelButton } = props;

  const shouldWrap = useValueFromBreakpoint([
    ['xs', true],
    ['sm', false]
  ]);

  if (!OkButton && !CancelButton) return null;

  return shouldWrap ? (
    <div>
      {OkButton && <OkButton block />}
      {OkButton && CancelButton && <div style={{ height: 20 }} />}
      {CancelButton && <CancelButton block />}
    </div>
  ) : (
    <Row wrap={true} justify="space-between" gutter={[20, 20]}>
      {CancelButton && (
        <Col>
          <CancelButton />
        </Col>
      )}
      {OkButton && (
        <Col>
          <OkButton />
        </Col>
      )}
    </Row>
  );
};
