import { R4 } from '@ahryman40k/ts-fhir-types';
import { DependentRelation } from '../contexts/a2i-data-provider';
import { Immunization } from './immunization';

export interface Patient extends R4.IPatient {
  fullName: string;
  displayName: string;
  firstName: string;
  lastName: string;
  immunizations: Array<Immunization>;
  email?: string;
  relation?: 'parent' | 'guardian';
  panoramaUserId?: string;
  hcn?: string;
  isDeceased: boolean;
}

export const parsePatientBundle = (
  bundle: R4.IBundle,
  dependents: Array<DependentRelation>
): Array<Patient> => {
  const patients = bundle.entry
    ?.filter((e) => e.resource!.resourceType === 'Patient')
    .map((e) => parsePatient(e.resource as R4.IPatient, dependents));
  return patients ?? [];
};

export const parsePatient = (
  patient: R4.IPatient,
  dependents: Array<DependentRelation>
): Patient => {
  const code = dependents.find((dependent) => dependent.id === patient.id)?.code;
  const name =
    patient?.name?.find((name) => name.use === R4.HumanNameUseKind._usual) ??
    patient?.name?.find((name) => name.use === R4.HumanNameUseKind._official);

  return {
    ...patient,
    immunizations: [],
    hcn: patient.identifier?.length ? getNSHcn(patient.identifier) : '',
    panoramaUserId: patient.identifier?.find(
      (id) => id.system && /(prod|uat).id.panorama.source/.test(id.system)
    )?.value,
    fullName: patient.name ? flattenName(patient.name, R4.HumanNameUseKind._official) : '',
    displayName: !!name?.given ? `${name.given?.join(' ')} ${name.family}` : '',
    firstName: !!name?.given ? name.given[0] : '',
    lastName: name?.family ?? '',
    email: patient?.telecom?.find((telecom) => String(telecom?.system) === 'Email')?.value ?? '',
    relation: code === 'GUARD' ? 'guardian' : code === 'NOK' ? 'parent' : undefined,
    isDeceased: patient?.active === false && !!patient?.deceasedDateTime
  };
};

const getNSHcn = (identifiers: Array<R4.IIdentifier>) => {
  const hcns = identifiers.filter(
    (id) =>
      id.system === 'https://fhir.infoway-inforoute.ca/NamingSystem/ca-ns-patient-healthcare-id'
  );
  if (hcns?.length === 0) return;
  if (hcns?.length === 1) return hcns[0].value;
  if (hcns?.length > 1) return hcns.find((hcn) => hcn.use === 'official')?.value;
};

const getFullName = (name: R4.IHumanName) => {
  const givenNames = name.given?.join(' ') ?? '';
  const familyName = name.family;

  return `${givenNames} ${familyName}`;
};

const flattenName = (
  names: Array<R4.IHumanName>,
  use: R4.HumanNameUseKind,
  defaultToFirstMatch = true
): string => {
  const name = names?.find((name) => name.use === use);
  if (name) {
    return getFullName(name);
  } else if (defaultToFirstMatch && names?.length) {
    return getFullName(names[0]);
  }
  return '';
};
