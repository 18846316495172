import { useValueFromBreakpoint } from 'src/utils/breakpoints';

export const SurveyBanner: React.FC = () => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: '#F7CB67',
        textAlign: 'center',
        padding: '10px 0',
        zIndex: 1
      }}
    >
      <text><strong>Vaccinations that have been reported to Public Health after 2008 will appear on your record.  See our </strong></text>
      <a
        href={process.env.REACT_APP_FAQ_URL}
        target="_blank"
        style={{ color: '#12235A', fontWeight: 'bold', textDecoration: 'underline' }}
        rel="noreferrer"
      >
         FAQ
      </a>
      <text><strong> for more details.</strong></text>
    </div>
  );
};
