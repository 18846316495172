import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormUX, FormUXFieldType, RequiredValidationRule } from '../index';
import { YearValidationRuleId } from '../validation/validation-rules/year-validation';
import { DayValidationRuleId } from '../validation/validation-rules/day-validation';
import { ValidationRules } from '../validation/validation-rules/validation-rules';
import { RequiredValidationRuleId } from '../validation/validation-rules/required-validation';

type ThreeFieldDate = { day: string; month: string; year: string };

interface ThreeFieldDatepickerProps {
  validationRules: Array<ValidationRules>;
  name: string;
  className?: string;
}

export const ThreeFieldDatepicker: React.FC<ThreeFieldDatepickerProps> = ({
  validationRules,
  name,
  className
}) => {
  const formikContext = useFormikContext<{}>();

  const isRequired = validationRules.some(
    (rule) => rule.validationRuleType === RequiredValidationRuleId
  );

  const yearKey = `${name}/year`;
  const monthKey = `${name}/month`;
  const dayKey = `${name}/day`;

  const year: string = formikContext.values[yearKey];
  const month: string = formikContext.values[monthKey];
  const day: string = formikContext.values[dayKey];

  React.useEffect(() => {
    const allFieldsValid: boolean = !Object.keys(formikContext.errors).some((k) =>
      [yearKey, monthKey, dayKey].includes(k)
    );
    const hasAllFields: boolean = !!(year && day && month);
    const dateTouched = formikContext.touched[name];

    if (allFieldsValid && hasAllFields) {
      if (!dateTouched) {
        formikContext.setFieldTouched(name, true);
      }
    } else {
      if (dateTouched) formikContext.setFieldTouched(name, false);
    }
    formikContext.validateField(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values[name]]);

  React.useEffect(() => {
    // Hacky solution to simulate the datepicker being a single field
    const newDate: ThreeFieldDate = { year, day, month };
    const date: ThreeFieldDate = formikContext.values[name];
    const allFieldsValid: boolean = !Object.keys(formikContext.errors).some((k) =>
      [yearKey, monthKey, dayKey].includes(k)
    );

    const hasAllFields: boolean = !!(year && day && month);
    const datesAreDifferent: boolean = JSON.stringify(date) !== JSON.stringify(newDate);

    if (hasAllFields && allFieldsValid) {
      if (datesAreDifferent) formikContext.setFieldValue(name, newDate);
    } else {
      formikContext.setFieldValue(name, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, day, month, formikContext.errors]);

  return (
    <Row
      justify="space-between"
      className={`datepicker ${className}`}
      gutter={[15, 15]}
      style={{ overflowX: 'hidden', marginLeft: -7.5, marginRight: -7.5 }}
    >
      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
        <FormUX
          formUXModel={[
            {
              name: yearKey,
              label: 'Year (YYYY)',
              type: FormUXFieldType.text,
              editable: true,
              placeholder: 'e.g. 1990',
              size: 'large',
              maxLength: 4,
              className: 'ant-input-grey',
              validationRules: [
                ...(isRequired ? RequiredValidationRule : []),
                {
                  validationRuleType: YearValidationRuleId
                }
              ]
            }
          ]}
        />
      </Col>
      <Col xs={{ span: 12 }} sm={{ span: 8 }}>
        <FormUX
          formUXModel={[
            {
              name: monthKey,
              label: 'Month (MMM)',
              searchable: true,
              type: FormUXFieldType.select,
              editable: true,
              placeholder: '-select-',
              selectableValues: MONTHS_OPTIONS,
              size: 'large',
              validationRules: [...(isRequired ? RequiredValidationRule : [])]
            }
          ]}
        />
      </Col>
      <Col xs={{ span: 12 }} sm={{ span: 8 }}>
        <FormUX
          formUXModel={[
            {
              name: dayKey,
              label: 'Day (DD)',
              type: FormUXFieldType.text,
              editable: true,
              placeholder: 'e.g. 01',
              size: 'large',
              maxLength: 2,
              className: 'ant-input-grey',
              validationRules: [
                ...(isRequired ? RequiredValidationRule : []),
                {
                  validationRuleType: DayValidationRuleId
                }
              ]
            }
          ]}
        />
      </Col>
    </Row>
  );
};

const MONTHS_OPTIONS = [
  { label: 'JAN', key: '01' },
  { label: 'FEB', key: '02' },
  { label: 'MAR', key: '03' },
  { label: 'APR', key: '04' },
  { label: 'MAY', key: '05' },
  { label: 'JUN', key: '06' },
  { label: 'JUL', key: '07' },
  { label: 'AUG', key: '08' },
  { label: 'SEP', key: '09' },
  { label: 'OCT', key: '10' },
  { label: 'NOV', key: '11' },
  { label: 'DEC', key: '12' }
];
