import { AbstractValidationRule } from './abstract-validation-rule';

export const PostalCodeValidationRuleId = 'postalCode';

export interface PostalCodeValidationRule extends AbstractValidationRule {
  validationRuleType: typeof PostalCodeValidationRuleId;
}
/**
 * Validation to check if a postal code is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validatePostalCodeRule(postalCode: string): boolean {
  const postalCodeClean = postalCode
    .trim()
    .replace(/[^0-9A-Za-z]/g, '')
    .toLocaleUpperCase();

  // Postal code must be 6 digits - but an empty string is also cool
  if (postalCodeClean.length !== 6 && postalCodeClean.length !== 0) {
    return false;
  }

  const postalCodeRegex = /^(?:[A-Z][0-9][A-Z][0-9][A-Z][0-9])?$/;

  return postalCodeRegex.test(postalCodeClean);
}
