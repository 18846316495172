import { AbstractValidationRule } from './abstract-validation-rule';

export const HcnTypeValidationRuleId = 'hcnType';

export interface HcnTypeValidationRule extends AbstractValidationRule {
  validationRuleType: typeof HcnTypeValidationRuleId;
}

/**
 * Validation to check if hcnType is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateHcnTypeRule(hcnType: string): boolean {
  if (!hcnType) return true;

  return hcnTypes.indexOf(hcnType) > -1;
}

export const hcnTypes = [
  'NL',
  'PE',
  'NS',
  'NB',
  'QC',
  'ON',
  'MB',
  'SK',
  'AB',
  'BC',
  'YT',
  'NU',
  'NT',
  'FN',
  'INTSTUD',
  'CORRECT',
  'RCMP',
  'CDNFORCES',
  'VA',
  'OUTCAN',
  'OTHER'
];
