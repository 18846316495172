import React from 'react';
import { FeatureFlagService, FeatureFlags, Features } from '../services/feature-flags';

const initialFlags: FeatureFlags = {
  [Features.HOMEPAGE_SURVEY]: false,
  [Features.MAINTENANCE_MODE]: false
};

interface IFeatureFlagsContext {
  flags: FeatureFlags;
}

export const FeatureFlagsContext = React.createContext<IFeatureFlagsContext>(undefined!);

export const FeatureFlagsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [flags, setFeatureFlags] = React.useState<FeatureFlags>(initialFlags);

  React.useEffect(() => {
    FeatureFlagService.start(setFeatureFlags, { initialFlags });
  }, []);

  return <FeatureFlagsContext.Provider value={{ flags }}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlag = (flag: Features) => {
  const { flags } = React.useContext(FeatureFlagsContext);
  return flags[flag];
};
