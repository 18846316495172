import { Col, Row } from 'antd';
import React from 'react';
import { IAddDependentModalState } from '..';
import { ConsentCaptureStep } from './consent-capture';
import { MFACodeStep } from './mfa-code';
import { MFASelectStep } from './mfa-select';
import { PatientMatchStep } from './patient-match';
import { RecordVerifiedStep } from './record-verified';

interface IAddDependentModalStepProps {
  setTitle: (title: string | undefined) => any;
  next: () => any;
  back: () => any;
  close: () => any;
  state?: IAddDependentModalState;
  setState: (state: IAddDependentModalState) => any;
  savedValues?: { [key: string]: any };
  setSavedValues: (values?: { [key: string]: any }) => any;
  retryAtTime?: number;
  updateConnectDependentRetryCooldown: () => Promise<void>;
  error?: ERROR_CODES;
  setError: (error?: ERROR_CODES) => any;
}

export type AddDependentModalStep = React.VFC<IAddDependentModalStepProps>;

/* In order of step appearance */
export const Steps: Array<AddDependentModalStep> = [
  ConsentCaptureStep,
  PatientMatchStep,
  MFASelectStep,
  MFACodeStep,
  RecordVerifiedStep
];

export const StepContentLayout: React.FC<React.PropsWithChildren<{ footer?: React.ReactNode }>> = (
  props
) => {
  return (
    <Row justify="space-between" gutter={[0, 15]}>
      <Col span={24} style={{ height: 'min(420px, 50vh)', overflowY: 'auto' }}>
        {props.children}
      </Col>
      {props.footer && <Col span={24}>{props.footer}</Col>}
    </Row>
  );
};

export enum ERROR_CODES {
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  PATIENT_OVER_16 = 'PATIENT_OVER_16',
  FAILED_TO_FIND_PATIENT = 'FAILED_TO_FIND_PATIENT',
  NO_PHONE_ATTACHED_TO_PATIENT = 'NO_PHONE_ATTACHED_TO_PATIENT',
  MFA_MISSING_AUTH_FACTORS = 'MFA_MISSING_AUTH_FACTORS',
  DEFAULT = 'DEFAULT',
  SMS_ERROR_LANDLINE = 'SMS_ERROR_LANDLINE',
  PHONE_ERROR_UNKNOWN = 'PHONE_ERROR_UNKNOWN',
  INCORRECT_MFA_CODE = 'INCORRECT_MFA_CODE',
  EXPIRED_MFA_CODE = 'EXPIRED_MFA_CODE',
  CONSENT_ALREADY_EXISTS = 'CONSENT_ALREADY_EXISTS',
  DEPENDENT_CANNOT_BE_LINKED = 'DEPENDENT_CANNOT_BE_LINKED'
}

export const error_codes_message_map: { [code in ERROR_CODES]: React.ReactNode } = {
  [ERROR_CODES.TOO_MANY_ATTEMPTS]: 'Too many attempts. Please try again in 10 minutes.',
  [ERROR_CODES.PATIENT_OVER_16]:
    'You cannot connect a dependent’s record if they are 16 years of age or older.',
  [ERROR_CODES.FAILED_TO_FIND_PATIENT]:
    'Information does not match. Please ensure you are entering the information as it appears on your dependent’s health card.',
  [ERROR_CODES.MFA_MISSING_AUTH_FACTORS]:
    'No phone number has been found. Please contact MSI Registration at 1-800-563-8880',
  [ERROR_CODES.NO_PHONE_ATTACHED_TO_PATIENT]:
    'No phone number has been found. Please contact MSI Registration at 1-800-563-8880',
  [ERROR_CODES.DEFAULT]: 'An unknown error occured. Please try again later.',
  [ERROR_CODES.CONSENT_ALREADY_EXISTS]: 'This dependent is already connected to your record.',
  [ERROR_CODES.SMS_ERROR_LANDLINE]:
    'An SMS message could not be sent to this number. Is it a landline?',
  [ERROR_CODES.PHONE_ERROR_UNKNOWN]:
    'Sending a verification code to that phone number failed. Please select another method or try again later.',
  [ERROR_CODES.INCORRECT_MFA_CODE]: 'Invalid code entered.',
  [ERROR_CODES.EXPIRED_MFA_CODE]: 'Expired code entered.',
  [ERROR_CODES.DEPENDENT_CANNOT_BE_LINKED]: 'This record cannot be connected.'
};
