import { message } from 'antd';
import Client from 'fhirclient/lib/Client';
import React from 'react';
import { DisconnectDependentModal } from 'src/modals/remove-dependent';
import { AddDependentModal } from 'src/modals/add-dependent';
import { useSmartApp } from './smart-app-provider';
import { getDependentRelationsFromBundle } from 'src/data/consent';
import { parseImmsBundle } from 'src/data/immunization';
import { parsePatientBundle, Patient } from 'src/data/patient';
import { useNetworkStatus } from 'src/utils/network-status';

interface A2iData {
  mainPatient: Patient | undefined;
  allPatients: Patient[];
  dependentPatients: Patient[];
  loading: boolean;
  getPatients: () => Promise<void>;
  launchAddDependentModal: () => any;
  launchDisconnectDependentModal: (patient: Patient) => any;
  retryVerifyDependentAtTime?: number;
  updateConnectDependentRetryCooldown: () => Promise<void>;
}

export interface DependentRelation {
  id: string;
  code: 'NOK' | 'GUARD';
}

const A2iDataContext = React.createContext<A2iData>({
  dependentPatients: [],
  allPatients: [],
  loading: false,
  getPatients: () => Promise.resolve(),
  launchAddDependentModal: () => null,
  launchDisconnectDependentModal: (patient: Patient) => null,
  mainPatient: undefined,
  retryVerifyDependentAtTime: undefined,
  updateConnectDependentRetryCooldown: () => Promise.resolve()
});

export const A2iDataProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [dependentPatients, setDependentPatients] = React.useState<Patient[]>([]);
  const [allPatients, setAllPatients] = React.useState<Patient[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [dependentModalVisible, setDependentModalVisible] = React.useState(false);
  const [disconnectDependentModalVisible, setDisconnectDependentModalVisible] =
    React.useState(false);
  const { lambdasClient, client } = useSmartApp();
  const [mainPatient, setMainPatient] = React.useState<Patient | undefined>(undefined);
  const [patient, setPatient] = React.useState<Patient | undefined>(undefined);
  const [retryVerifyDependentAtTime, setRetryVerifyDependentAtTime] = React.useState<number>();
  const isOnline = useNetworkStatus();

  const launchAddDependentModal = React.useCallback(() => {
    setDependentModalVisible(true);
  }, []);
  const launchDisconnectDependentModal = React.useCallback((patient: Patient) => {
    setPatient(patient);
    setDisconnectDependentModalVisible(true);
  }, []);

  const getPatients = React.useCallback(async () => {
    setLoading(true);
    return fetchPatients(client).then((patients) => {
      setAllPatients(patients);

      setMainPatient(
        patients?.find((patient) => patient.id && client?.user?.fhirUser?.includes(patient.id))
      );

      setDependentPatients(
        patients?.filter((pat) => {
          return pat.id && !client?.user?.fhirUser?.includes(pat.id);
        })
      );

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateConnectDependentRetryCooldown = async () => {
    try {
      await lambdasClient?.post('/Dependent', { retryTime: true }).then((response) => {
        const retryTime = response.data.retryTime;
        if (Date.now() > retryTime) setRetryVerifyDependentAtTime(undefined);
        else setRetryVerifyDependentAtTime(retryTime);
      });
    } catch (err) {
      message.error('An error occurred. Please try again later.');
    }
  };

  React.useEffect(() => {
    if (!isOnline) {
      setLoading(true);
    }
  }, [isOnline]);

  React.useEffect(() => {
    if (isOnline) getPatients();
  }, [getPatients, isOnline]);

  return (
    <A2iDataContext.Provider
      value={{
        dependentPatients,
        loading,
        getPatients,
        launchAddDependentModal,
        launchDisconnectDependentModal,
        mainPatient,
        retryVerifyDependentAtTime,
        updateConnectDependentRetryCooldown,
        allPatients
      }}
    >
      {props.children}
      <AddDependentModal visible={dependentModalVisible} setVisible={setDependentModalVisible} />
      <DisconnectDependentModal
        visible={disconnectDependentModalVisible}
        setVisible={setDisconnectDependentModalVisible}
        patient={patient}
      />
    </A2iDataContext.Provider>
  );
};

const fetchPatients = async (client: Client) => {
  const mainPatient = await fetchMainPatient(client);

  if (!mainPatient.isDeceased) {
    const dependentPatients = await fetchDependentPatients(client);
    const patients = [mainPatient, ...dependentPatients].filter((p) => !!p);
    // Sort patients by last name, then first name (including main patient)
    patients
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
    return patients;
  }

  return [mainPatient];
};

const fetchMainPatient = async (client: Client) => {
  const mainUserId = client.user.fhirUser!;

  // Retrieve patients bundle
  const mainPatient: Patient = await client
    .request(`Patient?_id=${encodeURIComponent(mainUserId?.replace('Patient/', ''))}`)
    .then((res) => {
      return parsePatientBundle(res, [])[0];
    });

  // pull main patient imms if not deceased
  if (!mainPatient.isDeceased) {
    // Retrieve imms and attach to the patient
    await client.request(`Immunization?patient=${encodeURIComponent(mainUserId)}`).then((res) => {
      const imms = parseImmsBundle(res);
      if (mainPatient) mainPatient.immunizations = imms;
    });
  }

  return mainPatient;
};

const fetchDependentPatients = async (client: Client) => {
  const mainUserId = client.user.fhirUser!;

  // Get dependent patientIds from consents
  const dependents: Array<DependentRelation> = await client
    .request(`Consent?actor=${encodeURIComponent(mainUserId)}`)
    .then((res) => {
      return getDependentRelationsFromBundle(res, mainUserId);
    });

  const dependentIds = dependents.map((dependent) => dependent.id);

  if (!dependentIds || !dependentIds.length) return [];

  // Retrieve patients bundle
  const dependentPatients: Array<Patient> = await client
    .request(
      `Patient?_id=${dependentIds.map((id) => encodeURIComponent(id.replace('Patient/', '')))}`
    )
    .then((res) => {
      return parsePatientBundle(res, dependents);
    });

  // Retrieve imms and attach to their patients
  await Promise.all(
    dependentIds.map((patientId) => {
      const patient = dependentPatients.find((p) => p.id && patientId.includes(p.id));
      if (patient && !patient.isDeceased) {
        return client
          .request(`Immunization?patient=${encodeURIComponent(patientId)}`)
          .then((res) => {
            const imms = parseImmsBundle(res);
            if (patient && !patient.isDeceased) patient.immunizations = imms;
          });
      }
    })
  );

  return dependentPatients || [];
};

export const useA2iData = () => {
  return React.useContext(A2iDataContext);
};
