import React from 'react';
import { NetworkStatusService } from 'src/services/network-status';

export function useNetworkStatus() {
  const [isOnline, setIsOnline] = React.useState(true);

  React.useEffect(() => {
    const lambdasUrl = process.env.REACT_APP_LAMBDAS_URL;
    const networkStatusUrl = `${lambdasUrl}/health`;

    const onConnect = () => setIsOnline(true);
    const onDisconnect = () => setIsOnline(false);
    NetworkStatusService.subscribeConnect(onConnect);
    NetworkStatusService.subscribeDisconnect(onDisconnect);
    NetworkStatusService.start(networkStatusUrl, 10000, 2000);
    return () => {
      NetworkStatusService.unsubscribeConnect(onConnect);
      NetworkStatusService.unsubscribeDisconnect(onDisconnect);
    };
  }, []);

  return isOnline;
}
