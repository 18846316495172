import { AbstractValidationRule } from './abstract-validation-rule';

export const TruthyValidationRuleId = 'truthy';

export interface TruthyValidationRule extends AbstractValidationRule {
  validationRuleType: typeof TruthyValidationRuleId;
}

/**
 * Validation to check if a value is truthy.
 *
 * To be used in single checkboxes
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateTruthyRule(value: any): boolean {
  return !!value;
}
