import { Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useA2iData } from 'src/contexts/a2i-data-provider';
import { ERROR_CODES, Steps } from './steps';
import { CONSENT_RELATION_TYPE } from './steps/consent-capture';
import { useValueFromBreakpoint } from 'src/utils/breakpoints';

export interface IAddDependentModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => any;
}

export interface IAuthFactor {
  id: string;
  channel: 'sms' | 'call';
  value: string;
}

export interface IAddDependentModalState {
  consent?: boolean;
  relation?: CONSENT_RELATION_TYPE;
  sessionId?: string;
  authFactors?: Array<IAuthFactor>;
  selectedAuthFactor?: IAuthFactor;
}

export const AddDependentModal: React.VFC<IAddDependentModalProps> = (props) => {
  const { visible, setVisible } = props;
  const { getPatients, retryVerifyDependentAtTime, updateConnectDependentRetryCooldown } =
    useA2iData();
  const [title, setTitle] = React.useState<string>();
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const [state, setState] = React.useState<IAddDependentModalState>();
  const [savedValues, setSavedValues] = React.useState<{ [key: string]: any }>();
  const [error, setError] = React.useState<ERROR_CODES>();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!state || Object.keys(state).length !== 0) setState(undefined);
    if (stepIndex !== 0) setStepIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const next = React.useCallback(() => {
    if (stepIndex >= Steps.length - 1) {
      setVisible(false);
      getPatients();
      setSavedValues(undefined);
      navigate('/');
    } else {
      setStepIndex(stepIndex + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, stepIndex]);

  const back = React.useCallback(() => {
    if (stepIndex === 0) {
      setVisible(false);
    } else {
      setStepIndex(stepIndex - 1);
    }
  }, [setVisible, stepIndex]);

  const close = React.useCallback(() => {
    if (stepIndex >= Steps.length - 1) getPatients();
    setVisible(false);
    setSavedValues(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, stepIndex]);

  const Step = Steps[stepIndex];

  const modalTopSpace = useValueFromBreakpoint([
    ['xs', 50],
    ['md', 100]
  ]);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      destroyOnClose={true}
      footer={null}
      maskClosable={false}
      style={{
        top: modalTopSpace
      }}
      title={
        <p style={{ color: '#00538f', margin: 0, fontWeight: 'bold', lineHeight: 1.8 }}>{title}</p>
      }
    >
      {Step && (
        <Step
          setTitle={setTitle}
          next={next}
          back={back}
          close={close}
          state={state}
          setState={setState}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          retryAtTime={retryVerifyDependentAtTime}
          updateConnectDependentRetryCooldown={updateConnectDependentRetryCooldown}
          error={error}
          setError={setError}
        />
      )}
    </Modal>
  );
};
