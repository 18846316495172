import moment from 'moment';

/**
 *
 * @param pdf Base64 pdf
 * @param patient Patient the pvc belongs to
 */
export const downloadPVCPdf = (pdf, patient, type) => {
  const pdfBase64 = pdf;
  const bytes = atob(pdfBase64);
  let length = bytes.length;
  let out = new Uint8Array(length);

  const pvcString =
    type === 'full'
      ? 'Immunization Record'.split(' ').join('-')
      : 'Proof of Vaccination'.split(' ').join('-');

  const mobileOptimizedString = type === 'mobile' ? 'Mobile Optimized '.split(' ').join('-') : '';
  const nameString = (
    type === 'pdf' || type === 'mobile' ? patient?.fullName : patient?.displayName
  )
    ?.split(' ')
    ?.join('-');
  const filename = `${nameString}-${mobileOptimizedString}${pvcString}-${moment().format('YYYY-MMM-DD').toUpperCase()}.pdf`;

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  const blob = new Blob([out], { type: 'application/pdf' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);

  link.download = `${filename}`;
  link.click();
};
