type CallbackFunction = () => void;

/**
 * Service to check if the network is online or offline
 */
export abstract class NetworkStatusService {
  private static url: string;
  private static interval: number;
  private static retryInterval: number;
  private static connectCallbacks: Array<CallbackFunction> = [];
  private static disconnectCallbacks: Array<CallbackFunction> = [];
  private static timeoutId?: NodeJS.Timeout;
  private static isRunning: boolean;
  static isOnline: boolean = true;
  /**
   * Starts the service if it is not already running
   */
  static start(url: string, interval: number, retryInterval: number) {
    if (this.isRunning) return;
    this.isRunning = true;
    this.url = url;
    this.interval = interval;
    this.retryInterval = retryInterval;

    // May not always work as expected in different browsers
    window.addEventListener('online', this.checkNetworkStatus);
    window.addEventListener('offline', this.checkNetworkStatus);

    document.addEventListener('visibilitychange', this.handleFocus);

    this.checkNetworkStatus();
  }

  static stop() {
    if (!this.isRunning) return;
    this.isRunning = false;
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
    window.removeEventListener('online', this.checkNetworkStatus);
    window.removeEventListener('offline', this.checkNetworkStatus);
    document.removeEventListener('visibilitychange', this.handleFocus);
  }

  static subscribeConnect(callback: CallbackFunction) {
    this.connectCallbacks.push(callback);
  }

  static subscribeDisconnect(callback: CallbackFunction) {
    this.disconnectCallbacks.push(callback);
  }

  static unsubscribeConnect(callback: CallbackFunction) {
    this.connectCallbacks = this.connectCallbacks.filter((cb) => cb !== callback);
  }

  static unsubscribeDisconnect(callback: CallbackFunction) {
    this.disconnectCallbacks = this.disconnectCallbacks.filter((cb) => cb !== callback);
  }

  private static onConnect = () => {
    this.isOnline = true;
    for (const callback of this.connectCallbacks) callback();
  };
  private static onDisconnect = () => {
    this.isOnline = false;
    for (const callback of this.disconnectCallbacks) callback();
  };

  private static handleFocus = () => {
    if (document.visibilityState === 'visible') this.checkNetworkStatus();
  };

  private static checkNetworkStatus = () => {
    if (!this.isRunning) return;
    if (this.timeoutId) clearTimeout(this.timeoutId);
    fetch(this.url)
      .then(() => {
        this.timeoutId = setTimeout(this.checkNetworkStatus, this.interval);
        this.onConnect();
      })
      .catch(() => {
        this.timeoutId = setTimeout(this.checkNetworkStatus, this.retryInterval);
        this.onDisconnect();
      });
  };
}
