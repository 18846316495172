import { AbstractValidationRule } from './abstract-validation-rule';

export const EmailValidationRuleId = 'email';

export interface EmailValidationRule extends AbstractValidationRule {
  validationRuleType: typeof EmailValidationRuleId;
}

//Regex for email format
const EMAIL_ADDRESS_PATTERN = new RegExp(
  '[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}' +
    '\\@' +
    '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}' +
    '(' +
    '\\.' +
    '[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}' +
    ')+'
);

/**
 * Validation to check if a email is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateEmailRule(email: string): boolean {
  if (email === null || email === undefined) return true;

  const emailClean = email.trim();

  //Empty/null email
  //TODO - this can be caught by the RequiredValidationRule instead, this function can only check formatting
  if (!(emailClean && emailClean.length)) return true;

  //Wrong format
  if (!emailClean.match(EMAIL_ADDRESS_PATTERN)) return false;

  return true;
}
