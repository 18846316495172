import { Gender } from '@canimmunize/tools';
import { AbstractValidationRule } from './abstract-validation-rule';

export const GenderValidationRuleId = 'gender';

export interface GenderValidationRule extends AbstractValidationRule {
  validationRuleType: typeof GenderValidationRuleId;
}

/**
 * Validation to check if a gender is an enum or valid string handled by CANImmunize
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateGenderRule(gender: any): boolean {
  // Validate possible gender strings; allow empty string
  if (
    typeof gender === 'string' &&
    ['female', 'male', 'unknown', ''].indexOf(gender.toLowerCase()) > -1
  )
    return true;

  gender = parseInt(gender);
  if (!isNaN(gender) && [Gender.MALE, Gender.FEMALE, 2, 3, 4].indexOf(gender) > -1) {
    return true;
  }

  return false;
}
