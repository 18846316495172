import { AbstractValidationRule } from './abstract-validation-rule';

export const PhoneValidationRuleId = 'phone';

export interface PhoneValidationRule extends AbstractValidationRule {
  validationRuleType: typeof PhoneValidationRuleId;
}
// /**
//  * Validation to check if a phone number is valid (10 digits)
//  *
//  * @export
//  * @param {*} value
//  * @returns {boolean} True if validation passed. False otherwise.
//  */
// export function validatePhoneRule(phone: string): boolean {
//   if (phone === null || phone === undefined) return true;

//   const phoneClean = phone.trim();

//   // Remove all of the non-numeric characters from the phone number
//   const numericPhone = phoneClean.replace(/[^0-9]/g, '');

//   //This can be caught by the RequiredValidationRule instead, this function can only check formatting
//   if (!(phoneClean && phoneClean.length)) return true;

//   // Phone must be 10 digits long (i.e. full number)
//   if (numericPhone.length !== 10) {
//     return false;
//   }

//   // Phone number cannot start with 1
//   if (parseInt(numericPhone.substring(0, 1)) === 1) {
//     return false;
//   }

//   return true;
// }


/**
 * Validation to check if a phone number is valid 0-40 alphanumeric digits, plus: ( ) - : #
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
 export function validatePhoneRule(phone: string): boolean {
  if (phone === null || phone === undefined) return true;

  const phoneClean = phone.trim();

  //This can be caught by the RequiredValidationRule instead, this function can only check formatting
  if (!(phoneClean && phoneClean.length)) return true;

  // Remove all of the non-numeric characters from the phone number
  const finalPhone = phoneClean.replace(/[^0-9A-Za-z()-:#+*]/g, '');


  // Phone must be 10 digits long (i.e. full number)
  if (finalPhone.length < 10 || finalPhone.length > 40) {
    return false;
  }

  return true;
}
