import { AbstractValidationRule } from './abstract-validation-rule';

export const UserPasswordValidationRuleId = 'userPassword';

export interface UserPasswordValidationRule extends AbstractValidationRule {
  validationRuleType: typeof UserPasswordValidationRuleId;
}
/**
 * Validation to check if a user password is valid
 *
 * @export
 * @param {*} value
 * @returns {boolean} True if validation passed. False otherwise.
 */
export function validateUserPasswordRule(password: string): boolean {
  if (password === null || password === undefined) return true;

  // Password should have at least 8 characters
  if (password.length < 8) return false;

  // Password should contain at least 3 of 4 regex below
  const reg = [/[a-z]/, /[A-Z]/, /[0-9]/, /[ (!@#$%^&*)]/];

  let count = 0;
  for (let i = 0; i < reg.length; i++) {
    if (reg[i].test(password)) count++;
    if (count >= 3) {
      return true;
    }
  }
  return false;
}
