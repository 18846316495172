import { ReactComponent as SuccessSvg } from './successfully-completed.svg';

import { colors } from '@canimmunize/tools';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

export const ContactSupportSuccess: React.VFC = () => {
  const navigate = useNavigate();
  return (
    <Card style={{ width: 'min(100%, 500px)', margin: '0 auto', padding: 25 }}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <SuccessSvg style={{ margin: '0 auto', display: 'block', height: 150 }} />
        </Col>
        <Col span={24}>
          <h4
            style={{
              color: colors.darkBlue,
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Submission Successful
          </h4>
        </Col>
        <Col span={24}>
          <p style={{ fontSize: 16 }}>
            Our target for issue resolution is 3 business days. If you have an urgent need for your
            vaccination records, call your local public health office.
          </p>
        </Col>
        <Col span={24}>
          <Button block size="large" type="primary" onClick={() => navigate('/')}>
            Return to Home
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
