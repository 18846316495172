import { faGear, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faFileLines, faHeadset } from '@fortawesome/pro-duotone-svg-icons';
import { Menu } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DuotoneFontAwesomeIcon } from 'src/utils/duotone-icon';
import { LogoutMenuItem, UserMenuItem } from './UserSettingsMenu';

export const SettingsMenu = ({ showFullMenu, onLogout, onClick }) => {
  const navigate = useNavigate();

  const items = React.useMemo(() => {
    const menuItems = [
      /* This one is no longer necessary but leaving it here in case it is resurrected */
      // <div onClick={() => alert('About VaxRecordNS form coming soon!')}>
      //   {VAXRecordNSIcon}
      //   <span className="ant-menu-item-text">About VaxRecordNS</span>
      <div
        onClick={() => {
          navigate('/account-settings');
        }}
      >
        <DuotoneFontAwesomeIcon icon={faGear} size="2x" className="ant-menu-item-icon" />
        <span className="ant-menu-item-text">Account Settings</span>
      </div>,
      <a target="_blank" href="https://www.nshealth.ca/vaxrecordns" rel="noreferrer">
        <DuotoneFontAwesomeIcon icon={faQuestionCircle} size="2x" className="ant-menu-item-icon" />
        <span className="ant-menu-item-text">FAQs</span>
      </a>,
      <a
        target="_blank"
        href="https://support.novascotia.ca/services/vaxrecordns-privacy-terms"
        rel="noreferrer"
      >
        <DuotoneFontAwesomeIcon icon={faFileLines} size="2x" className="ant-menu-item-icon" />
        <span className="ant-menu-item-text">Privacy & Terms of Use</span>
      </a>,
      <div
        onClick={() => {
          navigate('/contact-support');
        }}
      >
        <DuotoneFontAwesomeIcon icon={faHeadset} size="2x" className="ant-menu-item-icon" />
        <span className="ant-menu-item-text">Contact Support</span>
      </div>
    ];
    return menuItems.reduce<Array<JSX.Element>>((items, item, i) => {
      if (showFullMenu && i === 0) {
        items.push(<UserMenuItem key={'UserMenuItem'} />);
        items.push(<Menu.Divider key={'Divider-UserMenuItem'} />);
      }

      items.push(
        <Menu.Item className="menu-item" key={i}>
          {item}
        </Menu.Item>
      );

      if (i < menuItems.length - 1) {
        items.push(<Menu.Divider key={`Divider-${i}`} />);
      } else if (showFullMenu) {
        items.push(<Menu.Divider key={'Divider-LogoutMenuItem'} />);
        items.push(<LogoutMenuItem onLogout={onLogout} key={'LogoutMenuItem'} />);
      }

      return items;
    }, []);
  }, [navigate, onLogout, showFullMenu]);

  return (
    <Menu
      style={{
        padding: '10px 0',
        paddingTop: showFullMenu ? 25 : 10,
        marginTop: showFullMenu ? 10 : 0,
        boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px ${showFullMenu ? '7' : '29'}px 0px`,
        borderRadius: showFullMenu ? 0 : '10px'
      }}
      selectedKeys={[]}
      className="ant-menu-dropdown"
      onClick={onClick}
    >
      {items}
    </Menu>
  );
};

/* This one is no longer necessary but leaving it here in case it is resurrected */
// const VAXRecordNSIcon = (
//   <svg
//     viewBox="0 0 163 166"
//     xmlns="http://www.w3.org/2000/svg"
//     style={{
//       fontSize: '2em',
//       boxSizing: 'content-box',
//       height: '1em',
//       display: 'inline-block',
//     }}
//     className="ant-menu-item-icon"
//   >
//     <path
//       d="M150.734 0.850769H82.564C76.2566 0.850769 71.1434 5.91053 71.1434 12.1521V153.848C71.1434 160.09 76.2566 165.149 82.564 165.149H150.734C157.041 165.149 162.154 160.09 162.154 153.848V12.1521C162.154 5.91053 157.041 0.850769 150.734 0.850769Z"
//       fill="#FFC335"
//     />
//     <path d="M156.053 22.562H77.256V138.801H156.053V22.562Z" fill="white" />
//     <path
//       d="M128.15 12.3537H105.148C103.756 12.3537 102.628 13.4678 102.628 14.8421C102.628 16.2164 103.756 17.3305 105.148 17.3305H128.15C129.542 17.3305 130.67 16.2164 130.67 14.8421C130.67 13.4678 129.542 12.3537 128.15 12.3537Z"
//       fill="white"
//     />
//     <path
//       d="M124.139 151.97C124.139 147.873 120.783 144.552 116.643 144.552C112.503 144.552 109.147 147.873 109.147 151.97C109.147 156.066 112.503 159.387 116.643 159.387C120.783 159.387 124.139 156.066 124.139 151.97Z"
//       fill="white"
//     />
//     <path
//       d="M125.619 37.8744H7.59587C3.87065 37.8744 0.850769 40.8627 0.850769 44.5491V115.954C0.850769 119.64 3.87065 122.629 7.59587 122.629H125.619C129.344 122.629 132.364 119.64 132.364 115.954V44.5491C132.364 40.8627 129.344 37.8744 125.619 37.8744Z"
//       fill="#00538F"
//     />
//     <path
//       d="M35.2736 76.5004C42.2917 76.5004 47.981 70.8705 47.981 63.9257C47.981 56.9809 42.2917 51.3511 35.2736 51.3511C28.2555 51.3511 22.5662 56.9809 22.5662 63.9257C22.5662 70.8705 28.2555 76.5004 35.2736 76.5004Z"
//       fill="white"
//     />
//     <path
//       d="M34.4369 76.5005C39.8833 76.5005 45.1066 78.6415 48.9578 82.4524C52.8089 86.2633 54.9725 91.4321 54.9725 96.8216V109.152H13.9014V96.8216C13.9014 91.4321 16.0649 86.2633 19.9161 82.4524C23.7673 78.6415 28.9906 76.5005 34.4369 76.5005Z"
//       fill="white"
//     />
//     <path
//       d="M93.3629 92.8315H73.2777C71.5543 92.8315 70.1572 94.2141 70.1572 95.9195C70.1572 97.625 71.5543 99.0075 73.2777 99.0075H93.3629C95.0863 99.0075 96.4834 97.625 96.4834 95.9195C96.4834 94.2141 95.0863 92.8315 93.3629 92.8315Z"
//       fill="white"
//     />
//     <path
//       d="M114.52 60.8379H73.2777C71.5543 60.8379 70.1572 62.2204 70.1572 63.9258C70.1572 65.6313 71.5543 67.0138 73.2777 67.0138H114.52C116.244 67.0138 117.641 65.6313 117.641 63.9258C117.641 62.2204 116.244 60.8379 114.52 60.8379Z"
//       fill="white"
//     />
//     <path
//       d="M114.52 76.8295H73.2777C71.5543 76.8295 70.1572 78.212 70.1572 79.9174C70.1572 81.6229 71.5543 83.0054 73.2777 83.0054H114.52C116.244 83.0054 117.641 81.6229 117.641 79.9174C117.641 78.212 116.244 76.8295 114.52 76.8295Z"
//       fill="white"
//     />
//   </svg>
// );
